import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryLabel',
})
export class CountryLabelPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'en':
                return $localize`:@@CORE.LANGUAGE.ENGLISH:English`;
            case 'fr':
                return $localize`:@@CORE.LANGUAGE.FRENCH:French`;
            case 'es':
                return $localize`:@@CORE.LANGUAGE.SPANISH:Spanish`;
            default:
                return $localize`:@@CORE.LANGUAGE.ENGLISH:English`;
        }
    }
}
