import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavigationMenu } from '@libs/vc-core-lib';
import { IDGenerator } from '@libs/vc-common-ui-lib';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'vc-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {
    private _treeFlattener!: MatTreeFlattener<NavigationMenu, NavigationNode>;
    private _navigationTree: NavigationMenu[] = [];
    private _nodeLinks!: QueryList<ElementRef<HTMLAnchorElement>>;
    private _destroy$: Subject<void> = new Subject();
    private _selected!: NavigationMenu | null;

    public currentPath = '/';

    treeControl!: FlatTreeControl<NavigationNode>;
    dataSource!: MatTreeFlatDataSource<NavigationMenu, NavigationNode>;
    selection = new SelectionModel<NavigationNode>(true);

    @Input()
    set navigationTree(tree: NavigationMenu[]) {
        this._navigationTree = tree;

        if (tree && tree?.length > 0) {
            this.dataSource.data = this.navigationTree;

            this._markSelectedNode();

            if (this._selectedBranch) {
                // Expand down to current route
                this.treeControl.expand(this._selectedBranch);
            }
        }

        this.navigationTreeChange.emit(tree);
    }

    get navigationTree(): NavigationMenu[] {
        return this._navigationTree;
    }

    @Output()
    navigationTreeChange = new EventEmitter<NavigationMenu[]>();

    @ViewChild('treeComponent')
    treeComponent!: ElementRef;

    @ViewChildren('nodeLink')
    set nodeLinks(links: QueryList<ElementRef<HTMLAnchorElement>>) {
        this._nodeLinks = links;
        this._setTabIndexToFirstNode();
    }

    get nodeLinks(): QueryList<ElementRef<HTMLAnchorElement>> {
        return this._nodeLinks;
    }

    get visibleNodes(): NavigationNode[] {
        return this.treeControl.dataNodes.filter((dataNode: NavigationNode) => {
            return this.nodeLinks.find((link) => {
                return link.nativeElement.innerText.includes(dataNode.data?.text ?? '');
            });
        });
    }

    private get _selectedBranch() {
        return this.treeControl.dataNodes.find(
            (dataNode: NavigationNode) => dataNode?.data && this._selectNode(dataNode.data)
        );
    }

    constructor(private _router: Router, private _route: ActivatedRoute) {
        this._initDataSource();
        this.currentPath = this._router.url.slice(1).split('?', 1)[0];
    }

    ngOnInit() {
        this._router.events.pipe(takeUntil(this._destroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._selected = null;
                this.currentPath = event.urlAfterRedirects.slice(1).split('?', 1)[0];
                this._markSelectedNode();
            }
        });
    }

    private _markSelectedNode() {
        this.navigationTree.forEach((node: NavigationMenu) => {
            this._selectNode(node);
        });

        this._setTabIndexToFirstNode();
    }

    private _selectNode(node: NavigationMenu): boolean {
        node.selected = false;

        if (node?.link === this.currentPath) {
            node.selected = true; // render node as selected
            this._selected = node;
            return true;
        }

        if (node.childMenus) {
            let foundSelectedNode = false;
            for (const childNode of node.childMenus) {
                if (this._selectNode(childNode)) {
                    node.selected = true;
                    foundSelectedNode = true;
                    this._selected = childNode;
                }
            }
            return foundSelectedNode;
        }

        return false;
    }

    private _transformer = (menu: NavigationMenu, level: number): NavigationNode => {
        return {
            data: menu,
            level: level,
            expandable: !!menu.childMenus && menu.childMenus.length > 0,
            id: this._getNodeId(menu),
        };
    };

    _getNodeId(node: NavigationMenu) {
        let prefix = node.text?.replace(' ', '-').toLowerCase() ?? '';
        return `navigation-${prefix}-${IDGenerator.generateID()}`;
    }

    private _getLevel = (node: NavigationNode) => node.level ?? 0;
    private _isExpandable = (node: NavigationNode) => !!node.data?.childMenus && node.data?.childMenus.length > 0;
    private _getChildren = (node: NavigationMenu): NavigationMenu[] | undefined => node.childMenus;
    hasChild = (_: number, node: NavigationNode) => node.expandable ?? false;

    private _initDataSource() {
        this._treeFlattener = new MatTreeFlattener(
            this._transformer,
            this._getLevel,
            this._isExpandable,
            this._getChildren
        );
        this.treeControl = new FlatTreeControl<NavigationNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this._treeFlattener);
    }

    toggleExpand(menu: NavigationMenu) {
        menu.expanded = true;
    }

    getAriaOwnsId(elem: HTMLElement, node: NavigationNode) {
        let nodeParent = this._getNodeParentElement(elem, node.level ?? 0);
        return nodeParent?.children[0]?.id;
    }

    nodeKeyUp(event: KeyboardEvent, node: NavigationNode, nodeElement: HTMLElement) {
        const isExpanded = this.treeControl.isExpanded(node);
        const isExpandable = this.treeControl.isExpandable(node);

        event.stopPropagation();

        switch (event.code) {
            case NavKeys.ENTER:
            case NavKeys.SPACE:
                if (node?.data?.link != null) {
                    this.navigateToLink(node);
                } else {
                    this.treeControl.expand(node);
                }
                return;
            case NavKeys.UP:
                /** Set focus to the previous node sibling*/
                (nodeElement.previousElementSibling?.children[0] as HTMLElement)?.focus();
                return;
            case NavKeys.DOWN:
                /** Set focus to the next node sibling*/
                (nodeElement.nextElementSibling?.children[0] as HTMLElement)?.focus();
                return;
            case NavKeys.RIGHT:
                /** If a node can be expandable and is already expanded then move focus to the next node sibling else just expand node */
                isExpandable && isExpanded
                    ? (nodeElement.nextElementSibling?.children[0] as HTMLElement)?.focus()
                    : this.treeControl.expand(node);
                return;
            case NavKeys.LEFT:
                /** If a node can be expandable and is already expanded then collapse nodes */
                if (isExpandable && isExpanded) {
                    this.treeControl.collapse(node);
                }

                /** If a node is not expandable than move focus to the parent node */
                if (!isExpandable) {
                    const level = this.treeControl.getLevel(node);
                    this._getNodeParentElement(nodeElement, level)?.focus();
                }
                return;
            case NavKeys.HOME:
                this.nodeLinks?.first?.nativeElement?.focus();
                break;
            case NavKeys.END:
                this.nodeLinks?.last?.nativeElement?.focus();
                return;
            default:
                break;
        }

        const isAlpha = new RegExp(/[a-zA-Z]/).test(event.key);
        if (isAlpha) {
            this._findNextMenuItem(event.key.toLowerCase());
        }

        if (event.key === '*') {
            this._expandCurrentLevel(node);
        }
    }

    private _findNextMenuItem(keystroke: string) {
        // find currently focused link
        const sortedNodeLinks = this.nodeLinks
            ?.toArray()
            .sort((a: ElementRef<HTMLElement>, b: ElementRef<HTMLElement>) => {
                const aIdx = this.visibleNodes.findIndex((node) =>
                    a.nativeElement.innerText.includes(node.data?.text as string)
                );
                const bIdx = this.visibleNodes.findIndex((node) =>
                    b.nativeElement.innerText.includes(node.data?.text as string)
                );

                return aIdx - bIdx;
            });

        const focusedLinkIndex = sortedNodeLinks.findIndex(
            (link) => link.nativeElement.id === document.activeElement?.id
        );

        // Create wrapped list based on current node's index
        const wrappedDataLinks: ElementRef<HTMLElement>[] = [
            ...sortedNodeLinks.slice(focusedLinkIndex + 1),
            ...sortedNodeLinks.slice(0, focusedLinkIndex),
        ];

        // Find next node with keystroke letter
        for (const link of wrappedDataLinks) {
            const spanElement = link.nativeElement.querySelector('.vc-nav-menu-node-label');
            let letter = spanElement?.textContent?.charAt(0).toLowerCase() ?? '';

            if (letter === keystroke) {
                link.nativeElement.focus();
                break;
            }
        }
    }

    private _expandCurrentLevel(node: NavigationNode) {
        let level = node.level;

        this.treeControl.dataNodes.forEach((node) => {
            if (node.level === level) {
                this.treeControl.expand(node);
            }
        });
    }

    private _getNodeParentElement(elem: HTMLElement, level: number): HTMLElement | null {
        let selector = `[aria-level="${level}"]`;
        let sibling = elem.previousElementSibling;
        /** If the sibling matches our selector, use it,
         * If not, jump to the next sibling and continue the loop */
        while (sibling) {
            if (sibling.matches(selector)) {
                return sibling as HTMLElement;
            }
            sibling = sibling.previousElementSibling;
        }

        return null;
    }

    private _setTabIndexToFirstNode() {
        // Set tabindex 0 to the first menu item in a case when no selected node
        if (!this._selected) {
            this.nodeLinks?.first?.nativeElement?.setAttribute('tabindex', '0');
        }
    }

    public async navigateToLink(node?: NavigationNode) {
        const path = node?.data?.link;
        if (path != null) {
            await this._router.navigate([`${path}`]);
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}

enum NavKeys {
    ENTER = 'Enter',
    SPACE = 'Space',
    UP = 'ArrowUp',
    DOWN = 'ArrowDown',
    LEFT = 'ArrowLeft',
    RIGHT = 'ArrowRight',
    HOME = 'Home',
    END = 'End',
}

export class NavigationNode {
    data?: NavigationMenu;
    level?: number;
    expandable?: boolean;
    id?: string;
}
