import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

export declare type BadgeStatus = 'info' | 'success' | 'warning' | 'error' | 'neutral';
export declare type BadgeTooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';

/** Example of badge usage
 * HTML
 *  <vc-badge label="Info" status="info"></vc-badge>
 * */
@Component({
    selector: 'vc-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
    textTruncated: boolean = true;

    /** Specifies badge color */
    @Input()
    status: BadgeStatus = 'neutral';

    /** Specifies badge label */
    @Input()
    label!: string;

    /** Specifies prefix icon */
    @Input()
    prefixIcon!: string;

    /** Specifies suffix icon */
    @Input()
    suffixIcon!: string;

    /** Specifies tooltip */
    @Input()
    tooltip!: string;

    /** Specifies tooltip position */
    @Input()
    tooltipPosition: BadgeTooltipPosition = 'above';

    /** Whether to show tooltip */
    @Input()
    showTooltip: boolean = false;

    @ViewChild('textElement')
    textElement!: ElementRef;

    @HostListener('mouseover')
    checkIfTextTruncated() {
        const element = this.textElement?.nativeElement;
        this.textTruncated = element?.scrollWidth > element?.clientWidth;
    }
}
