<vc-button *ngIf="buttonMode === 'icon'"
            mode="icon"
           iconName="more_vert"
           iconColor="var(--text-high-emphasis)"
           [ariaLabel]="ariaLabel"
           [triggerFor]="actionMenu"
           (trigger)="open.emit()"
           [disabled]="disabled"></vc-button>
<vc-button *ngIf="buttonMode !== 'icon'"
            [mode]="buttonMode"
            [label]="buttonLabel"
            [ariaLabel]="ariaLabel"
            [triggerFor]="actionMenu"
            (trigger)="open.emit()"
            [disabled]="disabled">
</vc-button>
<mat-menu #actionMenu="matMenu">
    <ng-container *ngFor="let action of actions">
        <button
            *ngIf="action.visible ?? true"
            mat-menu-item
            class="vc-action-menu-item"
            [disabled]="action.disabled ?? false"
            (click)="trigger.emit(action)">
            <mat-icon *ngIf="action.prefixIcon" [style.color]="action.prefixIconColor">{{
                action.prefixIcon
            }}</mat-icon>
            <span *ngIf="action.label">{{ action.label }}</span>
            <mat-icon *ngIf="action.suffixIcon" class="vc-action-suffix-icon" [style.color]="action.suffixIconColor">{{
                action.suffixIcon
            }}</mat-icon>
            <ng-container
                *ngIf="action.template"
                [ngTemplateOutlet]="action.template"
                [ngTemplateOutletContext]="{ $implicit: action }"></ng-container>
        </button>
    </ng-container>
</mat-menu>
