import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { remove } from 'lodash-es';

/** Example of form usage
 * HTML
 * <vc-common-form (primaryTrigger)="submitForm($event)">
 *     <vc-input [(value)]="inputValue"
 *                label="Validation function"
 *                [validationFunction]="validateInput"></vc-input>
 *     <vc-input [(value)]="requiredValue"
 *               label="Required name field"
 *               [required]="true"></vc-input>
 *     <vc-input [(value)]="minLengthValue"
 *               label="Min Length 5"
 *               [minLength]="5"></vc-input>
 *     <vc-input [(value)]="maxLengthValue"
 *               label="Max Length 10"
 *               [maxLength]="10"></vc-input>
 *   <vc-date-picker label="Date"
 *                   [required]="true"
 *                   [minDate]="now"
 *                   [(value)]="date"></vc-date-picker>
 *   <vc-date-picker-range label="Date Range"
 *                         [required]="true"
 *                         [(value)]="range"></vc-date-picker-range>
 * </vc-common-form>
 *
 * TS
 *     inputValue = '';
 *     requiredValue = '';
 *     minLengthValue = '';
 *     maxLengthValue = '';
 *     date!: Date | null;
 *     range: DateRange = { startDate: new Date(), endDate: new Date() };
 *     now = new Date();
 *
 *     submitForm(valid: boolean) {
 *         if (!valid) {
 *             return;
 *         }
 *         ......
 *     }
 *
 *     validateInput = (value: string) => {
 *         return value?.length > 0 && value != 'test' ? "Value is not equal 'test'" : '';
 *     };
 * */

@Component({
    selector: 'vc-common-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
})
export class FormComponent {
    valid: boolean = true;

    private _formFields: Array<any> = new Array<any>();

    /** Form header */
    @Input()
    header!: string;

    /** Submit button label */
    @Input()
    primaryLabel: string = $localize`:@@COMMON_UI.SAVE:Save`;

    /** Cancel button label */
    @Input()
    secondaryLabel: string = $localize`:@@COMMON_UI.CANCEL:Cancel`;

    /** Identifies the element (or elements) that labels the form. */
    @Input()
    ariaLabeledBy!: string;

    /** Identifies the element (or elements) that describes the form. */
    @Input()
    ariaDescribedBy!: string;

    /** Specifies direction for display form buttons */
    @Input()
    buttonsDirection: 'column' | 'row' = 'row';

    /** Specifies position for display form buttons */
    @Input()
    buttonsPosition: 'start' | 'center' | 'end' | 'space-between' | 'space-around' = 'start';

    /** Whether to show buttons footer */
    @Input()
    showButtons: boolean = true;

    /** Whether to show submit button */
    @Input()
    showPrimaryButton: boolean = true;

    /** Whether to show cancel button */
    @Input()
    showSecondaryButton: boolean = true;

    /** Whether to disable submit button */
    @Input()
    disablePrimaryButton: boolean = false;

    /** Whether to disable cancel button */
    @Input()
    disableSecondaryButton: boolean = false;

    /** Whether to disable cancel button */
    @Input()
    clearFieldsOnSecondary: boolean = true;

    @ViewChild('formRef')
    formRef!: NgForm;

    /** Event is fired on form submit when user click Submit button */
    @Output()
    primaryTrigger = new EventEmitter<boolean>();

    /** Event is fired when user click Cancel button */
    @Output()
    secondaryTrigger = new EventEmitter();

    submit(): boolean {
        this._formFields.forEach((field) => {
            field && (field.touched = true);
            field?.validate();
        });
        this.valid = this._formFields?.find((field) => !field.valid) == null;
        this.primaryTrigger.emit(this.valid);

        return this.valid;
    }

    cancelTrigger() {
        this.clear(this.clearFieldsOnSecondary);
        setTimeout(() => {
            this.secondaryTrigger.emit();
        });
    }

    clear(clearFields = true) {
        this._formFields.forEach((field) => {
            if (clearFields) {
                field?.clear();
            } else {
                field && (field.touched = false);
            }
        });
        this.valid = true;
    }

    addFormField(field: any) {
        this._formFields.push(field);
    }

    removeFormField(field: any) {
        remove(this._formFields, field);
    }
}
