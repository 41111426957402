<vc-dialog
    [title]="title"
    [primaryLabel]="primaryLabel"
    [(visible)]="visible"
    [closeOnPrimary]="true"
    [primaryDisabled]="!selectedOrgNode"
    (primaryTrigger)="selectOrg()">
    <vc-input
        *ngIf="searchByFilter"
        class="space-bottom-16"
        prefixIcon="search"
        [label]="filterLabel"
        [placeholder]="filterPlaceholder"
        [showClear]="true"
        [(value)]="filterValue"
        (valueChange)="filterValueChange.emit($event)"></vc-input>
    <vc-tree
        [showFilter]="!searchByFilter"
        style="width: 700px; overflow-y: hidden"
        [(highlighted)]="selectedOrgNode"
        [showCheckIconForHighlighted]="!showTags"
        [data]="orgTreeNodes"
        [itemRenderer]="orgItemRenderer"
        [highlightable]="true"
        height="630px">
        <ng-template let-org>
            <div
                *ngIf="showTags"
                class="vc-org-picker-node"
                [style.margin-right]="selectedOrgNode === org ? '8px' : '44px'">
                <vc-chip-tags [tags]="org.tags" [showCount]="1" [wrap]="false"></vc-chip-tags>
                <vc-icon
                    *ngIf="selectedOrgNode === org"
                    class="vc-highlighted-node-icon"
                    name="done"
                    color="var(--text-low-emphasis)"></vc-icon>
            </div>
        </ng-template>
    </vc-tree>
</vc-dialog>
