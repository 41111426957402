export class Country {
    name: string;
    phoneCode!: string;
    alpha2!: string;
    allowedLengths!: Array<number>;
    mask!: string | undefined;

    constructor(name: string, phoneCode?: string, alpha2?: string, allowedLengths?: Array<number>, mask?: string) {
        this.name = name;
        this.phoneCode = phoneCode ?? '';
        this.alpha2 = alpha2 ?? '';
        this.allowedLengths = allowedLengths ?? [];
        this.mask = mask;
    }

    static getCountryByCode(code: string, number?: string): Country | undefined {
        if (code === '1' && number) {
            // Check for Canada area codes
            const areaCode = number.substring(0, 3);
            if (canadaAreaCode.some((code: string) => code === areaCode)) {
                return new Country('Canada', '1', 'CA', [10], '(999)999-9999');
            }

            if (puertoRico.some((code: string) => code === areaCode)) {
                return new Country('Puerto Rico', '1', 'PR', [10]);
            }

            if (dominicanRepublic.some((code: string) => code === areaCode)) {
                return new Country('Dominican Republic (República Dominicana)', '1', 'DO', [10]);
            }

            return new Country('United States', '1', 'US', [10], '(999) 999-9999');
        }

        return allCountries.find((value) => value.phoneCode == code);
    }
}

export const allCountries: Array<Country> = [
    new Country('United States', '1', 'US', [10], '(999) 999-9999'),
    new Country('Canada', '1', 'CA', [10], '(999)999-9999'),
    new Country('Puerto Rico', '1', 'PR', [10]),
    new Country('Dominican Republic (República Dominicana)', '1', 'DO', [10]),
    new Country('Afghanistan (‫افغانستان‬‎)', '93', 'AF', [9]),
    new Country('Albania (Shqipëri)', '355', 'AL', [6, 7, 8, 9]),
    new Country('Algeria (‫الجزائر‬‎)', '213', 'DZ', [8, 9]),
    new Country('American Samoa', '1684', 'AS', [10]),
    new Country('Andorra', '376', 'AD', [6, 8, 9]),
    new Country('Angola', '244', 'AO', [9]),
    new Country('Anguilla', '1264', 'AI', [10]),
    new Country('Antigua and Barbuda', '1268', 'AG', [10]),
    new Country('Argentina', '54', 'AR', [10, 11]),
    new Country('Armenia (Հայաստան)', '374', 'AM', [8]),
    new Country('Aruba', '297', 'AW', [7]),
    new Country('Australia', '61', 'AU', [5, 6, 7, 8, 9, 10]),
    new Country('Austria (Österreich)', '43', 'AT', [4, 5, 6, 7, 8, 9, 10, 11, 12, 13]),
    new Country('Azerbaijan (Azərbaycan)', '994', 'AZ', [9]),
    new Country('Bahamas', '1242', 'BS', [10]),
    new Country('Bahrain (‫البحرين‬‎)', '973', 'BH', [8]),
    new Country('Bangladesh (বাংলাদেশ)', '880', 'BD', [6, 7, 8, 9, 10]),
    new Country('Barbados', '1246', 'BB', [10]),
    new Country('Belarus (Беларусь)', '375', 'BY', [6, 7, 8, 9, 10, 11]),
    new Country('Belgium (België)', '32', 'BE', [8, 9]),
    new Country('Belize', '501', 'BZ', [7, 11]),
    new Country('Benin (Bénin)', '229', 'BJ', [8]),
    new Country('Bermuda', '1441', 'BM', [10]),
    new Country('Bhutan (འབྲུག)', '975', 'BT', [7, 8]),
    new Country('Bolivia', '591', 'BO', [8, 9]),
    new Country('Bosnia and Herzegovina (Босна и Херцеговина)', '387', 'BA', [8, 9]),
    new Country('Botswana', '267', 'BW', [7, 8]),
    new Country('Brazil (Brasil)', '55', 'BR', [8, 9, 10, 11]),
    new Country('British Indian Ocean Territory', '246', 'IO', [7]),
    new Country('British Virgin Islands', '1284', 'VG', [10]),
    new Country('Brunei', '673', 'BN', [7]),
    new Country('Bulgaria (България)', '359', 'BG', [6, 7, 8, 9]),
    new Country('Burkina Faso', '226', 'BF', [8]),
    new Country('Burundi (Uburundi)', '257', 'BI', [8]),
    new Country('Cambodia (កម្ពុជា)', '855', 'KH', [8, 9, 10]),
    new Country('Cameroon (Cameroun)', '237', 'CM', [8, 9]),
    new Country('Cape Verde (Kabu Verdi)', '238', 'CV', [7]),
    new Country('Caribbean Netherlands', '599', 'BQ', [7]),
    new Country('Cayman Islands', '1345', 'KY', [10]),
    new Country('Central African Republic (République centrafricaine)', '236', 'CF', [8]),
    new Country('Chad (Tchad)', '235', 'TD', [8]),
    new Country('Chile', '56', 'CL', [9, 10, 11]),
    new Country('China (中国)', '86', 'CN', [7, 8, 9, 10, 11, 12]),
    new Country('Christmas Island', '61', 'CX', [6, 7, 8, 9, 10]),
    new Country('Cocos (Keeling) Islands', '61', 'CC', [6, 7, 8, 9, 10]),
    new Country('Colombia', '57', 'CO', [8, 10, 11]),
    new Country('Comoros (‫جزر القمر‬‎)', '269', 'KM', [7]),
    new Country('Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', '243', 'CD', [7, 9]),
    new Country('Congo (Republic) (Congo-Brazzaville)', '242', 'CG', [9]),
    new Country('Cook Islands', '682', 'CK', [5]),
    new Country('Costa Rica', '506', 'CR', [8, 10]),
    new Country('Côte d’Ivoire', '225', 'CI', [8]),
    new Country('Croatia (Hrvatska)', '385', 'HR', [6, 7, 8, 9]),
    new Country('Cuba', '53', 'CU', [6, 7, 8]),
    new Country('Curaçao', '599', 'CW', [7, 8]),
    new Country('Cyprus (Κύπρος)', '357', 'CY', [8]),
    new Country('Czech Republic (Česká republika)', '420', 'CZ', [9, 10, 11, 12]),
    new Country('Denmark (Danmark)', '45', 'DK', [8]),
    new Country('Djibouti', '253', 'DJ', [8]),
    new Country('Dominica', '1767', 'DM', [10]),
    new Country('Ecuador', '593', 'EC', [8, 9, 10, 11]),
    new Country('Egypt (‫مصر‬‎)', '20', 'EG', [8, 9, 10]),
    new Country('El Salvador', '503', 'SV', [7, 8, 11]),
    new Country('Equatorial Guinea (Guinea Ecuatorial)', '240', 'GQ', [9]),
    new Country('Eritrea', '291', 'ER', [7]),
    new Country('Estonia (Eesti)', '372', 'EE', [7, 8, 10]),
    new Country('Ethiopia', '251', 'ET', [9]),
    new Country('Falkland Islands (Islas Malvinas)', '500', 'FK', [5]),
    new Country('Faroe Islands (Føroyar)', '298', 'FO', [6]),
    new Country('Fiji', '679', 'FJ', [7, 11]),
    new Country('Finland (Suomi)', '358', 'FI', [5, 6, 7, 8, 9, 10, 11, 12]),
    new Country('France', '33', 'FR', [9]),
    new Country('French Guiana (Guyane française)', '594', 'GF', [9]),
    new Country('French Polynesia (Polynésie française)', '689', 'PF', [6, 8]),
    new Country('Gabon', '241', 'GA', [7, 8]),
    new Country('Gambia', '220', 'GM', [7]),
    new Country('Georgia (საქართველო)', '995', 'GE', [9]),
    new Country('Germany (Deutschland)', '49', 'DE', [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]),
    new Country('Ghana (Gaana)', '233', 'GH', [8, 9]),
    new Country('Gibraltar', '350', 'GI', [8]),
    new Country('Greece (Ελλάδα)', '30', 'GR', [10]),
    new Country('Greenland (Kalaallit Nunaat)', '299', 'GL', [6]),
    new Country('Grenada', '1473', 'GD', [10]),
    new Country('Guadeloupe', '590', 'GP', [9]),
    new Country('Guam', '1671', 'GU', [10]),
    new Country('Guatemala', '502', 'GT', [8, 11]),
    new Country('Guernsey', '44', 'GG', [7, 9, 10]),
    new Country('Guinea (Guinée)', '224', 'GN', [8, 9]),
    new Country('Guinea-Bissau (Guiné Bissau)', '245', 'GW', [7, 9]),
    new Country('Guyana', '592', 'GY', [7]),
    new Country('Haiti', '509', 'HT', [8]),
    new Country('Honduras', '504', 'HN', [8]),
    new Country('Hong Kong (香港)', '852', 'HK', [5, 6, 7, 8, 9, 11]),
    new Country('Hungary (Magyarország)', '36', 'HU', [8, 9]),
    new Country('Iceland (Ísland)', '354', 'IS', [7, 9]),
    new Country('India (भारत)', '91', 'IN', [8, 9, 10, 11, 12, 13]),
    new Country('Indonesia', '62', 'ID', [7, 8, 9, 10, 11, 12]),
    new Country('Iran (‫ایران‬‎)', '98', 'IR', [6, 7, 10]),
    new Country('Iraq (‫العراق‬‎)', '964', 'IQ', [8, 9, 10]),
    new Country('Ireland', '353', 'IE', [7, 8, 9, 10]),
    new Country('Isle of Man', '44', 'IM', [10]),
    new Country('Israel (‫ישראל‬‎)', '972', 'IL', [4, 7, 8, 9, 10, 11, 12]),
    new Country('Italy (Italia)', '39', 'IT', [6, 7, 8, 9, 10, 11]),
    new Country('Jamaica', '1876', 'JM', [10]),
    new Country('Japan (日本)', '81', 'JP', [8, 9, 10, 11, 12, 13, 14, 15, 16, 17]),
    new Country('Jersey', '44', 'JE', [10]),
    new Country('Jordan (‫الأردن‬‎)', '962', 'JO', [8, 9]),
    new Country('Kazakhstan (Казахстан)', '7', 'KZ', [10]),
    new Country('Kenya', '254', 'KE', [7, 8, 9, 10]),
    new Country('Kiribati', '686', 'KI', [5, 8]),
    new Country('Kosovo', '383', 'XK', [8, 9]),
    new Country('Kuwait (‫الكويت‬‎)', '965', 'KW', [7, 8]),
    new Country('Kyrgyzstan (Кыргызстан)', '996', 'KG', [9, 10]),
    new Country('Laos (ລາວ)', '856', 'LA', [8, 9, 10]),
    new Country('Latvia (Latvija)', '371', 'LV', [8]),
    new Country('Lebanon (‫لبنان‬‎)', '961', 'LB', [7, 8]),
    new Country('Lesotho', '266', 'LS', [8]),
    new Country('Liberia', '231', 'LR', [7, 8, 9]),
    new Country('Libya (‫ليبيا‬‎)', '218', 'LY', [9]),
    new Country('Liechtenstein', '423', 'LI', [7, 9]),
    new Country('Lithuania (Lietuva)', '370', 'LT', [8]),
    new Country('Luxembourg', '352', 'LU', [4, 5, 6, 7, 8, 9, 10, 11]),
    new Country('Macau (澳門)', '853', 'MO', [8]),
    new Country('Macedonia (FYROM) (Македонија)', '389', 'MK', [8]),
    new Country('Madagascar (Madagasikara)', '261', 'MG', [9]),
    new Country('Malawi', '265', 'MW', [7, 9]),
    new Country('Malaysia', '60', 'MY', [8, 9, 10]),
    new Country('Maldives', '960', 'MV', [7, 10]),
    new Country('Mali', '223', 'ML', [8]),
    new Country('Malta', '356', 'MT', [8]),
    new Country('Marshall Islands', '692', 'MH', [7]),
    new Country('Martinique', '596', 'MQ', [9]),
    new Country('Mauritania (‫موريتانيا‬‎)', '222', 'MR', [8]),
    new Country('Mauritius (Moris)', '230', 'MU', [7, 8]),
    new Country('Mayotte', '262', 'YT', [9]),
    new Country('Mexico (México)', '52', 'MX', [10, 11]),
    new Country('Micronesia', '691', 'FM', [7]),
    new Country('Moldova (Republica Moldova)', '373', 'MD', [8]),
    new Country('Monaco', '377', 'MC', [8, 9]),
    new Country('Mongolia (Монгол)', '976', 'MN', [8, 9, 10]),
    new Country('Montenegro (Crna Gora)', '382', 'ME', [8]),
    new Country('Montserrat', '1664', 'MS', [10]),
    new Country('Morocco (‫المغرب‬‎)', '212', 'MA', [9]),
    new Country('Mozambique (Moçambique)', '258', 'MZ', [8, 9]),
    new Country('Myanmar (Burma) (မြန်မာ)', '95', 'MM', [6, 7, 8, 9, 10]),
    new Country('Namibia (Namibië)', '264', 'NA', [8, 9]),
    new Country('Nauru', '674', 'NR', [7]),
    new Country('Nepal (नेपाल)', '977', 'NP', [8, 10]),
    new Country('Netherlands (Nederland)', '31', 'NL', [5, 6, 7, 8, 9, 10]),
    new Country('New Caledonia (Nouvelle-Calédonie)', '687', 'NC', [6]),
    new Country('New Zealand', '64', 'NZ', [8, 9, 10]),
    new Country('Nicaragua', '505', 'NI', [8]),
    new Country('Niger (Nijar)', '227', 'NE', [8]),
    new Country('Nigeria', '234', 'NG', [7, 8, 10, 11, 12, 13, 14]),
    new Country('Niue', '683', 'NU', [4]),
    new Country('Norfolk Island', '672', 'NF', [6]),
    new Country('North Korea (조선 민주주의 인민 공화국)', '850', 'KP', [8, 10]),
    new Country('Northern Mariana Islands', '1670', 'MP', [10]),
    new Country('Norway (Norge)', '47', 'NO', [5, 8]),
    new Country('Oman (‫عُمان‬‎)', '968', 'OM', [7, 8, 9]),
    new Country('Pakistan (‫پاکستان‬‎)', '92', 'PK', [8, 9, 10, 11, 12]),
    new Country('Palau', '680', 'PW', [7]),
    new Country('Palestine (‫فلسطين‬‎)', '970', 'PS', [8, 9, 10]),
    new Country('Panama (Panamá)', '507', 'PA', [7, 8]),
    new Country('Papua New Guinea', '675', 'PG', [7, 8]),
    new Country('Paraguay', '595', 'PY', [6, 7, 8, 9]),
    new Country('Peru (Perú)', '51', 'PE', [8, 9]),
    new Country('Philippines', '63', 'PH', [6, 8, 9, 10, 11, 12, 13]),
    new Country('Poland (Polska)', '48', 'PL', [6, 7, 8, 9]),
    new Country('Portugal', '351', 'PT', [9]),
    new Country('Qatar (‫قطر‬‎)', '974', 'QA', [7, 8]),
    new Country('Réunion (La Réunion)', '262', 'RE', [9]),
    new Country('Romania (România)', '40', 'RO', [6, 9]),
    new Country('Russia (Россия)', '7', 'RU', [10]),
    new Country('Rwanda', '250', 'RW', [8, 9]),
    new Country('Saint Barthélemy', '590', 'BL', [9]),
    new Country('Saint Helena', '290', 'SH', [4, 5]),
    new Country('Saint Kitts and Nevis', '1869', 'KN', [10]),
    new Country('Saint Lucia', '1758', 'LC', [10]),
    new Country('Saint Martin (Saint-Martin (partie française))', '590', 'MF', [9]),
    new Country('Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', '508', 'PM', [6]),
    new Country('Saint Vincent and the Grenadines', '1784', 'VC', [10]),
    new Country('Samoa', '685', 'WS', [5, 6, 7]),
    new Country('San Marino', '378', 'SM', [8, 10]),
    new Country('São Tomé and Príncipe (São Tomé e Príncipe)', '239', 'ST', [7]),
    new Country('Saudi Arabia (‫المملكة العربية السعودية‬‎)', '966', 'SA', [9, 10]),
    new Country('Senegal (Sénégal)', '221', 'SN', [9]),
    new Country('Serbia (Србија)', '381', 'RS', [6, 7, 8, 9, 10, 11, 12]),
    new Country('Seychelles', '248', 'SC', [7]),
    new Country('Sierra Leone', '232', 'SL', [8]),
    new Country('Singapore', '65', 'SG', [8, 10, 11]),
    new Country('Sint Maarten', '1721', 'SX', [10]),
    new Country('Slovakia (Slovensko)', '421', 'SK', [6, 7, 9]),
    new Country('Slovenia (Slovenija)', '386', 'SI', [5, 6, 7, 8]),
    new Country('Solomon Islands', '677', 'SB', [5, 7]),
    new Country('Somalia (Soomaaliya)', '252', 'SO', [6, 7, 8, 9]),
    new Country('South Africa', '27', 'ZA', [5, 6, 7, 8, 9]),
    new Country('South Korea (대한민국)', '82', 'KR', [5, 6, 8, 9, 10, 11, 12, 13, 14]),
    new Country('South Sudan (‫جنوب السودان‬‎)', '211', 'SS', [9]),
    new Country('Spain (España)', '34', 'ES', [9]),
    new Country('Sri Lanka (ශ්‍රී ලංකාව)', '94', 'LK', [9]),
    new Country('Sudan (‫السودان‬‎)', '249', 'SD', [9]),
    new Country('Suriname', '597', 'SR', [6, 7]),
    new Country('Svalbard and Jan Mayen', '47', 'SJ', [5, 8]),
    new Country('Swaziland', '268', 'SZ', [8]),
    new Country('Sweden (Sverige)', '46', 'SE', [6, 7, 8, 9, 10, 12]),
    new Country('Switzerland (Schweiz)', '41', 'CH', [9, 12]),
    new Country('Syria (‫سوريا‬‎)', '963', 'SY', [8, 9]),
    new Country('Taiwan (台灣)', '886', 'TW', [7, 8, 9, 10]),
    new Country('Tajikistan', '992', 'TJ', [9]),
    new Country('Tanzania', '255', 'TZ', [7, 9]),
    new Country('Thailand (ไทย)', '66', 'TH', [8, 9, 10]),
    new Country('Timor-Leste', '670', 'TL', [7, 8]),
    new Country('Togo', '228', 'TG', [8]),
    new Country('Tokelau', '690', 'TK', [4, 5, 6, 7]),
    new Country('Tonga', '676', 'TO', [5, 7]),
    new Country('Trinidad and Tobago', '1868', 'TT', [10]),
    new Country('Tunisia (‫تونس‬‎)', '216', 'TN', [8]),
    new Country('Turkey (Türkiye)', '90', 'TR', [7, 10]),
    new Country('Turkmenistan', '993', 'TM', [8]),
    new Country('Turks and Caicos Islands', '1649', 'TC', [10]),
    new Country('Tuvalu', '688', 'TV', [5, 6, 7]),
    new Country('U.S. Virgin Islands', '1340', 'VI', [10]),
    new Country('Uganda', '256', 'UG', [9]),
    new Country('Ukraine (Україна)', '380', 'UA', [9], '(99) 999 99 99'),
    new Country('United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', '971', 'AE', [5, 6, 7, 8, 9, 10, 11, 12]),
    new Country('United Kingdom', '44', 'GB', [7, 9, 10], '999 999999'),
    new Country('Uruguay', '598', 'UY', [7, 8]),
    new Country('Uzbekistan (Oʻzbekiston)', '998', 'UZ', [9]),
    new Country('Vanuatu', '678', 'VU', [5, 7]),
    new Country('Vatican City (Città del Vaticano)', '39', 'VA', [6, 8, 9, 10, 11]),
    new Country('Venezuela', '58', 'VE', [10]),
    new Country('Vietnam (Việt Nam)', '84', 'VN', [7, 8, 9, 10]),
    new Country('Wallis and Futuna (Wallis-et-Futuna)', '681', 'WF', [6]),
    new Country('Western Sahara (‫الصحراء الغربية‬‎)', '212', 'EH', [9]),
    new Country('Yemen (‫اليمن‬‎)', '967', 'YE', [7, 8, 9]),
    new Country('Zambia', '260', 'ZM', [9]),
    new Country('Zimbabwe', '263', 'ZW', [5, 6, 7, 8, 9, 10]),
    new Country('Åland Islands', '358', 'AX', [5, 6, 7, 8, 9, 10, 11, 12]),
];

export const dominicanRepublic: string[] = ['809', '829', '849'];

export const puertoRico: string[] = ['787', '939'];

export const canadaAreaCode: string[] = [
    '204',
    '226',
    '236',
    '249',
    '250',
    '263',
    '289',
    '306',
    '343',
    '354',
    '365',
    '367',
    '368',
    '403',
    '416',
    '418',
    '431',
    '437',
    '438',
    '450',
    '468',
    '474',
    '506',
    '514',
    '519',
    '548',
    '579',
    '581',
    '584',
    '587',
    '604',
    '613',
    '639',
    '647',
    '672',
    '683',
    '705',
    '709',
    '742',
    '753',
    '778',
    '780',
    '782',
    '807',
    '819',
    '825',
    '867',
    '873',
    '902',
    '905',
];
