<vc-badge *ngFor="let val of displayTags | slice:0: showCount;"
          class="space-4"
          [tooltipPosition]="tooltipPosition"
          [tooltip]="val.displayValue"
          [prefixIcon]="val.prefix | tagIcon"
          [label]="val.displayValue"></vc-badge>
<vc-badge *ngIf="displayTags.length > showCount"
          class="space-4 vc-tags-count"
          label="+{{ displayTags.length - showCount }}"
          [showTooltip]="true"
          [tooltip]="notVisibleTagsTooltip"
          [tooltipPosition]="tooltipPosition"></vc-badge>
