import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'textFormatEllipsis'})
export class TextFormatEllipsisPipe implements PipeTransform {
  private static DEFAULT_TEXT_LENGTH: number = 40;
  private static DEFAULT_START: number = 0;
  private static ELLIPSIS: string = '...';

  transform(value: string, start?: number, length?: number): string {
    let setLength: number = length? length : TextFormatEllipsisPipe.DEFAULT_TEXT_LENGTH;
    let startIndex: number = start? start : TextFormatEllipsisPipe.DEFAULT_START;
    let endIndex: number = setLength - 3;
    if(value.length > setLength) {
      return value.substring(startIndex, endIndex) + TextFormatEllipsisPipe.ELLIPSIS;
    }
    return value;
  }
}
