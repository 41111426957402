import { Directive, Host, HostListener, Input, Optional, Self } from '@angular/core';
import { InputComponent } from '../../components/input/input.component';

const masks: Record<string, string> = {
    float: '^([1-9]+[0-9]*)+([.][0-9]{0,2})?$',
    floatMinZero: '^([0-9]+)+([.][0-9]{0,2})?$',
    number: '^[0-9]*$',
    letters: '^[a-zA-Z]*$',
    alphanumeric: '^[A-Z|a-z|0-9]*$',
    integer: '^-?[0-9]*[.]{0,1}[0-9]{0,2}$',
};

@Directive({
    selector: '[vcMaskPattern]',
})
export class InputMaskPatternDirective {
    @Input()
    set vcMaskPattern(value: string) {
        const mask = masks[value] || value;
        this.regExpr = new RegExp(mask);
    }

    private _oldvalue: string = '';
    private regExpr: any;

    constructor(@Host() @Self() @Optional() private input?: InputComponent) {}

    @HostListener('input', ['$event']) onInput($event: InputEvent) {
        if ($event.target) {
            let item = $event.target as HTMLInputElement;
            let value = item.value;
            let pos = item.selectionStart || 0;
            let noMatch: boolean = !!value && !this.regExpr.test(value);

            if (noMatch) {
                if (this.input) {
                    this.input.value = this._oldvalue;
                }

                item.value = this._oldvalue;
                item.selectionStart = item.selectionEnd = pos - 1;
            } else {
                this._oldvalue = value;
            }
        }
    }
}
