export enum TimeFormat {
    HOURS_12 = 'HOURS_12',
    HOURS_24 = 'HOURS_24',
}

export enum TimePeriod {
    AM = 'AM',
    PM = 'PM',
}

export interface TimeModel {
    hour: number | null;
    minute: number | null;
    format?: TimeFormat;
    period?: TimePeriod;
}
