<vc-input
    *ngIf="showFilter"
    class="vc-tree-search-input space-bottom-16"
    prefixIcon="search"
    [label]="filterLabel"
    [placeholder]="filterPlaceholder"
    [showClear]="true"
    [(value)]="filter"></vc-input>
<mat-tree class="vc-tree-wrapper" [style.height]="height" [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
        *matTreeNodeDef="let node"
        #nodeElement
        matTreeNodeToggle
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
        class="vc-mat-child-tree-node"
        [attr.tabindex]="getNodeTabIndex(node)"
        [attr.aria-selected]="getAriaSelected(node)"
        [class.is-hidden]="filterNode(node)"
        (click)="highlightNode(node)"
        (keydown)="nodeKeyUp($event, node, nodeElement)">
        <div
            class="vc-node-wrapper"
            [class.vc-highlightable-node]="highlightable && node.selectable"
            [class.vc-highlight-node]="checkIfHighlight(node)">
            <button mat-icon-button [disabled]="true" tabindex="-1" [attr.aria-label]="itemRenderer(node.data)"></button>
            <mat-checkbox
                *ngIf="multiselect && node.selectable"
                [checked]="selection.isSelected(node)"
                (change)="nodeLeafSelectionToggle(node)"
                ><vc-tooltip-text [text]="itemRenderer(node.data)"></vc-tooltip-text
            ></mat-checkbox>
            <div *ngIf="!multiselect || !node.selectable" class="vc-tree-node-label">
                <vc-icon *ngIf="node.iconPrefix" [name]="node.iconPrefix" color="var(--primary-700)"></vc-icon>
                <vc-tooltip-text
                    style="overflow: hidden"
                    [class.vc-node-label-bold]="highlightLabelBold"
                    [text]="itemRenderer(node.data)"></vc-tooltip-text>
            </div>
            <ng-template
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="getContextType({ $implicit: node.data })"></ng-template>
            <vc-icon
                *ngIf="showCheckIconForHighlighted && checkIfHighlight(node)"
                class="vc-highlighted-node-icon"
                name="done"
                color="var(--text-low-emphasis)"></vc-icon>
        </div>
    </mat-tree-node>

    <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        #nodeElement
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
        [attr.tabindex]="getNodeTabIndex(node)"
        [attr.aria-selected]="getAriaSelected(node)"
        [class.is-hidden]="filterNode(node)"
        (click)="highlightNode(node)"
        (keydown)="nodeKeyUp($event, node, nodeElement)">
        <div
            class="vc-node-wrapper"
            [class.vc-highlightable-node]="highlightable && node.selectable"
            [class.vc-highlight-node]="checkIfHighlight(node)">
            <button mat-icon-button matTreeNodeToggle tabindex="-1" [attr.aria-label]="'Toggle ' + node.data">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right' }}
                </mat-icon>
            </button>
            <mat-checkbox
                *ngIf="multiselect && node.selectable"
                [checked]="descendantsAllSelected(node)"
                (change)="nodeSelectionToggle(node)"
                ><vc-tooltip-text [text]="itemRenderer(node.data)"></vc-tooltip-text
            ></mat-checkbox>
            <div *ngIf="!multiselect || !node.selectable" class="vc-tree-node-label">
                <vc-icon *ngIf="node.iconPrefix" [name]="node.iconPrefix" color="var(--primary-700)"></vc-icon>
                <vc-tooltip-text
                    style="overflow: hidden"
                    [class.vc-node-label-bold]="highlightLabelBold"
                    [text]="itemRenderer(node.data)"></vc-tooltip-text>
            </div>
            <ng-template
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="getContextType({ $implicit: node.data })"></ng-template>
            <vc-icon
                *ngIf="showCheckIconForHighlighted && checkIfHighlight(node)"
                class="vc-highlighted-node-icon"
                name="done"
                color="var(--text-low-emphasis)"></vc-icon>
        </div>
    </mat-tree-node>
</mat-tree>
