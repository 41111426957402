import { ITagModel } from "./tag.model";

export class TagModel implements ITagModel {
  prefix: string = '';
  name: string = '';
  value: string = '';
  tagValue: string = '';
  isNew: boolean = false;

  set displayPrefix(prefix) {
    this.prefix = prefix;
  }
  get displayPrefix(): string {
    if (this.prefix) {
      if (this.prefix === '/vc') {
        return 'Internal';
      }
      if (this.prefix === '/pvt') {
        return 'Private';
      }
    }
    return 'External';
  }

  set displayValue(value) {
    this.value = value;
  }
  get displayValue(): string {
    let val = this.name;
    if (this.value) {
      val = val.concat(' | ', this.value);
    }
    return val;
  }
}
