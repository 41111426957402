import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum NotificationType {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export class Notification {
    constructor(
        public id: number,
        public type: NotificationType,
        public title: string,
        public message: string,
        public timeout: number,
        public showAlone?: boolean
    ) {}
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private _notify = new Subject<Notification>();
    private _index = 0;

    notify(): Observable<Notification> {
        return this._notify.asObservable();
    }

    info(title: string, message: string, timeout = 4000, showAlone = false) {
        this._notify.next(new Notification(this._index++, NotificationType.INFO, title, message, timeout, showAlone));
    }

    success(title: string, message: string, timeout = 4000, showAlone = false) {
        this._notify.next(
            new Notification(this._index++, NotificationType.SUCCESS, title, message, timeout, showAlone)
        );
    }

    warning(title: string, message: string, timeout = 4000, showAlone = false) {
        this._notify.next(
            new Notification(this._index++, NotificationType.WARNING, title, message, timeout, showAlone)
        );
    }

    error(title: string, message: string, timeout = 4000, showAlone = false) {
        this._notify.next(new Notification(this._index++, NotificationType.ERROR, title, message, timeout, showAlone));
    }
}
