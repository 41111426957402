export abstract class DateUtil {
    public static getUtcDate(date: string | Date) {
        let currentDate: Date = new Date(date);
        return new Date(
            currentDate.getUTCFullYear(),
            currentDate.getUTCMonth(),
            currentDate.getUTCDate(),
            currentDate.getUTCHours(),
            currentDate.getUTCMinutes(),
            currentDate.getUTCSeconds()
        );
    }

    public static isGreaterDate(firstDate: Date, secondDate: Date): boolean {
        return firstDate.getTime() > secondDate.getTime();
    }

    public static isNotGreaterDate(firstDate: Date, secondDate: Date): boolean {
        return !DateUtil.isGreaterDate(firstDate, secondDate);
    }

    public static isSmallerDate(firstDate: Date, secondDate: Date): boolean {
        return firstDate.getTime() < secondDate.getTime();
    }

    public static isNotSmallerDate(firstDate: Date, secondDate: Date): boolean {
        return !DateUtil.isSmallerDate(firstDate, secondDate);
    }

    public static getUTCTimezoneDate(date: Date) {
        let now: Date = new Date(date);
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    public static setUTCTimezoneDate(date: Date) {
        let now: Date = new Date(date);
        return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    }

    public static manageUTCTimezoneDateByOffset(date: Date, offset: string, isGetter: boolean = true) {
        let _offset: string;
        let hours: number;
        let minutes: number;
        let totalMinutes: number;
        let isAdd: boolean = true;
        if (offset.includes('+')) {
            isAdd = true;
            _offset = offset.split('+')[1];
        } else {
            isAdd = false;
            _offset = offset.split('-')[1];
        }

        hours = Number.parseInt(_offset.split(':')[0]);
        minutes = Number.parseInt(_offset.split(':')[1]);
        totalMinutes = hours * 60 + minutes;

        if ((!isAdd && isGetter) || (isAdd && !isGetter)) {
            totalMinutes = totalMinutes * -1;
        }

        return new Date(date.getTime() + totalMinutes * 60000);
    }

    public static getDateWithoutTimezone(date: Date | string | null): Date | null {
        if (date === null) {
            return null;
        } else if (typeof date === 'string') {
            return new Date(date + 'T00:00:00');
        } else {
            return date;
        }
    }

    public static getStringDateWithoutTimezone(date: Date | null): string | null {
        if (date === null) {
            return null;
        } else if (typeof date === 'string') {
            return date;
        } else {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
}
