import { Pipe, PipeTransform } from '@angular/core';
import { ISequenceNumberModel } from '../model/sequence-number.model';
import { StringUtil } from '../util/string.util';

@Pipe({ name: 'sequenceNumber' })
export class SequenceNumberFormatPipe implements PipeTransform {
    private static SEPARATOR: string = '.';
    transform(sequence: ISequenceNumberModel, separator?: string) {
        const _separator = StringUtil.isNotBlankWithTrim(separator as any)
            ? separator
            : SequenceNumberFormatPipe.SEPARATOR;
        let returnStr = '';
        if (!!sequence && !!sequence.majorNumber) {
            returnStr = returnStr + sequence.majorNumber;
            if (!!sequence.minorNumber) {
                returnStr = returnStr + _separator + sequence.minorNumber;
            }
        }
        return returnStr;
    }
}
