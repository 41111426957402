import { Component, HostBinding, OnInit } from '@angular/core';
import { CommonUtil } from '@libs/vc-core-lib';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import NoDataOptions from 'highcharts/modules/no-data-to-display';


Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
NoDataOptions(Highcharts);

const { version: appVersion } = require('package.json');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @HostBinding('attr.version')
    version = appVersion;

    ngOnInit() {
        CommonUtil.retrieveThemeAndSwitch(false);

        this._initializeHighCharts();
    }

    private _initializeHighCharts() {
        Highcharts.setOptions({
            chart: {
                borderWidth: 2,
                borderRadius: 6,
                backgroundColor: CommonUtil.getRenderedColor('--background'),
                borderColor: CommonUtil.getRenderedColor('--outline'),
                spacingLeft: 20,
                spacingRight: 20,
                style: {
                    fontFamily: 'Poppins',
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        style: {
                            fontSize: '10px',
                            fontWeight: '400',
                            color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                        },
                    },
                },
            },
            title: {
                useHTML: true,
                style: {
                    fontSize: '16px',
                    fontWeight: '400',
                    color: CommonUtil.getRenderedColor('--text-med-emphasis'),
                    align: 'left',
                },
            },
            lang: {
                thousandsSep: ',',
            },
            legend: {
                itemStyle: {
                    fontSize: '12px',
                    fontWeight: '400',
                    height: 16,
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                },
            },
            navigation:{
                menuStyle: {
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    background: CommonUtil.getRenderedColor('--background'),
                    borderColor: CommonUtil.getRenderedColor('--outline'),
                },
                menuItemStyle: {
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    background: CommonUtil.getRenderedColor('--background'),
                },
                menuItemHoverStyle: {
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    background: CommonUtil.getRenderedColor('--primary-50'),
                },
            },
            yAxis: {
                labels: {
                    style: {
                        fontSize: '10px',
                        color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    },
                },
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: '10px',
                        color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    },
                },
            },
            noData: {
                useHTML: true,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                            'separator',
                            'downloadCSV',
                            'downloadXLS',
                            // 'viewData',
                            // 'openInCloud',
                        ],
                        y: 4,
                        symbolFill: CommonUtil.getRenderedColor('--text-high-emphasis'),
                        symbolStroke: CommonUtil.getRenderedColor('--primary-700'),
                        theme: {
                            fill: CommonUtil.getRenderedColor('--background'),
                        }
                    },
                },
            },
            credits: {
                enabled: false,
            },
        });
    }
}
