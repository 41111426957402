export abstract class ListUtil{

    public static clear(list: Array<any>):boolean{
        if(list && list.length>0){
           while(list.length > 0) {
                list.pop();
            }
            return true;
        }
        return  false;
    }

    public static isEmpty(list: Array<any>): boolean{
      if(null == list || undefined == list || list.length == 0){
        return true;
      }
      return false;
    }

    public static isNotEmpty(list: Array<any>): boolean{
      return !this.isEmpty(list);
    }
}
