import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[vcRadiobutton]',
})
export class RadiobuttonDirective<T> {
    @Input()
    value!: T;

    /** Radiobutton label */
    @Input()
    label!: string;

    /** Whether this radio button is checked */
    @Input()
    checked: boolean = false;

    /** Whether the radio button is disabled */
    @Input()
    disabled: boolean = false;

    /** Whether the label should appear after or before the radio button. Defaults to 'after' */
    @Input()
    labelPosition: 'before' | 'after' = 'after';
}
