import { IUserKeyModel } from '../user-key.model';
import { IUserPreferenceRequest } from './user-preference-request.model';

export interface IUserPreferenceModel {
    userKey: IUserKeyModel;
    category: string;
    subCategory: string;
    attributeKey: string;
    attributeValue: string;
}

export class UserPreferenceHelper {
    static getRequestFromModel(model: IUserPreferenceModel): IUserPreferenceRequest {
        return {
            category: model.category,
            subcategory: model.subCategory,
            attributeKey: model.attributeKey,
        } as IUserPreferenceRequest;
    }
}
