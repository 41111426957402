<h3 *ngIf="header" class="vc-common-form-header">{{ header }}</h3>
<form
    #formRef="ngForm"
    [attr.aria-label]="header"
    [attr.aria-labelledby]="ariaLabeledBy"
    [attr.aria-describedby]="ariaDescribedBy"
    (submit)="submit()">
    <ng-content></ng-content>
    <div
        *ngIf="showButtons"
        class="vc-form-buttons"
        [style.justify-content]="buttonsPosition"
        [class.vc-form-column-direction]="buttonsDirection === 'column'"
        [class.vc-form-row-direction]="buttonsDirection === 'row'">
        <vc-button
            *ngIf="showPrimaryButton"
            type="submit"
            mode="primary"
            [disabled]="disablePrimaryButton"
            [label]="primaryLabel"></vc-button>
        <vc-button
            *ngIf="showSecondaryButton"
            mode="basic"
            [disabled]="disableSecondaryButton"
            [label]="secondaryLabel"
            (trigger)="cancelTrigger()"></vc-button>
        <ng-content select="[buttons]"></ng-content>
    </div>
</form>
