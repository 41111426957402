import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { WhoAmI } from '../model/user/whoAmI.model';
import { CoreConstant } from '../constant/base.constant';

@Injectable({
    providedIn: 'root',
})
export class UserContextService {
    private _userContext = new BehaviorSubject<WhoAmI | null>(null);
    private _userToken = new BehaviorSubject<string | null>(null);

    public setContext(userContext: WhoAmI | null) {
        this._userContext.next(userContext);
    }

    public getContext(): Observable<WhoAmI | null> {
        return this._userContext.asObservable();
    }

    public setToken(userToken: string | null) {
        userToken && localStorage.setItem(CoreConstant.X_AUTH_TOKEN, userToken);
        this._userToken.next(userToken);
        this._userToken.complete();
    }

    public getToken(): Observable<string | null> {
        return this._userToken.asObservable();
    }

    public getTokenFromStorage(): string | null {
        const session = sessionStorage.getItem(CoreConstant.X_AUTH_TOKEN);
        return session != null && session != '' ? session : localStorage.getItem(CoreConstant.X_AUTH_TOKEN);
    }

    public clear() {
        sessionStorage.clear();
        localStorage.removeItem(CoreConstant.X_AUTH_TOKEN);
        this.setToken(null);
        this.setContext(null);
    }

    public clearSessionOnly() {
        sessionStorage.clear();
        this.setContext(null);
    }
}
