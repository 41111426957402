<vc-dialog
    [visible]="visible"
    title="{{ forceReset ? resetPasswordText : changePasswordText }}"
    primaryLabel="{{ forceReset ? resetPasswordText : changeText }}"
    secondaryLabel="Cancel"
    i18n-secondaryLabel="@@CORE.BUTTON.CANCEL"
    [loading]="loading"
    [width]="'430px'"
    [showCloseIcon]="!forceReset"
    [showSecondary]="!forceReset"
    [closeOnPrimary]="false"
    [clearFormOnClose]="true"
    (primaryTrigger)="onChangePassword($event)"
    (visibleChange)="visibleChange.emit($event)">
    <span id="vc-reset-form-description" style="display: none">
        {{ forceReset ? resetPasswordText : changePasswordText }} form
    </span>
    <vc-common-form
        #changePasswordForm
        class="vc-reset-form"
        [ariaDescribedBy]="'vc-reset-form-description'"
        [showPrimaryButton]="false"
        [showSecondaryButton]="false"
        (primaryTrigger)="onChangePassword($event)">
        <input
            *ngIf="!!username"
            class="sr-only"
            type="text"
            autocomplete="username"
            aria-required="true"
            aria-invalid="false"
            [defaultValue]="username" />
        <vc-password
            label="Current Password"
            i18n-label="@@ACCOUNT.CURRENT_PASSWORD"
            [required]="true"
            [autocomplete]="'current-password'"
            [(value)]="currentPassword"
            [showClear]="true"></vc-password>
        <vc-password
            label="New password"
            i18n-label="@@LOGIN.NEW_PASSWORD"
            i18n-description="@@LOGIN.PASSWORD_REQUIREMENTS_TEXT"
            description="Password must be 8 to 32 characters, contain at least 1 upper case, 1 lower case, 1 number and 1 special character."
            [required]="true"
            [showVisibleButton]="true"
            [autocomplete]="'new-password'"
            [(value)]="newPassword"
            [maxLength]="32"
            [minLength]="8"
            [minNumbers]="1"
            [minUpper]="1"
            [minSpecial]="1"
            [mustNotMatch]="currentPassword"
            [showClear]="true"></vc-password>
        <vc-password
            label="Repeat password"
            i18n-label="@@LOGIN.REPEAT_PASSWORD"
            [required]="true"
            [showVisibleButton]="true"
            [mustMatch]="newPassword"
            [autocomplete]="'new-password'"
            [(value)]="confirmPassword"
            [showClear]="true"></vc-password>
    </vc-common-form>
</vc-dialog>
