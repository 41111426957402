<mat-checkbox [aria-describedby]="ariaDescribedby"
              [aria-label]="ariaLabel ?? label"
              [(ngModel)]="checked"
              [id]="id"
              [required]="required"
              [disabled]="disabled"
              [labelPosition]="labelPosition"
              [(indeterminate)]="indeterminate"
              (click)="$event.stopPropagation()"
              (change)="this.checkedChange.emit(checked)">{{ label }}</mat-checkbox>
