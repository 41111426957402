import { Injectable, Injector } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { WhoAmI } from '../../model/user/whoAmI.model';
import { IUserModel } from '../../model/user/user.model';
import { IResetPasswordModel } from '../../model/user/reset-password.model';
import { BaseService } from '../../base/base.service';
import { IUserKeyModel } from '../../model/user-key.model';
import { UserTimezone } from '../../model/user/user-timezone.model';
import { IUserCriteriaDTO } from '../../model/dto/user.criteria.dto';
import { IUserSearchDTO } from '../../model/dto/user.search.dto';
import { PageResponse } from '../../model/page-response.model';
import { UserContextService } from '../../context/user.context.service';
import { IUserExportModel } from '../../model/export/user-export.model';
import { formatDate } from '@angular/common';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Language } from '../../model/user/language.enum';
import { IChangePasswordModel } from '../../model/user/change-password.model';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService<IUserModel> {
    constructor(protected injector: Injector, private _userContextService: UserContextService) {
        super(injector, 'user');
    }

    public save(model: IUserModel): Observable<IUserModel> {
        return this.httpClient.put<IUserModel>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}`,
            model
        );
    }

    public update(model: IUserModel): Observable<void> {
        return this.httpClient.post<void>(`${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}`, model);
    }

    public searchUsersPage(searchDTO: IUserSearchDTO): Observable<PageResponse<IUserModel>> {
        return this.httpClient.post<PageResponse<IUserModel>>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/pageSearch`,
            searchDTO
        );
    }

    public searchUsers(searchDTO: IUserCriteriaDTO): Observable<IUserModel[]> {
        return this.httpClient.post<IUserModel[]>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/search`,
            searchDTO
        );
    }

    public searchUsersPageForAdmin(searchDTO: IUserSearchDTO): Observable<PageResponse<IUserModel>> {
        return this.httpClient.post<PageResponse<IUserModel>>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/admin/pageSearch`,
            searchDTO
        );
    }

    public whoAmI(): Observable<WhoAmI> {
        return this.httpClient
            .get<WhoAmI>(`${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/whoami`)
            .pipe(
                map((response) => {
                    const user = new WhoAmI(
                        response.username,
                        response.authorities,
                        response.authenticator,
                        response.orgId,
                        response.usingMfa,
                        response.credentialsExpired,
                        response.lastName,
                        response.firstName,
                        response.email,
                        response.isSsoUser,
                        response.forcePasswordChange,
                        response.timeZone,
                        response.locale
                    );
                    this._userContextService.setContext(user);
                    return user;
                })
            );
    }

    public getUserDetails(): Observable<IUserModel> {
        return this.httpClient.get<IUserModel>(`${this.environment.APP.VC_USER_BASE_URL}/user-details`);
    }

    public updateUserDetails(user: IUserModel): Observable<void> {
        return this.httpClient.put<void>(`${this.environment.APP.VC_USER_BASE_URL}/user-details`, user);
    }

    public sendResetLink(username: string): Observable<void> {
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/reset/${encodeURIComponent(username)}`,
            null
        );
    }

    public resetPassword(model: IResetPasswordModel): Observable<void> {
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/resetPassword`,
            model
        );
    }

    public resetPasswordMfa(model: IResetPasswordModel): Observable<void> {
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/resetPasswordMfa`,
            model
        );
    }

    public changeMyPassword(model: IChangePasswordModel): Observable<void> {
        const params = new HttpParams().set('oldPassword', model.oldPassword).set('newPassword', model.newPassword);
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/password`,
            params
        );
    }

    public beginMfa(): Observable<string> {
        return this.httpClient.post(`${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/beginMfa`, null, {
            responseType: 'text',
        });
    }

    public disableMfa(verificationCode: string): Observable<void> {
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/disableMfa/${verificationCode}`,
            null
        );
    }

    public verifyMfa(verificationCode: string): Observable<void> {
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/verifyMfa/${verificationCode}`,
            null
        );
    }

    public getByIdWithAuthenticator(id: string, authenticator: string): Observable<IUserModel> {
        return this.httpClient.get<IUserModel>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/${encodeURIComponent(
                authenticator
            )}/${encodeURIComponent(id)}`
        );
    }

    public deleteByIdWithAuthenticator(id: string, authenticator: string): Observable<void> {
        return this.httpClient.delete<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/${encodeURIComponent(
                authenticator
            )}/${encodeURIComponent(id)}`
        );
    }

    public sendWelcome(username: string): Observable<void> {
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/welcome/${encodeURIComponent(username)}`,
            null
        );
    }

    public sendInitToken(username: string) {
        return this.httpClient.post(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/initToken/${encodeURIComponent(username)}`,
            null
        );
    }

    public startImpersonate(model: IUserModel): Observable<string> {
        return this.httpClient.post<string>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/start-impersonate`,
            model.userKey,
            {
                responseType: 'text' as 'json',
            }
        );
    }

    public updateUserStatus(userKey: IUserKeyModel): Observable<IUserModel> {
        return this.httpClient.put<IUserModel>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/admin/updateStatus`,
            userKey
        );
    }

    public exportUsers(model: IUserExportModel): Observable<void> {
        const extension = model.exportType.toLowerCase();
        const today = formatDate(new Date(Date.now()), 'yyyy-MM-dd', 'en-US');
        const fileName = `users_${today}.${extension}`;
        return this.httpClient
            .post(`${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/export`, model, {
                observe: 'response',
                responseType: 'text',
            })
            .pipe(
                take(1),
                map((res: HttpResponse<string>) => {
                    if (!res) return;

                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                        new Blob([res.body ?? ''], {
                            type: res.headers.get('content-type') ?? 'text/csv;charset=UTF-8',
                        })
                    );
                    link.download = res.headers.get('filename') ?? fileName;
                    link.click();
                    link.remove();
                    return;
                })
            );
    }

    public adminResetPassword(username: string, newPassword: string): Observable<void> {
        let params = this.getHttpParams();
        params = params.set('username', username);
        params = params.set('newPassword', newPassword);
        return this.httpClient.post<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/admin/resetPassword`,
            params
        );
    }

    public findByUsername(username: string, authenticator: string): Observable<IUserModel> {
        return this.httpClient.get<IUserModel>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/${authenticator}/${username}`
        );
    }

    public updateUserTimeZone(userTimezoneModel: UserTimezone): Observable<void> {
        return this.httpClient.put<void>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/timezone`,
            userTimezoneModel
        );
    }

    public saveUserLocale(username: string, locale: string): Observable<void> {
        const model = {
            username: username,
            authenticator: 'INTERNAL',
            updates: {
                locale: locale,
            },
        };
        return this.httpClient.patch<void>(`${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}`, model);
    }

    public getUserLocale(username: string): Observable<Language> {
        return this.httpClient.get<Language>(
            `${this.environment.APP.VC_USER_BASE_URL}/${this.serviceBaseUrl}/locale/INTERNAL/${username}`
        );
    }
}
