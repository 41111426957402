import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { HttpParamEncoder } from './httpparamencoder';
import { CoreConstant } from '../constant/base.constant';
import { UserContextService } from '../context/user.context.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
    private _userToken!: string | null;
    private _destroy$: Subject<void> = new Subject();

    constructor(private _userContextService: UserContextService) {}

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const request = this._getModifiedRequest(httpRequest);
        return next.handle(request);
    }

    private _getModifiedRequest(httpRequest: HttpRequest<any>) {
        let headers = httpRequest.headers;
        let params: HttpParams = new HttpParams({ encoder: new HttpParamEncoder() });
        let param: string | null;
        this._userToken = this._userContextService.getTokenFromStorage();
        this._userToken && (headers = headers.set(CoreConstant.X_AUTH_TOKEN, this._userToken));

        httpRequest.params.keys().forEach((key) => {
            param = httpRequest.params.get(key);
            if (param) {
                params = params.set(key, param);
            }
        });

        return httpRequest.clone({
            headers: headers,
            params: params,
        });
    }

    ngOnDestroy(): void {
        this._userToken = null;
        this._destroy$.next();
        this._destroy$.complete();
    }
}
