export class PageRequest {
    advancedFilterMap?: Map<string, string[]>;
    filterFields?: string[];
    filterValue?: string;
    filterCaseInsensitive?: boolean;
    pageNumber = 0;
    pageSize = 100;
    previous?: boolean;
    previousPageFirstItem?: any;
    previousPageLastItem?: any;
    sortCaseInsensitive?: boolean;
    sortDescending?: boolean = false;
    sortField?: string;
}
