<mat-slide-toggle
    color="primary"
    [aria-describedby]="description ?? ariaDescribedby"
    [aria-label]="ariaLabel ?? label"
    [aria-labelledby]="ariaLabelledby"
    [checked]="checked"
    [disabled]="disabled"
    [labelPosition]="labelPosition"
    [id]="id"
    (change)="checkedChange.emit($event.checked)">
    {{ label }}
    <span [hidden]="true">{{ ariaLabel ?? label }}</span>
</mat-slide-toggle>
<span class="vc-toggle-description caption-text"
      [class.disabled]="disabled"
      [class.vc-toggle-description-after]="labelPosition === 'after'">{{ description }}</span>
