<vc-button
    [mode]="menuButtonMode"
    [disabled]="disabled"
    [tooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
    [iconColor]="iconColor"
    [badgeIcon]="badgeIcon"
    [label]="label"
    [iconName]="icon"
    [triggerFor]="menu">
</vc-button>
<mat-menu #menu="matMenu" (closed)="menuClosed.emit()">
    <ng-content></ng-content>
</mat-menu>
