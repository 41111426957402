<mat-icon svgIcon="vc-logo" class="vc-header-logo"></mat-icon>

<div class="vc-header-impersonation-container body-m-1" *ngIf="isImpersonating">
    <div>
        Impersonating <span class="body-m-2">{{ isImpersonating }}</span>
    </div>
    <button class="vc-header-end-impersonate-button" mat-raised-button (click)="endImpersonation.emit()">
        End Impersonation
    </button>
</div>
<div class="vc-header-actions">
    <!--    TODO: Show when SQL Migration Testing is needed-->
    <!--    <vc-button-->
    <!--        *ngIf="showSQLTesting"-->
    <!--        mode="icon"-->
    <!--        iconName="construction"-->
    <!--        iconColor="var(&#45;&#45;secondary-800)"-->
    <!--        tooltip="Notifications"-->
    <!--        [triggerFor]="testingMenu"></vc-button>-->
    <!--    <mat-menu #testingMenu="matMenu" class="vc-header-testing">-->
    <!--        <div class="vc-header-testing"></div>-->
    <!--    </mat-menu>-->

    <vc-button
        mode="icon"
        iconName="notifications"
        iconColor="var(--secondary-800)"
        ariaLabel="Notifications Menu"
        i18n-tooltip="@@CORE.NOTIFICATIONS"
        tooltip="Notifications"
        [triggerFor]="notificationsMenu"></vc-button>
    <mat-menu #notificationsMenu="matMenu" class="vc-notifications-menu">
        <button
            mat-menu-item
            class="vc-notification-item vc-notification-header settings vc-header-menu-focus"
            (click)="navigateToNotificationSettings()">
            <span
                class="vc-notification-item-label"
                [class.headline-s]="notifications.length > 0"
                i18n="@@CORE.NOTIFICATIONS"
                >Notifications</span
            >
            <mat-icon class="vc-notification-icon" aria-hidden="true">settings</mat-icon>
        </button>
        <ng-container *ngFor="let notification of notifications | slice : 0 : 5">
            <button
                mat-menu-item
                class="vc-notification-history-item vc-header-menu-focus"
                [attr.aria-label]="notification.shortSubject + '' + notification.shortDescription"
                (click)="navigateToNotificationHistory(notification.eventKey)">
                <span class="vc-notification-history-content">
                    <mat-icon class="vc-notification-history-icon" aria-hidden="true">
                        {{ notification.eventKey | notificationIcon }}
                    </mat-icon>
                    <span class="vc-notification-history-text">
                        <span class="vc-notification-history-header">
                            <span class="vc-notification-history-subject body-m-2">
                                {{ notification.shortSubject }}</span
                            >
                            <span class="vc-notification-history-date body-s">
                                {{ notification.createdInstant | date : 'MM/dd/y, h:mm a' }}
                            </span>
                        </span>
                        <span class="vc-notification-history-description body-m-1">
                            {{ notification.shortDescription }}
                        </span>
                    </span>
                </span>
            </button>
        </ng-container>
        <button
            mat-menu-item
            *ngIf="notifications.length > 0"
            class="vc-notification-history-show-all label-s vc-header-menu-focus"
            (click)="navigateToNotificationHistory()">
            <span class="vc-notification-history-label label-s" i18n="@@CORE.NOTIFICATION_HISTORY.SHOW_ALL_NOTIFICATIONS"
                >Show All Notifications</span
            >
        </button>
    </mat-menu>
    <button
        class="vc-header-lang-button vc-header-menu-focus"
        aria-label="Language Menu"
        i18n-matTooltip="@@CORE.LANGUAGE"
        matTooltip="Change language"
        [matMenuTriggerFor]="langMenu">
        <img class="vc-header-flag-img" alt="Country flag image" [src]="lang | countryImage" />
    </button>
    <mat-menu #langMenu="matMenu">
        <button
            *ngFor="let value of langOptions"
            mat-menu-item
            class="vc-lang-menu-item vc-header-menu-focus"
            [class.highlight]="lang === value"
            (click)="changeLanguage(value)">
            <img class="vc-lang-menu-img" alt="Country flag image" [src]="value | countryImage" />
            <span>{{ value | countryLabel }}</span>
        </button>
    </mat-menu>
    <vc-theme-toggle tooltip [mode]="themeMode" (modeChanged)="changeThemeMode($event)"></vc-theme-toggle>
    <vc-button
        mode="icon"
        iconName="account_circle"
        iconColor="var(--secondary-800)"
        ariaLabel="Account Menu"
        i18n-tooltip="@@CORE.USER.ACCOUNT"
        tooltip="Account"
        [triggerFor]="userMenu"></vc-button>
    <mat-menu #userMenu="matMenu">
        <span class="vc-user-menu-username title-s" (click)="$event.stopPropagation()">
            {{ userName }}
        </span>
        <button
            *ngIf="hasChildren || isOrgAdmin"
            mat-menu-item
            class="vc-user-menu-item body-m-1 org-change vc-header-menu-focus"
            [attr.aria-label]="selectedOrgName + ' - Change organization'"
            [disableRipple]="true"
            (click)="openOrgDialog()">
            <div class="vc-header-account-menu">
                <span class="vc-user-menu-item-title org-title" (click)="$event.stopPropagation()">
                    {{ selectedOrgName }}
                </span>
                <div *ngIf="billingIds" class="vc-org-billing-ids space-bottom-4" (click)="$event.stopPropagation()">
                    <div i18n="@@CORE.ORGANIZATION.BILLING_ID">Billing ID:</div>
                    <vc-tooltip-text [text]="billingIds" class="space-left-4 space-right-16"></vc-tooltip-text>
                </div>
                <span class="vc-user-menu-item-title change-org" i18n="@@CORE.USER.CHANGE_ORGANIZATION">
                    Change organization
                </span>
            </div>
        </button>
        <hr class="vc-user-menu-divider" />
        <button
            *ngIf="isOrgAdmin"
            mat-menu-item
            class="vc-user-menu-item label-l vc-header-menu-focus"
            (click)="navigateToOrganization()">
            <span class="vc-user-menu-item-title label-l" i18n="@@CORE.ORGANIZATION">Organization</span>
            <mat-icon class="vc-user-menu-item-icon" aria-hidden="true">domain</mat-icon>
        </button>
        <button mat-menu-item  class="vc-user-menu-item label-l vc-header-menu-focus" (click)="navigateToAccount()">
            <span class="vc-user-menu-item-title label-l" i18n="@@CORE.USER.ACCOUNT">Account</span>
            <mat-icon class="vc-user-menu-item-icon" aria-hidden="true">tune</mat-icon>
        </button>
        <button mat-menu-item class="vc-user-menu-item label-l vc-header-menu-focus" (click)="logout.emit()">
            <span class="vc-user-menu-item-title label-l" i18n="@@CORE.USER.LOGOUT">Log out</span>
            <mat-icon class="vc-user-menu-item-icon" aria-hidden="true">input</mat-icon>
        </button>
    </mat-menu>

    <vc-org-picker
        [(visible)]="orgDialogVisible"
        [user]="user"
        [orgModels]="orgModels"
        (selectedOrgChange)="changeOrg.emit($event.id)">
    </vc-org-picker>
</div>
