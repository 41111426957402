<label class="vc-theme-toggle">
    <input type="checkbox"
           [attr.aria-labelledby]="labelId"
           [attr.aria-checked]="checked"
           [(ngModel)]="checked"
           (ngModelChange)="modeChanged.emit(checked ? 'dark' : 'light')">
    <vc-icon *ngIf="checked"
             aria-hidden="true"
             color="var(--secondary-800)"
             name="light_mode"
             i18n-tooltip="@@CORE.THEME.LIGHT"
             tooltip="Light theme"></vc-icon>
    <vc-icon *ngIf="!checked"
             aria-hidden="true"
             color="var(--secondary-800)"
             name="dark_mode"
             i18n-tooltip="@@CORE.THEME.DARK"
             tooltip="Dark theme"></vc-icon>
    <span [hidden]="true" [id]="labelId">Change theme to {{ checked ? 'dark' : 'light' }}</span>
</label>
