<vc-dialog
    [(visible)]="visible"
    [loading]="loading"
    title="Add bulk Zip"
    i18n-title="@@FILE_BROWSER.LABEL.ADD_BULK_ZIP"
    [showActionsOnLeft]="true"
    [showActionsOnRight]="false"
    [closeOnSecondary]="true"
    [clearFormOnClose]="true"
    (primaryTrigger)="save()">
    <div class="body-m-2 space-bottom-16">{{ location }}</div>
    <vc-file-uploader
        #fileUploaderComponent
        (selectionChange)="uploadFiles($event)"
        [acceptedTypes]="['.zip']"></vc-file-uploader>
</vc-dialog>
