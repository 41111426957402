import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileBrowserRoutingModule } from './file-browser-routing.module';
import { FileBrowserSearchComponent } from './file-browser-search/file-browser-search.component';
import { VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import { TagsSelectorModule } from '@libs/vc-shared-ui-lib';
import { VcCoreLibModule } from '@libs/vc-core-lib';
import { FileBrowserFormDialogComponent } from './file-browser-form-dialog/file-browser-form-dialog.component';
import { FileBrowserFolderDialogComponent } from './file-browser-folder-dialog/file-browser-folder-dialog.component';
import { FileBrowserUploadZipDialogComponent } from './file-browser-upload-zip-dialog/file-browser-upload-zip-dialog.component';

@NgModule({
    declarations: [
        FileBrowserSearchComponent,
        FileBrowserFormDialogComponent,
        FileBrowserFolderDialogComponent,
        FileBrowserUploadZipDialogComponent,
    ],
    imports: [CommonModule, FileBrowserRoutingModule, VcCommonUiLibModule, VcCoreLibModule, TagsSelectorModule],
})
export class FileBrowserModule {}
