import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, DummyRouteComponent } from '@libs/vc-core-lib';
import { LandingComponent } from './landing/landing.component';
import { PostLoginLayoutComponent } from './layout/post-login.layout.component';
import { SecureEdgePublicWebsiteComponent } from './component/secure-edge-public-website/secure-edge-public-website.component';

const routes: Routes = [
    {
        path: '',
        component: PostLoginLayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: LandingComponent },
            {
                path: 'account',
                loadChildren: () =>
                    import('./component/account-settings/account-settings.module').then((m) => m.AccountSettingsModule),
            },
            { path: 'dummy/route', component: DummyRouteComponent },
            {
                path: 'finding',
                loadChildren: () => import('./findings/findings.module').then((m) => m.FindingsModule),
            },
            { path: 'event', loadChildren: () => import('./event/event.module').then((m) => m.EventModule) },
            { path: 'asset', loadChildren: () => import('./asset/asset.module').then((m) => m.AssetModule) },
            {
                path: 'file-browser',
                loadChildren: () =>
                    import('./file-browser/file-browser-routing.module').then((m) => m.FileBrowserRoutingModule),
            },
            {
                path: 'notifications-settings',
                loadChildren: () =>
                    import('./component/notifications-settings/notifications-settings.module').then(
                        (m) => m.NotificationsSettingsModule
                    ),
            },
            {
                path: 'notification-history',
                loadChildren: () =>
                    import('./component/notification-history/notification-history.module').then(
                        (m) => m.NotificationHistoryModule
                    ),
            },
            {
                path: 'assessment',
                loadChildren: () =>
                    import('../../../vc-assessment-ui/src/app/app.module').then((m) => m.AssessmentsModule),
            },
            {
                path: 'security-operations',
                loadChildren: () =>
                    import('../../../vc-security-operation-ui/src/app/app.module').then((m) => m.AppModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('../../../vc-admin-ui/src/app/app.module').then((m) => m.AdminModule),
            },
            {
                path: 'scan',
                loadChildren: () => import('../../../vc-scan-ui/src/app/app.module').then((m) => m.ScanModule),
            },
            {
                path: 'mss',
                loadChildren: () => import('../../../vc-mss-ui/src/app/app.module').then((m) => m.AppModule),
            },
            {
                path: 'endpoints',
                loadChildren: () => import('../../../vc-endpoint-ui/src/app/app.module').then((m) => m.EndpointModule),
            },
            {
                path: 'marketplace',
                loadChildren: () =>
                    import('../../../vc-marketplace-ui/src/app/app.module').then((m) => m.MarketplaceModule),
            },
        ],
    },
    { path: 'secureedge', component: SecureEdgePublicWebsiteComponent },
    { path: '', loadChildren: () => import('./component/login/login.module').then((m) => m.LoginModule) },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
