<div
    class="vc-badge {{ status }}"
    [matTooltip]="tooltip ?? label"
    [matTooltipDisabled]="!showTooltip && !textTruncated"
    [matTooltipPosition]="tooltipPosition">
    @if (prefixIcon) {
        <mat-icon *ngIf="prefixIcon" class="vc-badge-prefix-icon">{{ prefixIcon }}</mat-icon>
    } @if (label && label !== '') {
        <span #textElement class="vc-badge-label">{{ label }}</span>
    } @if (suffixIcon) {
        <mat-icon *ngIf="suffixIcon" class="vc-badge-suffix-icon">{{ suffixIcon }}</mat-icon>
    }
</div>
