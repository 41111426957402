import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    private _add = new Subject<Breadcrumb[]>();
    private _currentUrl: string = '';
    private _previousUrl!: string;
    private _history: string[] = [];

    constructor(private _router: Router, private location: Location) {
        this._router.events.subscribe((event: any) => {
            // Clear breadcrumbs after navigate to new page
            if (event instanceof NavigationStart && event?.url != this._currentUrl) {
                this._previousUrl = this._currentUrl;
                this._currentUrl = event.url;
                this.add([]);
            }

            if (event instanceof NavigationEnd) {
                this._history.push(event.urlAfterRedirects);
            }
        });
    }

    getBreadcrumbs(): Observable<Breadcrumb[]> {
        return this._add.asObservable();
    }

    getPreviousUrl(): string | null {
        const url = this._previousUrl?.split('?')[0];
        return url ?? null;
    }

    add(breadcrumbs: Breadcrumb[]) {
        this._add.next(breadcrumbs);
    }

    async back() {
        this._history.pop();
        if (this._history.length > 0) {
            this.location.back();
        } else {
            await this._router.navigateByUrl('/');
        }
    }
}
