import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';

/** Example of input usage
 * HTML
 *    <vc-input
 *         label="My Input"
 *         [validationFunction]="validateInput"
 *         [(value)]="inputValue"></vc-input>
 *
 *  TS
 *      inputValue = '';
 *      validateInput = (value: string | null) => {
 *         return (value?.length ?? 0) > 0 && value != 'test' ? "Value is not equal 'test'" : '';
 *     };
 * */

@Component({
    selector: 'vc-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent extends BaseFieldComponent<string> implements AfterViewInit {
    private requiredMinCharsText = $localize`:@@COMMON_UI.TEXTAREA.REQUIRED_MINIMUM_CHARACTERS:Required minimum characters:`;
    private requiredMaxCharsText = $localize`:@@COMMON_UI.TEXTAREA.REQUIRED_MAX_CHARACTERS:Required maximum characters:`;

    @Input()
    set value(val: string) {
        if (val != this._value) {
            this._value = val;
            this.valueChange.emit(this._value);
            this.validate();
        }
    }

    get value(): string {
        return this._value;
    }

    private _value: string = '';

    /** Description text underneath  */
    @Input()
    description!: string;

    /** Whether to show clear icon. */
    @Input()
    showClear: boolean = false;

    /** Whether to validate field on value changes. */
    @Input()
    validateOnValueChange: boolean = false;

    /** Prefix icon */
    @Input()
    prefixIcon!: string;

    /** Suffix icon */
    @Input()
    suffixIcon!: string;

    /** Suffix icon color */
    @Input()
    suffixIconColor!: string;

    /** Prefix icon color */
    @Input()
    prefixIconColor!: string;

    /** Suffix button icon */
    @Input()
    suffixButtonIcon!: string;

    /** Suffix button aria label */
    @Input()
    suffixButtonAriaLabel!: string;

    /** Input placeholder value */
    @Input()
    placeholder!: string;

    /** Input type of the element. */
    @Input()
    type!: string;

    /** Minimum allowed characters in input */
    @Input()
    minLength!: number;

    /** Maximum allowed characters in input. */
    @Input()
    maxLength!: number;

    /** Identifies the element (or elements) that describes the input. */
    @Input()
    ariaDescribedBy!: string;

    /** Specifies tooltip text for tooltip upon hovering */
    @Input()
    tooltip!: string;

    /** Value for autocomplete attribute on input */
    @Input()
    autocomplete!: string;

    /** Value for autofocus on input on first load */
    @Input()
    autoFocus!: boolean;

    /** Whether to show error message. */
    @Input()
    showErrorMessage: boolean = true;

    /** Two ways data binding for input value */
    @Output()
    valueChange = new EventEmitter<string>();

    /** Event is fired when a key is released */
    @Output()
    keyUp = new EventEmitter<string>();

    /** Event is fired when a key is pressed */
    @Output()
    keyDown = new EventEmitter<string>();

    /** Event is fired when a key Enter is pressed */
    @Output()
    keyupEnter = new EventEmitter<string>();

    /** Event is fired when suffix button triggered */
    @Output()
    suffixButtonTrigger = new EventEmitter();

    /** Event is fired when input gets focus */
    @Output()
    focusChange = new EventEmitter();

    /** Event is fired when input loses focus */
    @Output()
    blurChange = new EventEmitter();

    @ViewChild('inputElement')
    inputElement!: ElementRef;

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    ngAfterViewInit(): void {
        if (this.autoFocus) {
            this.focus();
        }
    }

    onBlur() {
        this.touched = true;
        this.validate();
        this.blurChange.emit();
    }

    onKeyDown() {
        if (this.validateOnValueChange) {
            this.touched = true;
        }
        this.keyDown.emit(this.value);
    }

    onKeyUp() {
        if (this.validateOnValueChange) {
            this.touched = true;
        }
        this.keyUp.emit(this.value);
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        const isNotEmpty = this.value != null && this._value?.trim() !== '';
        if (this.required && (this._value == null || this._value.trim() == '')) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this._value);
            this.valid = this.errorMessage == '';
        }

        if (isNotEmpty && this.minLength && this.valid) {
            this.valid = (this?.value ?? '')?.length >= this.minLength;
            this.errorMessage = `${this.requiredMinCharsText} ${this.minLength}`;
        }

        if (isNotEmpty && this.maxLength && this.valid) {
            this.valid = (this?.value ?? '')?.length <= this.maxLength;
            this.errorMessage = `${this.requiredMaxCharsText} ${this.maxLength}`;
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.value = '');
        this.touched = false;
        this.valid = true;
        this.errorMessage = '';
    }

    focus() {
        this.inputElement?.nativeElement?.focus();
    }
}
