import { ServiceUrls } from '@libs/vc-core-lib';

export const environment = {
    production: true,
    apiUrl: "",
    APP: ServiceUrls,
    adyen: {
        clientKey: 'test_QOQMUQA275AV3B57S2TXCMFLSYDCI653',
        env: 'test'
    }
};
