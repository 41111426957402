import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { TimeFormat, TimePeriod, TimeModel } from '../../interfaces/time-picker';

/** Example of time picker usage
 * HTML
 * <vc-time-picker
 * [timeFormat]="timeFormat"
 * [options]="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
 * (valueChange)="handleTimeChange($event)">
 * </vc-time-picker>
 *
 * TS
 * public timeSelected: string[] = [];
 * public timeFormat = TimeFormat.HOURS_12 or TimeFormat.HOURS_24
 * handleTimeChange(timeModel: TimeModel) {
 *     this.timeSelected = timeModel;
 * }
 * */

@Component({
    selector: 'vc-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
    hoursValue!: number | null;
    minutesValue!: number | null;

    hours: number[] = Array.from({ length: 24 }, (_, index) => index);
    minuteOptions: number[] = Array.from({ length: 60 }, (_, index) => index);
    pmOrAm!: TimePeriod;

    @Input()
    set value(val: TimeModel) {
        if (val !== this._value) {
            this._value = val;
            this.hoursValue = this._value.hour;
            this.minutesValue = this._value.minute;
            this.valueChange.emit(this._value);
        }
    }
    get value(): TimeModel {
        return this._value;
    }
    private _value!: TimeModel;

    /** Whether the field is required. */
    @Input()
    required = false;

    /** Whether the field is disabled. */
    @Input()
    disabled = false;

    /** Whether the field is readonly. */
    @Input()
    readonly = false;

    /** Specifies time format */
    @Input()
    timeFormat: TimeFormat = TimeFormat.HOURS_24;

    /** Whether to apply minimal styles(no border, background, underline etc)  */
    @Input()
    minimalStyles: boolean = false;

    @Output()
    valueChange = new EventEmitter<TimeModel>();

    ngOnInit(): void {
        this.verifyRequired();
    }

    emitSelectionChange() {
        const timePicker: TimeModel = {
            hour: this.hoursValue,
            minute: this.minutesValue,
            format: this.timeFormat,
            period: this.pmOrAm,
        };
        this.valueChange.emit(timePicker);
    }

    optionRenderer = (value: number): string => {
        if (this.timeFormat === TimeFormat.HOURS_12) {
            this.pmOrAm = value >= 12 ? TimePeriod.PM : TimePeriod.AM;
            const hours = value % 12 || 12;
            return `${hours}${this.pmOrAm === TimePeriod.AM ? ' AM' : ' PM'}`;
        }
        return value < 10 ? `0${value}` : `${value}`;
    };

    minuteRenderer = (value: number): string => {
        if (value < 10) {
            return `0${value}`;
        }
        return `${value}`;
    };

    verifyRequired(): void {
        if (this.required) {
            if (!this.hoursValue) {
                this.hoursValue = this.hours[0];
            }
            if (!this.minutesValue) {
                this.minutesValue = this.minuteOptions[0];
            }
        }
    }
}
