import { Injectable, Injector } from '@angular/core';
import { BaseService, IExporterPayloadDTO, IFacetedPageDTO, Page, PageUtil, ScanType } from '@libs/vc-core-lib';
import { IFindingCriteriaDTO } from '../dto/finding.criteria.dto';
import { IFindingModel, issuesCountStoricData } from '../model/finding.model';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { IssuesCountParamDTO } from '../dto/issues-count.dto';
import { IFindingHistogramDTO, IFindingHistogramData, IFindingHistogramParam } from '../dto/finding.histogram.dto';
import { IFindingSearchDTO } from '../dto/finding.search.dto';
import { IFindingDashboardResultsModel } from '../model/finding-dashboard-results.model';

@Injectable()
export class FindingService extends BaseService<IFindingModel> {
    private _previousFindingsDTO!: IFindingSearchDTO;

    constructor(protected injector: Injector) {
        super(injector, 'finding');
    }

    public getFindingById(id: string): Observable<IFindingModel> {
        return this.httpClient.get<IFindingModel>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/${id}`
        );
    }

    public searchFindings(searchDTO: IFindingSearchDTO): Observable<IFindingModel[]> {
        return this.httpClient.post<IFindingModel[]>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/search`,
            searchDTO
        );
    }

    public searchFindingsWithPreviousDTO(searchDTO: IFindingSearchDTO): Observable<IFindingModel[]> {
        return this.searchFindings(this._previousFindingsDTO || searchDTO);
    }

    public searchPagedFindingsWithPreviousDTO(searchDTO: IFindingSearchDTO): Observable<Page<IFindingModel>> {
        return this.searchPagedFindings(this._previousFindingsDTO || searchDTO);
    }

    public searchPagedFindings(searchDTO: IFindingSearchDTO): Observable<Page<IFindingModel>> {
        this._previousFindingsDTO = searchDTO;
        return this.httpClient
            .post<IFacetedPageDTO<IFindingModel>>(
                `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/pageSearch`,
                searchDTO
            )
            .pipe(
                map((response: IFacetedPageDTO<IFindingModel>) => {
                    return PageUtil.convertToPage(response.page);
                })
            );
    }

    public searchFindingsWithoutPaging(findingCriteriaDTO: IFindingCriteriaDTO): Observable<IFindingModel[]> {
        return this.httpClient.post<IFindingModel[]>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/search`,
            findingCriteriaDTO
        );
    }

    public countFindings(criteria: IFindingCriteriaDTO): Observable<number> {
        return this.httpClient.post<number>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/count`,
            criteria
        );
    }

    public getFindingSeverityAggByCriteria(findingCriteriaDTO: IFindingCriteriaDTO): Observable<IFindingModel[]> {
        return this.httpClient.post<IFindingModel[]>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/severity-aggregation`,
            findingCriteriaDTO
        );
    }

    public getIssuesCount(criteriaDTO: any, params: IssuesCountParamDTO): Observable<issuesCountStoricData> {
        const httpParams = new HttpParams({ fromObject: params as any });

        return this.httpClient.post<issuesCountStoricData>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/issues-count`,
            criteriaDTO,
            { params: httpParams }
        );
    }

    public getFindingHistogram(
        findingHistogramDTO: IFindingHistogramDTO,
        params: IFindingHistogramParam
    ): Observable<IFindingHistogramData> {
        const httpParams = new HttpParams({ fromObject: params as any });

        return this.httpClient.post<IFindingHistogramData>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/findingHistogram`,
            findingHistogramDTO,
            { params: httpParams }
        );
    }

    public findingFacetedSearch(searchDTO: IFindingSearchDTO): Observable<IFacetedPageDTO<IFindingModel>> {
        return this.httpClient.post<IFacetedPageDTO<IFindingModel>>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/pageSearch`,
            searchDTO
        );
    }

    public deleteFinding(findingId: string): Observable<void> {
        return this.httpClient.delete<void>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/${findingId}`
        );
    }

    public getTemplates(): Observable<Array<IFindingModel>> {
        return this.httpClient.get<Array<IFindingModel>>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/templates`
        );
    }

    public exportFindings(payload: IExporterPayloadDTO<IFindingSearchDTO>): Observable<Blob> {
        const url: string = `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/export`;
        return this.httpClient.post<Blob>(url, payload, { observe: 'body', responseType: 'blob' as 'json' });
    }

    public exportFindingsWithScanType(
        payload: IExporterPayloadDTO<IFindingSearchDTO>,
        scanType: ScanType
    ): Observable<Blob> {
        const url: string = `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/export/${scanType}`;
        return this.httpClient.post<Blob>(url, payload, { observe: 'body', responseType: 'blob' as 'json' });
    }

    public upsertFinding(finding: IFindingModel): Observable<string> {
        return this.httpClient.post(`${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}`, finding, {
            responseType: 'text',
        });
    }

    public getDashboardResults(
        from: string,
        to: string,
        dateInterval: string
    ): Observable<IFindingDashboardResultsModel> {
        return this.httpClient.get<IFindingDashboardResultsModel>(
            `${this.environment.APP.VC_FINDING_BASE_URL}/${this.serviceBaseUrl}/dashboardItems`,
            { params: new HttpParams().set('from', from).set('to', to).set('timeInterval', dateInterval) }
        );
    }
}
