import { Component, ElementRef, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';

/** Example of date picker usage
 * HTML
 *   <vc-common-textarea label="Textarea" [required]="true" [(value)]="textareaValue"></vc-common-textarea>
 *
 *  TS
 *   textareaValue = '';
 * */

@Component({
    selector: 'vc-common-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends BaseFieldComponent<string> {
    private requiredMinCharsText = $localize`:@@COMMON_UI.TEXTAREA.REQUIRED_MINIMUM_CHARACTERS:Required minimum characters:`;
    private requiredMaxCharsText = $localize`:@@COMMON_UI.MAX_CHARACTER_LIMIT:Maximum character limit reached`;

    @Input()
    set value(val: string) {
        if (val != this._value) {
            this._value = val;
            this.valueChange.emit(this._value);
            this.validate();
        }
    }

    get value(): string {
        return this._value;
    }

    private _value: string = '';

    /** Textarea placeholder value */
    @Input()
    placeholder!: string;

    /** Minimum allowed characters in textarea */
    @Input()
    minLength!: number;

    /** Maximum allowed characters in textarea. */
    @Input()
    maxLength!: number;

    /** Identifies the element (or elements) that describes the textarea. */
    @Input()
    ariaDescribedBy!: string;

    /** Specifies the height of the text area */
    @Input()
    rows: string = '3';

    /** Whether textarea can be resizable */
    @Input()
    resizable: boolean = false;

    /** Whether to show max count description */
    @Input()
    showMax: boolean = false;

    /** Whether to show error message on value changes. */
    @Input()
    validateOnValueChange: boolean = false;

    /** Suffix icon. */
    @Input()
    suffixIcon!: string;

    /** Two ways data binding for textarea value */
    @Output()
    valueChange = new EventEmitter<string>();

    /** Event is fired when a key is released */
    @Output()
    keyUp = new EventEmitter<string>();

    /** Event is fired when a key is pressed */
    @Output()
    keyDown = new EventEmitter<string>();

    /** Event is fired when textarea gets focus */
    @Output()
    focusChange = new EventEmitter();

    /** Event is fired when textarea loses focus */
    @Output()
    blurChange = new EventEmitter();

    @ViewChild('textareaElement')
    textareaElement!: ElementRef;

    get maxCountLabel(): string {
        return `${(this.value ?? '')?.length}/${this.maxLength}`;
    }

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    onBlur() {
        this.touched = true;
        this.validate();
        this.blurChange.emit();
    }

    onKeyDown() {
        if (this.validateOnValueChange) {
            this.touched = true;
        }
        this.keyDown.emit(this.value);
    }

    onKeyUp() {
        if (this.validateOnValueChange) {
            this.touched = true;
        }
        this.keyUp.emit(this.value);
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        const isNotEmpty = this.value != null && this._value?.trim() !== '';
        if (this.required && (this._value == null || this._value.trim() == '')) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this._value);
            this.valid = this.errorMessage == '';
        }

        if (isNotEmpty && this.minLength && this.valid) {
            this.valid = (this?.value ?? '')?.length >= this.minLength;
            this.errorMessage = `${this.requiredMinCharsText} ${this.minLength}`;
        }

        if (isNotEmpty && this.maxLength && this.valid) {
            this.valid = (this?.value ?? '')?.length <= this.maxLength;
            this.errorMessage = `${this.requiredMaxCharsText}`;
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.value = '');
        this.touched = false;
        this.valid = true;
        this.errorMessage = '';
    }

    focus() {
        this.textareaElement?.nativeElement?.focus();
    }
}
