import { AssessmentStatus } from '../model/assessment-status.enum';
import { Priority } from '../model/priority.enum';

export enum FileOrFolder {
    FILE = 'FILE',
    FOLDER = 'FOLDER',
}

export enum FileRegion {
    GENERIC = 'GENERIC',
    ASSESSMENT = 'ASSESSMENT',
    FINDING = 'FINDING',
}

export enum AssetType {
    NETWORK = 'NETWORK',
    HOST = 'HOST',
    APPLICATION = 'APPLICATION',
    USER = 'USER',
    ENDPOINT = 'ENDPOINT',
}

export enum CommentVisibility {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

export enum FrameworkStatus {
    UNPUBLISHED = 'UNPUBLISHED',
    PUBLISHED = 'PUBLISHED',
    INACTIVE = 'INACTIVE',
}

export enum WorkflowType {
    DISPUTE = 'DISPUTE',
    SOC = 'SOC',
}

export enum WorkflowStatus {
    CREATED = 'CREATED',
    ASSIGNED = 'ASSIGNED',
    REVIEWED = 'REVIEWED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    REDISPUTED = 'REDISPUTED',
    RESOLVED = 'RESOLVED',
}

export enum ScanType {
    PCI = 'PCI',
    EVS = 'EVS',
    IVS = 'IVS',
}

export enum ScanPciStatus {
    PASS = 'PASS',
    INCOMPLETE = 'INCOMPLETE',
    FAIL = 'FAIL',
}

export enum Frequency {
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
}

export enum SortTypes {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum TransitionPriority {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    REVERT = 'REVERT',
    NONE = 'NONE',
}

export enum Operation {
    ADD = 'add',
    REMOVE = 'remove',
    REPLACE = 'replace',
    COPY = 'copy',
    MOVE = 'move',
    TEST = 'test',
}

export abstract class EnumUtil {
    public static SEVERITIES: Array<string> = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO', 'UNKNOWN'];
    public static CONFIDENCES: Array<string> = ['TENTATIVE', 'FIRM', 'CERTAIN'];
    public static ASSET_TYPES: Array<string> = [
        AssetType.NETWORK,
        AssetType.HOST,
        AssetType.APPLICATION,
        AssetType.USER,
        AssetType.ENDPOINT,
    ];
    public static PCI_SEVERITIES: Array<string> = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL', 'URGENT'];
    public static RISKS: Array<string> = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
    public static PRIORITIES: Array<string> = [Priority.LOW, Priority.MEDIUM, Priority.HIGH];
    public static FILTER_DURATION: Array<string> = ['PT15M', 'PT30M', 'PT1H', 'PT2H', 'PT4H', 'PT8H', 'PT12H', 'PT24H'];
    public static DISPUTE_STATUSES: Array<string> = ['CREATED', 'ASSIGNED', 'REVIEWED', 'APPROVED', 'REJECTED'];
    public static TASK_STATUSES: Array<{ id: string; name: string }> = [
        { id: 'PENDING', name: 'Pending' },
        { id: 'IN_PROGRESS', name: 'In Progress' },
        { id: 'SUBMITTED', name: 'Submitted' },
        { id: 'IN_REVIEW', name: 'In Review' },
        { id: 'REJECTED', name: 'Rejected' },
        { id: 'COMPLETE', name: 'Completed' },
    ];
    public static TASK_STATUS: Map<string, string> = new Map<string, string>()
        .set('PENDING', 'Pending')
        .set('IN_PROGRESS', 'In Progress')
        .set('SUBMITTED', 'Submitted')
        .set('IN_REVIEW', 'In Review')
        .set('REJECTED', 'Rejected')
        .set('COMPLETE', 'Completed');
    public static ASSESSMENT_STATUSES: Array<{ id: string; name: string }> = [
        { id: AssessmentStatus.NOT_STARTED, name: 'Not Started' },
        { id: AssessmentStatus.IN_PROGRESS, name: 'In Progress' },
        { id: AssessmentStatus.PAUSED, name: 'Paused' },
        { id: AssessmentStatus.DELAYED, name: 'Delayed' },
        { id: AssessmentStatus.SUBMITTED_TO_QA, name: 'Submitted to Qa' },
        { id: AssessmentStatus.IN_QA, name: 'In Qa' },
        { id: AssessmentStatus.RETURN_TO_ASSESSOR, name: 'Return to Assessor' },
        { id: AssessmentStatus.READY_FOR_DELIVERY, name: 'Ready for Delivery' },
        { id: AssessmentStatus.CANCELED, name: 'Canceled' },
        { id: AssessmentStatus.COMPLETED, name: 'Completed' },
    ];
    public static ASSESSMENT_STATUS: Map<string, string> = new Map<string, string>()
        .set(AssessmentStatus.NOT_STARTED, 'Not Started')
        .set(AssessmentStatus.IN_PROGRESS, 'In Progress')
        .set(AssessmentStatus.PAUSED, 'Paused')
        .set(AssessmentStatus.DELAYED, 'Delayed')
        .set(AssessmentStatus.SUBMITTED_TO_QA, 'Submitted to Qa')
        .set(AssessmentStatus.IN_QA, 'In Qa')
        .set(AssessmentStatus.RETURN_TO_ASSESSOR, 'Return to Assessor')
        .set(AssessmentStatus.READY_FOR_DELIVERY, 'Ready for Delivery')
        .set(AssessmentStatus.CANCELED, 'Canceled')
        .set(AssessmentStatus.COMPLETED, 'Completed');

    public static SOURCE_TYPES: Array<string> = ['UTM', 'LOG', 'ENDPOINT'];
    public static SOURCES: Array<any> = [
        { type: 'UTM', name: 'utm_security_event' },
        { type: 'UTM', name: 'utm_meraki_alert' },
        { type: 'LOG', name: 'syslog_fortinet' },
        { type: 'LOG', name: 'syslog_meraki_firewall' },
        { type: 'ENDPOINT', name: 'on-access-scan' },
        { type: 'ENDPOINT', name: 'hostinfo' },
    ];
}
