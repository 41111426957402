<ul class="vc-common-breadcrumb-list">
    <li *ngFor="let item of breadcrumbs" class="vc-common-breadcrumb-item">
        <a
            *ngIf="item.url || item.goBack; else contentTemplate"
            href="javascript:void(0)"
            tabindex="0"
            (click)="navigateTo(item)">
            <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        </a>
        <ng-template #contentTemplate>
            <span class="content">
                <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
            </span>
        </ng-template>
        <ng-template #labelTemplate>
            <mat-icon
                class="vc-common-breadcrumb-icon"
                *ngIf="item.iconName"
                aria-hidden="false"
                [attr.aria-label]="item.label ? item.label : item.ariaLabel">
                {{ item.iconName }}
            </mat-icon>
            <span class="body-s">{{ item.label }}</span>
        </ng-template>
    </li>
</ul>
