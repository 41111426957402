import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RegexUtil } from '@libs/vc-core-lib';
import { NotificationService } from '@libs/vc-common-ui-lib';
import { FileLockerService, IFileModel } from '@libs/vc-file-locker-lib';
import { IFolderTreeModel } from '../../common/models/folder-tree.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'vc-file-browser-folder-dialog',
    templateUrl: './file-browser-folder-dialog.component.html',
    styleUrls: ['./file-browser-folder-dialog.component.scss'],
    providers: [FileLockerService],
})
export class FileBrowserFolderDialogComponent implements OnDestroy {
    @Input() public folder!: IFolderTreeModel;
    @Input() public orgId!: string;

    @Input()
    set visible(value: boolean) {
        if (this._visible != value) {
            this._visible = value;
            this.visibleChange.emit(this._visible);
        }
        this.location = $localize`:@@FILE_BROWSER.LABEL.LOCATION:Location: ` + this.folder?.path;
    }
    get visible(): boolean {
        return this._visible;
    }

    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() public onSaveSuccess = new EventEmitter();

    private _visible: boolean = false;
    loading: boolean = false;
    folderName!: string;
    location!: string;
    private _destroy$: Subject<any> = new Subject<any>();

    constructor(private _fileLockerService: FileLockerService, private _notificationService: NotificationService) {}
    validateFolderName = (folderName: string): string => {
        return RegexUtil.isNotValidFolderName(folderName)
            ? $localize`:@@FILE_BROWSER.ADD_FOLDER.VALIDATION_MSG:Please enter valid folder name`
            : '';
    };

    public save(): void {
        this.loading = true;
        const path =
            this.folder?.path === '/' ? this.folder?.path + this.folderName : this.folder?.path + '/' + this.folderName;
        this._fileLockerService
            .createFileDirectory(path, this.orgId, null)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.loading = false))
            )
            .subscribe({
                next: (file: IFileModel) => {
                    this.onSaveSuccess.emit(file);
                    this.visible = false;
                },
                error: (error: HttpErrorResponse) => {
                    this._notificationService.error('', error.error);
                },
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next(null);
        this._destroy$.complete();
    }
}
