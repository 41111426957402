import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { FileUploaderComponent, NotificationService } from '@libs/vc-common-ui-lib';
import { FileLockerService, IFileArchiveModel, IFileModel } from '@libs/vc-file-locker-lib';
import { IFolderTreeModel } from '../../common/models/folder-tree.model';

@Component({
    selector: 'vc-file-browser-upload-zip-dialog',
    templateUrl: './file-browser-upload-zip-dialog.component.html',
    styleUrls: ['./file-browser-upload-zip-dialog.component.scss'],
    providers: [FileLockerService],
})
export class FileBrowserUploadZipDialogComponent implements OnDestroy {
    @Input() public folder!: IFolderTreeModel;
    @Input() public orgId!: string;

    @Input()
    set visible(value: boolean) {
        if (this._visible != value) {
            this._visible = value;
            this.visibleChange.emit(this._visible);
        }
        this.location = $localize`:@@FILE_BROWSER.LABEL.LOCATION:Location: ` + this.folder?.path;
        this.uploadFile = null;
        this.fileUploaderComponent?.reset();
    }
    get visible(): boolean {
        return this._visible;
    }

    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() public onSaveSuccess = new EventEmitter();

    private _visible: boolean = false;
    loading: boolean = false;
    uploadFile: File | null = null;
    location!: string;
    @ViewChild('fileUploaderComponent') fileUploaderComponent!: FileUploaderComponent;
    private _destroy$: Subject<any> = new Subject<any>();

    constructor(private _fileLockerService: FileLockerService, private _notificationService: NotificationService) {}
    uploadFiles(files: File[]) {
        this.uploadFile = files?.length > 0 ? files[0] : null;
    }

    public save(): void {
        if (this.uploadFile) {
            this.loading = true;
            const fileArchive = {} as IFileArchiveModel;
            fileArchive.dirPrefix = this.folder?.path;
            fileArchive.orgId = this.orgId;
            fileArchive.originalFileName = this.uploadFile.name;
            this._fileLockerService
                .uploadFileArchive(this.uploadFile, fileArchive)

                .pipe(
                    takeUntil(this._destroy$),
                    finalize(() => {
                        this.fileUploaderComponent?.reset();
                        this.uploadFile = null;
                        this.loading = false;
                    })
                )
                .subscribe({
                    next: (files: Array<IFileModel>) => {
                        this.onSaveSuccess.emit(files);
                        this.visible = false;
                    },
                    error: () => {
                        this._notificationService.error(
                            '',
                            $localize`:@@FILE_BROWSER.UPLOAD_ZIP.ERROR:Error while uploading ZIP file.`
                        );
                    },
                });
        } else {
            this._notificationService.error(
                '',
                $localize`:@@FILE_BROWSER.FORM.ADD_FILE.SELECT_FILE:Please select a file.`
            );
        }
    }
    ngOnDestroy(): void {
        this._destroy$.next(null);
        this._destroy$.complete();
    }
}
