<div *ngIf="!loading">
    <div id="vc-user-registration-description" class="vc-user-registration-description title-l">
        <span i18n="@@LOGIN.USER_REGISTRATION.COMPLETE_REGISTRATION_FOR">Complete registration for:</span>
        <span class="vc-user-registration-merchant headline-l">{{ companyName }}</span>
        <span i18n="@@LOGIN.USER_REGISTRATION.AS_PART_OF_PROGRAM">as part of {{ programName }} program</span>
    </div>

    <vc-common-form
        #userRegistrationForm
        class="vc-user-registration-form"
        [ariaDescribedBy]="'vc-user-registration-description'"
        [showPrimaryButton]="false"
        [showSecondaryButton]="false">
        <div class="vc-user-registration-form-wrapper">
            <vc-input
                i18n-label="@@CORE.INPUT.PREFERRED_EMAIL"
                label="Preferred email"
                [required]="true"
                [maxLength]="255"
                [validationFunction]="validateEmail"
                [(value)]="email"></vc-input>
            <vc-input
                label="Username"
                i18n-label="@@CORE.INPUT.USERNAME"
                [required]="true"
                [maxLength]="255"
                [autocomplete]="'username'"
                [(value)]="username"></vc-input>
            <vc-phone [(value)]="phone" [showExt]="false"></vc-phone>
            <vc-password
                label="Password"
                i18n-label="@@LOGIN.FORM.PASSWORD"
                [required]="true"
                [description]="passwordDescription"
                [autocomplete]="'new-password'"
                [(value)]="newPassword"
                [maxLength]="32"
                [minLength]="8"
                [minNumbers]="1"
                [minUpper]="1"
                [minSpecial]="1"
                [showVisibleButton]="true"
                [showClear]="true"></vc-password>
        </div>
        <div buttons class="vc-user-registration-buttons">
            <vc-button
                type="submit"
                mode="primary"
                label="Submit"
                i18n-label="@@LOGIN.USER_REGISTRATION.REGISTER"
                [width]="'100%'"
                (trigger)="registerUser()"></vc-button>
        </div>
    </vc-common-form>
    <div class="vc-user-registration-links">
        <vc-link
            label="Need help?"
            i18n-label="@@LOGIN.NEED_HELP"
            [underlineText]="true"
            [color]="'var(--primary-700)'"
            [url]="'https://www.vikingcloud.com/contact?tab=support'"
            [showOpenInNewWindow]="true"></vc-link>
    </div>
</div>
