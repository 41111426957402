<div *ngIf="label" [id]="labelId">{{ label }}</div>
<div class="vc-checkboxes"
     [attr.aria-labelledby]="label ? labelId : null"
     [attr.aria-describedby]="!valid && touched ? errorId : null"
     [class.vc-direction-row]="direction === 'row'">
  <ng-content></ng-content>
</div>
<mat-hint
    *ngIf="!valid && touched"
    [id]="errorId"
    class="mat-error vc-error-message">
    {{ errorMessage }}</mat-hint>
