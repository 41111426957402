export enum DataUnit {
    B = 'B',
    KB = 'KB',
    MB = 'MB',
    GB = 'GB',
    TB = 'TB',
    PB = 'PB',
    EB = 'EB',
    ZB = 'ZB',
    YB = 'YB',
}
