import { HttpClient, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { PageDTO } from './page.dto';
import { ISearchDTO } from './search.dto';

export abstract class BaseService<T> {
    protected httpClient: HttpClient;
    protected serviceBaseUrl: string;
    protected environment: any;

    protected constructor(protected injector: Injector, serviceBaseUrl: string) {
        this.httpClient = injector.get(HttpClient);
        this.environment = injector.get('environment');
        this.serviceBaseUrl = serviceBaseUrl;
    }

    public search(searchDTO: any, fnCallback: Function, urlValues?: Array<any>) {
        let service = this;
        service.httpClient
            .post(`${service.environment.APP.VC_USER_BASE_URL}/${service.serviceBaseUrl}/search`, searchDTO)
            .pipe((res: any) => res)
            .subscribe((data) => {
                let pageDTO: PageDTO<T> = service.manageSearchData(data, searchDTO);
                fnCallback(pageDTO);
            });
    }

    public get(id: string, fnCallback: Function, urlValues?: Array<any>) {
        let service = this;
        service.httpClient
            .get(`${service.environment.APP.VC_USER_BASE_URL}/${service.serviceBaseUrl}/${id}`)
            .pipe((res: any) => res)
            .subscribe((data) => {
                fnCallback(data);
            });
    }

    public save(model: T, fnCallback: Function) {
        let service = this;
        service.httpClient
            .post(`${service.environment.APP.VC_USER_BASE_URL}/${service.serviceBaseUrl}`, model)
            .pipe((res: any) => res)
            .subscribe((data) => {
                fnCallback(data);
            });
    }

    public update(model: T, fnCallback: Function) {
        let service = this;
        //TODO: Replace with server call
        // model = service.dataService.update(service.serviceBaseUrl, model);
        // fnCallback(model);
    }

    public delete(id: string, fnCallback: Function) {
        let service = this;
        service.httpClient
            .delete(`${service.environment.APP.VC_USER_BASE_URL}/${service.serviceBaseUrl}/${id}`)
            .pipe((res: any) => res)
            .subscribe((data) => {
                fnCallback(data);
            });
    }

    public all(fnCallback: Function) {
        let service = this;
        service.httpClient
            .get(`${service.environment.APP.VC_USER_BASE_URL}/${service.serviceBaseUrl}`)
            .pipe((res: any) => res)
            .subscribe((data) => {
                fnCallback(data);
            });
    }

    protected manageSearchData(page: any, searchDTO: ISearchDTO): PageDTO<any> {
        return new PageDTO(page.pageRequest.pageNumber, page.pageRequest.pageSize, page.items, page.totalCount);
    }

    protected getHttpParams(): HttpParams {
        return new HttpParams();
    }
}
