export enum TimeInterval {
    DAY = 'Day',
    WEEK = 'Week',
    MONTH = 'Month',
    YEAR = 'Year',
    CUSTOM = 'Custom',
}

export class TimeIntervalHelper {
    static getTimeIntervalLocale(interval: TimeInterval) {
        switch (interval) {
            case TimeInterval.DAY:
                return $localize`:@@CORE.DAY:Day`;
            case TimeInterval.WEEK:
                return $localize`:@@CORE.WEEK:Week`;
            case TimeInterval.MONTH:
                return $localize`:@@CORE.MONTH:Month`;
            case TimeInterval.YEAR:
                return $localize`:@@CORE.YEAR:Year`;
            case TimeInterval.CUSTOM:
                return $localize`:@@CORE.CUSTOM:Custom`;
            default:
                return interval;
        }
    }
}
