export enum AssessmentStatus {
    NOT_STARTED = 'NOT_STARTED',
    PRE_ASSESSMENT = 'PRE_ASSESSMENT',
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSED = 'PAUSED',
    DELAYED = 'DELAYED',
    SUBMITTED_TO_QA = 'SUBMITTED_TO_QA',
    IN_QA = 'IN_QA',
    RETURN_TO_ASSESSOR = 'RETURN_TO_ASSESSOR',
    READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
    CANCELED = 'CANCELED',
    COMPLETED = 'COMPLETED',
}

export class AssessmentStatusHelper {
    static getAssessmentStatusLocale(status: AssessmentStatus): string {
        switch (status) {
            case AssessmentStatus.NOT_STARTED:
                return $localize`:@@CORE.ASSESSMENT_STATUS.NOT_STARTED:Not Started`;
            case AssessmentStatus.PRE_ASSESSMENT:
                return $localize`:@@CORE.ASSESSMENT_STATUS.PRE_ASSESSMENT:Pre-assessment`;
            case AssessmentStatus.IN_PROGRESS:
                return $localize`:@@CORE.ASSESSMENT_STATUS.IN_PROGRESS:In Progress`;
            case AssessmentStatus.IN_QA:
                return $localize`:@@CORE.ASSESSMENT_STATUS.IN_QA:In QA`;
            case AssessmentStatus.PAUSED:
                return $localize`:@@CORE.ASSESSMENT_STATUS.PAUSED:Paused`;
            case AssessmentStatus.DELAYED:
                return $localize`:@@CORE.ASSESSMENT_STATUS.DELAYED:Delayed`;
            case AssessmentStatus.SUBMITTED_TO_QA:
                return $localize`:@@CORE.ASSESSMENT_STATUS.SUBMITTED_TO_QA:Submitted to QA`;
            case AssessmentStatus.RETURN_TO_ASSESSOR:
                return $localize`:@@CORE.ASSESSMENT_STATUS.RETURN_TO_ASSESSOR:Return to Assessor`;
            case AssessmentStatus.READY_FOR_DELIVERY:
                return $localize`:@@CORE.ASSESSMENT_STATUS.READY_FOR_DELIVERY:Ready for Delivery`;
            case AssessmentStatus.CANCELED:
                return $localize`:@@CORE.ASSESSMENT_STATUS.CANCELED:Canceled`;
            case AssessmentStatus.COMPLETED:
                return $localize`:@@CORE.ASSESSMENT_STATUS.COMPLETED:Completed`;
            default:
                return status;
        }
    }

    /**
     * @description
     * Returns an array of all the statuses except Canceled and Completed
     */
    static getDefaultAssessmentStatuses(): AssessmentStatus[] {
        return [
            AssessmentStatus.NOT_STARTED,
            AssessmentStatus.PRE_ASSESSMENT,
            AssessmentStatus.IN_PROGRESS,
            AssessmentStatus.PAUSED,
            AssessmentStatus.DELAYED,
            AssessmentStatus.SUBMITTED_TO_QA,
            AssessmentStatus.IN_QA,
            AssessmentStatus.RETURN_TO_ASSESSOR,
            AssessmentStatus.READY_FOR_DELIVERY,
        ];
    }
}
