import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[vcResizableColumn]',
})
export class ResizableColumnDirective implements AfterViewInit, DoCheck {
    private readonly columnHeaderCell!: HTMLElement;

    @Input()
    resizable: boolean = true;

    @HostBinding('style.width')
    @Input()
    width: string | null = null;

    /** Column minimum width in px */
    @Input()
    minWidth: number = 100;

    @HostBinding('class.pointer-events-none')
    isResizing = false;

    @Output()
    widthChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private _renderer: Renderer2,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(DOCUMENT) private readonly documentRef: Document
    ) {
        this.columnHeaderCell = this.elementRef.nativeElement;
    }

    ngDoCheck() {
        const offsetWidth = this.elementRef?.nativeElement?.offsetWidth;
        if (offsetWidth && offsetWidth < this.minWidth) {
            this.width = `${this.minWidth}px`;
            this.widthChange.emit(this.width);
        }
    }

    ngAfterViewInit() {
        if (!this.resizable) return;

        const resizer = this._renderer.createElement('div');
        this._renderer.addClass(resizer, 'vc-resizer');
        this._renderer.appendChild(this.columnHeaderCell, resizer);

        fromEvent<MouseEvent>(resizer, 'mousedown')
            .pipe(
                tap((e: MouseEvent) => {
                    e.preventDefault();
                    this.isResizing = true;
                }),
                switchMap((e: MouseEvent) => {
                    const { width, right } = this.elementRef.nativeElement.closest('th').getBoundingClientRect();

                    return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
                        map(({ clientX }) => width + clientX - right),
                        distinctUntilChanged(),
                        takeUntil(
                            fromEvent(this.documentRef, 'mouseup').pipe(
                                tap((e: Event) => {
                                    if (e.type === 'mouseup') {
                                        setTimeout(() => (this.isResizing = false), 0);
                                    }
                                })
                            )
                        )
                    );
                })
            )
            .subscribe((value: number) => {
                value = value < this.minWidth ? this.minWidth : value;
                this.width = `${value}px`;
                this.widthChange.emit(this.width);
                this._changeDetectorRef.markForCheck();
            });
    }
}
