import { Component, Input } from '@angular/core';

@Component({
    selector: 'vc-country-flag',
    templateUrl: './country-flag.component.html',
    styleUrls: ['./country-flag.component.scss'],
})
export class CountryFlagComponent {
    @Input()
    countryClass!: string;
}
