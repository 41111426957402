import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BreadcrumbsService } from '../../services/breadcrumbs-service/breadcrumbs.service';

export interface Breadcrumb {
    label?: string;
    url?: string;
    iconName?: string;
    ariaLabel?: string;
    queryParams?: Params | null;
    goBack?: boolean;
}

/** Example of usage
 * HTML
 * <vc-common-breadcrumb
 *          [breadcrumbs]="breadcrumbs"></vc-common-breadcrumb>
 *
 * TS
 *     breadcrumbs = [
 *         {
 *             label: 'Home',
 *             url: '',
 *         },
 *         {
 *             label: 'Dashboard',
 *             url: 'dashboard',
 *         },
 *         {
 *             label: 'User Details',
 *             url: 'user-details',
 *         },
 *     ];
 * */

@Component({
    selector: 'vc-common-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Input()
    breadcrumbs: Breadcrumb[] = [];

    constructor(private router: Router, private _breadcrumbsService: BreadcrumbsService) {}

    async navigateTo(breadcrumb: Breadcrumb) {
        if (breadcrumb.goBack) {
            await this._breadcrumbsService.back();
        } else if (breadcrumb.url) {
            await this.router.navigate([breadcrumb.url], { queryParams: breadcrumb.queryParams });
        }
    }
}
