<vc-common-autocomplete
    label="Hour"
    i18n-label="@@COMMON_UI.TIME_PICKER.HOUR"
    [(value)]="hoursValue"
    [options]="hours"
    [required]="required"
    [disabled]="disabled"
    [readonly]="readonly"
    [minimalStyles]="minimalStyles"
    [defaultValue]="0"
    [itemRenderer]="optionRenderer"
    (valueChange)="emitSelectionChange()"></vc-common-autocomplete>

<vc-common-autocomplete
    label="Minute"
    i18n-label="@@COMMON_UI.TIME_PICKER.MINUTES"
    [(value)]="minutesValue"
    [options]="minuteOptions"
    [required]="required"
    [disabled]="disabled"
    [readonly]="readonly"
    [minimalStyles]="minimalStyles"
    [defaultValue]="0"
    [itemRenderer]="minuteRenderer"
    (valueChange)="emitSelectionChange()"></vc-common-autocomplete>
