import { Pipe, PipeTransform } from '@angular/core';
import { AssessmentStatus, AssessmentStatusHelper } from '../model/assessment-status.enum';
import { TaskStatus, TaskStatusHelper } from '../model/task-status.enum';
import { AdHocTaskPurpose, AdHocTaskPurposeHelper } from '../model/ad-hoc-task-purpose.enum';
import { ReferenceTaskType, ReferenceTaskTypeHelper } from '../model/referenceTaskType.enum';

@Pipe({ name: 'assessmentStatus' })
export class AssessmentStatusPipe implements PipeTransform {
    transform(status: AssessmentStatus | string): string {
        return AssessmentStatusHelper.getAssessmentStatusLocale(status as AssessmentStatus);
    }
}

@Pipe({ name: 'taskStatus' })
export class TaskStatusPipe implements PipeTransform {
    transform(status: TaskStatus | string): string {
        return TaskStatusHelper.getTaskStatusString(status as TaskStatus);
    }
}

@Pipe({ name: 'adHocTaskPurpose' })
export class AdHocTaskPurposePipe implements PipeTransform {
    transform(purpose: AdHocTaskPurpose | string): string {
        if (!purpose) {
            return purpose;
        }
        return AdHocTaskPurposeHelper.getAdHocTaskPurposeLocale(purpose as AdHocTaskPurpose);
    }
}

@Pipe({
    name: 'referenceTaskType',
})
export class ReferenceTaskTypePipe implements PipeTransform {
    transform(type: ReferenceTaskType | string): string {
        if (!type) {
            return type;
        }
        return ReferenceTaskTypeHelper.getReferenceTaskLocale(type as ReferenceTaskType);
    }
}
