import { Country } from '../../common/countries';
import { Phone } from './phone.component';

export class PhoneUtil {
    static getPhoneFromNumberAndCode(code: string | null, number: string, extension?: string): Phone {
        let phone = {
            country: code ? Country.getCountryByCode(code, number) : null,
            number: number,
        } as Phone;

        if (extension) {
            phone.extension = extension;
        }

        return phone;
    }
}
