export enum ReferenceTaskType {
    DOCUMENT = 'DOCUMENT',
    TEXT = 'TEXT',
    DATE = 'DATE',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    TODO = 'TODO',
    NO_RESPONSE = 'NO_RESPONSE',
}

export class ReferenceTaskTypeHelper {
    static getReferenceTaskLocale(type: ReferenceTaskType) {
        switch (type) {
            case ReferenceTaskType.DOCUMENT:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.DOCUMENT:Document`;
            case ReferenceTaskType.DATE:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.DATE:Date`;
            case ReferenceTaskType.TEXT:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.TEXT:Text`;
            case ReferenceTaskType.SINGLE_SELECT:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.SINGLE_SELECT:Single select`;
            case ReferenceTaskType.MULTI_SELECT:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.MULTI_SELECT:Multi-select`;
            case ReferenceTaskType.TODO:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.TODO:To do`;
            case ReferenceTaskType.NO_RESPONSE:
                return $localize`:@@CORE.REFERENCE_TASK_TYPES.NO_RESPONSE:No response`;
            default:
                return type;
        }
    }
}
