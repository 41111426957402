import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryImage',
})
export class CountryImagePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'en':
                return 'assets/images/countries_flags/usa.svg';
            case 'fr':
                return 'assets/images/countries_flags/french.svg';
            case 'es':
                return 'assets/images/countries_flags/spanish.svg';
            default:
                return 'assets/images/countries_flags/usa.svg';
        }
    }
}
