import { Component, EventEmitter, Input, Output } from '@angular/core';
import { allCountries, Country } from '../../common/countries';
import { ValidationFunction } from '../base-field/base-field';

@Component({
    selector: 'vc-country-selector',
    templateUrl: './country-selector.component.html',
    styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent {
    @Input()
    set value(val: Country | null) {
        if (val != this._value) {
            this._value = val;
            this.valueChange.emit(this._value);
        }
    }

    get value(): Country | null {
        return this._value;
    }

    private _value!: Country | null;

    /** County selector label */
    @Input()
    label: string = $localize`:@@COMMON_UI.COUNTRY_SELECTOR.COUNTRY:Country`;

    /** Input placeholder */
    @Input()
    placeholder: string = $localize`:@@COMMON_UI.COUNTRY_SELECTOR.SELECT_COUNTRY:Select Country`;

    /** List of countries. */
    @Input()
    countries: Country[] = allCountries;

    /** Whether the field is disabled. */
    @Input()
    disabled: boolean = false;

    /** Whether the field is required. */
    @Input()
    required: boolean = false;

    /** Whether the field is readonly. */
    @Input()
    readonly: boolean = false;

    /** Whether to show country name. */
    @Input()
    showName: boolean = true;

    /** Whether to show country code. */
    @Input()
    showPhoneCode: boolean = true;

    /** Whether to show clear button. */
    @Input()
    showClear: boolean = true;

    /** Whether to apply minimal styles(no border, background, underline etc)  */
    @Input()
    minimalStyles: boolean = false;

    /** Suffix icon. */
    @Input()
    suffixIcon!: string;

    /** Function to validate field, should return error message */
    @Input()
    validationFunction!: ValidationFunction<Country>;

    /** Two ways data binding for country value */
    @Output()
    valueChange = new EventEmitter<Country | null>();

    itemRenderer: (data: Country) => string = (country: Country) =>
        country
            ? `${this.showName ? country?.name ?? '' : ''} ${this.showPhoneCode ? '+' + country?.phoneCode : ''}`
            : '';
}
