import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, WhoAmI, CoreConstant, CommonUtil } from '@libs/vc-core-lib';

@Component({
    selector: 'vc-external-login',
    templateUrl: './external-login.component.html',
    styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
    showError = false;
    generalError = $localize`:@@LOGIN_ISSUE:There was an issue logging in.  Please try again.`;
    error!: string | null;

    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService) {}

    ngOnInit(): void {
        const params = this._route.snapshot.queryParams;
        const userToken = params['token'];
        if (!userToken) {
            this.error = params['error'];
            this.showError = true;
            CommonUtil.hideAppLoader();
            return;
        }

        sessionStorage.removeItem(CoreConstant.X_AUTH_TOKEN);
        sessionStorage.setItem(CoreConstant.X_AUTH_TOKEN, userToken);

        this._userService
            .whoAmI()
            .pipe(
                take(1),
                finalize(() => CommonUtil.hideAppLoader())
            )
            .subscribe({
                next: (user: WhoAmI) => {
                    user && this._router.navigate(['/']);
                },
                error: () => {
                    this.showError = true;

                    // Clear route params
                    this._router.navigate([], {
                        queryParams: {
                            token: null,
                        },
                        queryParamsHandling: 'merge',
                    });
                },
            });
    }
}
