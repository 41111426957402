import { ProductEnum } from './product-enum';

export enum EventKey {
    // CCS
    MERCHANT_INVITE = 'MERCHANT_INVITE',
    // MSS
    MSS_FORTINET_IPS_EVENT = 'MSS_FORTINET_IPS_EVENT',
    MSS_PRTG_STATUS_UP = 'MSS_PRTG_STATUS_UP',
    MSS_PRTG_STATUS_DOWN = 'MSS_PRTG_STATUS_DOWN',
    MSS_UTM_SETTINGS_CHANGED = 'MSS_UTM_SETTINGS_CHANGED',
    MSS_ROUGE_AP = 'MSS_ROUGE_AP',
    MSS_INTERFACE_STATUS_CHANGE = 'MSS_INTERFACE_STATUS_CHANGE',
    MSS_LOGIN_FAILED = 'MSS_LOGIN_FAILED',
    MSS_IDS_EVENT = 'MSS_IDS_EVENT',
    MSS_DEVICE_WENT_QUIET = 'MSS_DEVICE_WENT_QUIET',
    // MST/MCS
    TASK_IN_PROGRESS = 'TASK_IN_PROGRESS',
    TASK_UPCOMING = 'TASK_UPCOMING',
    TASK_REJECTED_PENDING = 'TASK_REJECTED_PENDING',
    TASK = 'TASK',
    TASK_PENDING_ASSIGNMENT = 'TASK_PENDING_ASSIGNMENT',
    TASK_ASSIGNED = 'TASK_ASSIGNED',
    TASK_REJECTED = 'TASK_REJECTED',
    TASK_SUBMITTED = 'TASK_SUBMITTED',
    TASK_IN_REVIEW = 'TASK_IN_REVIEW',
    TASK_STATUS_CHANGED = 'TASK_STATUS_CHANGED',
    TASK_DELEGATED = 'TASK_DELEGATED',
    MILESTONE_ADDED = 'MILESTONE_ADDED',
    MILESTONE_REMOVED = 'MILESTONE_REMOVED',
    MILESTONE_DATE_CHANGED = 'MILESTONE_DATE_CHANGED',
    MILESTONE_RISK_CHANGED = 'MILESTONE_RISK_CHANGED',
    ASSESSMENT_USER_REMOVED = 'ASSESSMENT_USER_REMOVED',
    MILESTONE_NAME_CHANGED = 'MILESTONE_NAME_CHANGED',
    TASK_COMPLETED = 'TASK_COMPLETED',
    MST_USER_REMOVE_FROM_ASSESSMENT = 'MST_USER_REMOVE_FROM_ASSESSMENT',
    TASK_COMPLETION_PERCENTAGE = 'TASK_COMPLETION_PERCENTAGE',
    ASSESSMENT = 'ASSESSMENT',
    ASSESSMENT_ASSIGNED = 'ASSESSMENT_ASSIGNED',
    ASSESSMENT_REJECTED = 'ASSESSMENT_REJECTED',
    ASSESSMENT_UPCOMING = 'ASSESSMENT_UPCOMING',
    ASSESSMENT_PAST_DUE = 'ASSESSMENT_PAST_DUE',
    MST_USER_ADDED__TO_ASSESSMENT = 'MST_USER_ADDED__TO_ASSESSMENT',
    TASK_PAST_DUE = 'TASK_PAST_DUE',
    ASSESSMENT_COMPLETED = 'ASSESSMENT_COMPLETED',
    TASK_MILESTONE_ACHIEVED = 'TASK_MILESTONE_ACHIEVED',
    TASK_MILESTONE_MISSED = 'TASK_MILESTONE_MISSED',
    ASSESSMENT_7_DAYS_FROM_DUE_DATE = 'ASSESSMENT_7_DAYS_FROM_DUE_DATE',
    ASSESSMENT_30_DAYS_FROM_DUE_DATE = 'ASSESSMENT_30_DAYS_FROM_DUE_DATE',
    ASSESSMENT_60_DAYS_FROM_DUE_DATE = 'ASSESSMENT_60_DAYS_FROM_DUE_DATE',
    // Portal
    PASSWORD_CHANGE = 'PASSWORD_CHANGE',
    PORTAL_COMMON_EVENT = 'PORTAL_COMMON_EVENT',
    PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
    MFA_ENABLED = 'MFA_ENABLED',
    MFA_DISABLED = 'MFA_DISABLED',
    WELCOME_TOKEN = 'WELCOME_TOKEN',
    WELCOME = 'WELCOME',
    VMS_ERROR_CHECKING = 'VMS_ERROR_CHECKING',
    // Vulnerability Management
    VMS_SCAN_STARTED = 'VMS_SCAN_STARTED',
    VMS_SCAN_SCHEDULED = 'VMS_SCAN_SCHEDULED',
    VMS_SCAN_FINISHED = 'VMS_SCAN_FINISHED',
    VMS_DISPUTE_REVIEWED = 'VMS_Dispute_Reviewed',
    VMS_PCI_PASS = 'VMS_PCI_PASS',
    VMS_SCAN_SCHEDULED_NEXT_DAY = 'VMS_SCAN_SCHEDULED_NEXT_DAY',
    VMS_SCAN_CANCELED = 'VMS_SCAN_CANCELED',
}

export abstract class EventKeyHelper {
    static isProductEventKey(product: ProductEnum, value: EventKey): boolean {
        switch (product) {
            case ProductEnum.CCS:
                return value == EventKey.MERCHANT_INVITE;
            case ProductEnum.MSS:
                return [
                    EventKey.MSS_FORTINET_IPS_EVENT,
                    EventKey.MSS_PRTG_STATUS_UP,
                    EventKey.MSS_PRTG_STATUS_DOWN,
                    EventKey.MSS_UTM_SETTINGS_CHANGED,
                    EventKey.MSS_ROUGE_AP,
                    EventKey.MSS_INTERFACE_STATUS_CHANGE,
                    EventKey.MSS_LOGIN_FAILED,
                    EventKey.MSS_IDS_EVENT,
                    EventKey.MSS_DEVICE_WENT_QUIET,
                ].includes(value);
            case ProductEnum.MST_MCS:
                return [
                    EventKey.TASK_IN_PROGRESS,
                    EventKey.TASK_UPCOMING,
                    EventKey.TASK_REJECTED_PENDING,
                    EventKey.TASK,
                    EventKey.TASK_PENDING_ASSIGNMENT,
                    EventKey.TASK_ASSIGNED,
                    EventKey.TASK_REJECTED,
                    EventKey.TASK_SUBMITTED,
                    EventKey.TASK_IN_REVIEW,
                    EventKey.TASK_STATUS_CHANGED,
                    EventKey.TASK_DELEGATED,
                    EventKey.MILESTONE_ADDED,
                    EventKey.MILESTONE_REMOVED,
                    EventKey.MILESTONE_DATE_CHANGED,
                    EventKey.MILESTONE_RISK_CHANGED,
                    EventKey.ASSESSMENT_USER_REMOVED,
                    EventKey.MILESTONE_NAME_CHANGED,
                    EventKey.TASK_COMPLETED,
                    EventKey.MST_USER_REMOVE_FROM_ASSESSMENT,
                    EventKey.TASK_COMPLETION_PERCENTAGE,
                    EventKey.ASSESSMENT,
                    EventKey.ASSESSMENT_ASSIGNED,
                    EventKey.ASSESSMENT_REJECTED,
                    EventKey.ASSESSMENT_UPCOMING,
                    EventKey.ASSESSMENT_PAST_DUE,
                    EventKey.MST_USER_ADDED__TO_ASSESSMENT,
                    EventKey.TASK_PAST_DUE,
                    EventKey.ASSESSMENT_COMPLETED,
                    EventKey.TASK_MILESTONE_ACHIEVED,
                    EventKey.TASK_MILESTONE_MISSED,
                    EventKey.ASSESSMENT_7_DAYS_FROM_DUE_DATE,
                    EventKey.ASSESSMENT_30_DAYS_FROM_DUE_DATE,
                    EventKey.ASSESSMENT_60_DAYS_FROM_DUE_DATE,
                ].includes(value);
            case ProductEnum.VULNERABILITY_MANAGEMENT:
                return [
                    EventKey.VMS_SCAN_STARTED,
                    EventKey.VMS_SCAN_SCHEDULED,
                    EventKey.VMS_SCAN_FINISHED,
                    EventKey.VMS_DISPUTE_REVIEWED,
                    EventKey.VMS_PCI_PASS,
                    EventKey.VMS_SCAN_SCHEDULED_NEXT_DAY,
                    EventKey.VMS_SCAN_CANCELED,
                ].includes(value);
            case ProductEnum.PORTAL:
                return [
                    EventKey.PASSWORD_CHANGE,
                    EventKey.PORTAL_COMMON_EVENT,
                    EventKey.PASSWORD_RECOVERY,
                    EventKey.MFA_ENABLED,
                    EventKey.MFA_DISABLED,
                    EventKey.WELCOME_TOKEN,
                    EventKey.WELCOME,
                    EventKey.VMS_ERROR_CHECKING,
                ].includes(value);
            default:
                return true;
        }
    }

    static getProductByEventKey(eventKey: EventKey): ProductEnum {
        switch (eventKey) {
            case EventKey.MERCHANT_INVITE:
                return ProductEnum.CCS;
            case EventKey.MSS_FORTINET_IPS_EVENT:
            case EventKey.MSS_PRTG_STATUS_UP:
            case EventKey.MSS_PRTG_STATUS_DOWN:
            case EventKey.MSS_UTM_SETTINGS_CHANGED:
            case EventKey.MSS_ROUGE_AP:
            case EventKey.MSS_INTERFACE_STATUS_CHANGE:
            case EventKey.MSS_LOGIN_FAILED:
            case EventKey.MSS_IDS_EVENT:
            case EventKey.MSS_DEVICE_WENT_QUIET:
                return ProductEnum.MSS;
            case EventKey.TASK_IN_PROGRESS:
            case EventKey.TASK_UPCOMING:
            case EventKey.TASK_REJECTED_PENDING:
            case EventKey.TASK:
            case EventKey.TASK_PENDING_ASSIGNMENT:
            case EventKey.TASK_ASSIGNED:
            case EventKey.TASK_REJECTED:
            case EventKey.TASK_SUBMITTED:
            case EventKey.TASK_IN_REVIEW:
            case EventKey.TASK_STATUS_CHANGED:
            case EventKey.TASK_DELEGATED:
            case EventKey.MILESTONE_ADDED:
            case EventKey.MILESTONE_REMOVED:
            case EventKey.MILESTONE_DATE_CHANGED:
            case EventKey.MILESTONE_RISK_CHANGED:
            case EventKey.ASSESSMENT_USER_REMOVED:
            case EventKey.MILESTONE_NAME_CHANGED:
            case EventKey.TASK_COMPLETED:
            case EventKey.MST_USER_REMOVE_FROM_ASSESSMENT:
            case EventKey.TASK_COMPLETION_PERCENTAGE:
            case EventKey.ASSESSMENT:
            case EventKey.ASSESSMENT_ASSIGNED:
            case EventKey.ASSESSMENT_REJECTED:
            case EventKey.ASSESSMENT_UPCOMING:
            case EventKey.ASSESSMENT_PAST_DUE:
            case EventKey.MST_USER_ADDED__TO_ASSESSMENT:
            case EventKey.TASK_PAST_DUE:
            case EventKey.ASSESSMENT_COMPLETED:
            case EventKey.TASK_MILESTONE_ACHIEVED:
            case EventKey.TASK_MILESTONE_MISSED:
            case EventKey.ASSESSMENT_7_DAYS_FROM_DUE_DATE:
            case EventKey.ASSESSMENT_30_DAYS_FROM_DUE_DATE:
            case EventKey.ASSESSMENT_60_DAYS_FROM_DUE_DATE:
                return ProductEnum.MST_MCS;
            case EventKey.VMS_SCAN_STARTED:
            case EventKey.VMS_SCAN_SCHEDULED:
            case EventKey.VMS_SCAN_FINISHED:
            case EventKey.VMS_DISPUTE_REVIEWED:
            case EventKey.VMS_PCI_PASS:
            case EventKey.VMS_SCAN_SCHEDULED_NEXT_DAY:
            case EventKey.VMS_SCAN_CANCELED:
                return ProductEnum.VULNERABILITY_MANAGEMENT;
            case EventKey.PASSWORD_CHANGE:
            case EventKey.PORTAL_COMMON_EVENT:
            case EventKey.PASSWORD_RECOVERY:
            case EventKey.MFA_ENABLED:
            case EventKey.MFA_DISABLED:
            case EventKey.WELCOME_TOKEN:
            case EventKey.WELCOME:
            case EventKey.VMS_ERROR_CHECKING:
                return ProductEnum.PORTAL;
            default:
                return ProductEnum.ALL;
        }
    }

    static getEventKeyIcon(value: EventKey) {
        switch (value) {
            case EventKey.MERCHANT_INVITE:
                return 'edit_document';
            case EventKey.MSS_FORTINET_IPS_EVENT:
            case EventKey.MSS_PRTG_STATUS_UP:
            case EventKey.MSS_PRTG_STATUS_DOWN:
            case EventKey.MSS_UTM_SETTINGS_CHANGED:
            case EventKey.MSS_ROUGE_AP:
            case EventKey.MSS_INTERFACE_STATUS_CHANGE:
            case EventKey.MSS_LOGIN_FAILED:
            case EventKey.MSS_IDS_EVENT:
            case EventKey.MSS_DEVICE_WENT_QUIET:
                return 'security';
            case EventKey.TASK_IN_PROGRESS:
            case EventKey.TASK_UPCOMING:
            case EventKey.TASK_REJECTED_PENDING:
            case EventKey.TASK:
            case EventKey.TASK_PENDING_ASSIGNMENT:
            case EventKey.TASK_ASSIGNED:
            case EventKey.TASK_REJECTED:
            case EventKey.TASK_SUBMITTED:
            case EventKey.TASK_IN_REVIEW:
            case EventKey.TASK_STATUS_CHANGED:
            case EventKey.TASK_DELEGATED:
            case EventKey.MILESTONE_ADDED:
            case EventKey.MILESTONE_REMOVED:
            case EventKey.MILESTONE_DATE_CHANGED:
            case EventKey.MILESTONE_RISK_CHANGED:
            case EventKey.ASSESSMENT_USER_REMOVED:
            case EventKey.MILESTONE_NAME_CHANGED:
            case EventKey.TASK_COMPLETED:
            case EventKey.MST_USER_REMOVE_FROM_ASSESSMENT:
            case EventKey.TASK_COMPLETION_PERCENTAGE:
            case EventKey.ASSESSMENT:
            case EventKey.ASSESSMENT_ASSIGNED:
            case EventKey.ASSESSMENT_REJECTED:
            case EventKey.ASSESSMENT_UPCOMING:
            case EventKey.ASSESSMENT_PAST_DUE:
            case EventKey.MST_USER_ADDED__TO_ASSESSMENT:
            case EventKey.TASK_PAST_DUE:
            case EventKey.ASSESSMENT_COMPLETED:
            case EventKey.TASK_MILESTONE_ACHIEVED:
            case EventKey.TASK_MILESTONE_MISSED:
            case EventKey.ASSESSMENT_7_DAYS_FROM_DUE_DATE:
            case EventKey.ASSESSMENT_30_DAYS_FROM_DUE_DATE:
            case EventKey.ASSESSMENT_60_DAYS_FROM_DUE_DATE:
                return 'assignment';
            case EventKey.VMS_SCAN_STARTED:
            case EventKey.VMS_SCAN_SCHEDULED:
            case EventKey.VMS_SCAN_FINISHED:
            case EventKey.VMS_DISPUTE_REVIEWED:
            case EventKey.VMS_PCI_PASS:
            case EventKey.VMS_SCAN_SCHEDULED_NEXT_DAY:
            case EventKey.VMS_SCAN_CANCELED:
                return 'network_check';
            case EventKey.PASSWORD_CHANGE:
            case EventKey.PORTAL_COMMON_EVENT:
            case EventKey.PASSWORD_RECOVERY:
            case EventKey.MFA_ENABLED:
            case EventKey.MFA_DISABLED:
            case EventKey.WELCOME_TOKEN:
            case EventKey.WELCOME:
            case EventKey.VMS_ERROR_CHECKING:
                return 'dashboard';
            default:
                return 'notifications';
        }
    }
}
