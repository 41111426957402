<nav aria-label="VikingCloud Asgard">
    <mat-tree
        class="vc-navigation-menu-tree"
        role="tree"
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        #treeComponent>
        <mat-tree-node
            *matTreeNodeDef="let node"
            #nodeElement
            matTreeNodeToggle
            matTreeNodePadding
            [attr.aria-current]="node.data.selected"
            (keydown)="nodeKeyUp($event, node, nodeElement)">
            <a
                class="vc-node-wrapper"
                #nodeLink
                [ngClass]="node.data.selected ? 'vc-node-selected' : ''"
                [attr.id]="node.id"
                [attr.aria-owns]="getAriaOwnsId(nodeElement, node)"
                [attr.aria-current]="node.data.selected ? 'page' : ''"
                [attr.tabindex]="node.data.selected ? '0' : '-1'"
                (click)="navigateToLink(node)">
                <mat-icon *ngIf="node.data?.icon" class="vc-nav-menu-node-icon">{{ node.data?.icon }}</mat-icon>
                <span class="vc-nav-menu-node-label">{{ node.data?.text }}</span>
            </a>
        </mat-tree-node>

        <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            #nodeElement
            matTreeNodeToggle
            matTreeNodePadding
            [role]="'group'"
            (keydown)="nodeKeyUp($event, node, nodeElement)">
            <a
                class="vc-node-wrapper"
                #nodeLink
                [ngClass]="node.data.selected ? 'vc-branch-selected' : ''"
                [tabIndex]="node.data.selected ? '0' : '-1'"
                [attr.id]="node.id"
                [attr.aria-current]="node.data.selected ? 'page' : ''"
                [attr.aria-expanded]="node.data.expanded ? 'true' : 'false'"
                (click)="toggleExpand(node.data)">
                <mat-icon
                    *ngIf="node.data?.icon"
                    class="vc-nav-menu-node-icon"
                    [ngClass]="node.data.selected ? 'vc-nav-menu-node-icon-selected' : ''"
                    >{{ node.data?.icon }}</mat-icon
                >
                <span class="vc-nav-menu-node-label">{{ node.data?.text }}</span>
                <button
                    class="vc-nav-menu-arrow"
                    mat-icon-button
                    matTreeNodeToggle
                    [attr.tabindex]="'-1'"
                    [attr.aria-label]="'Toggle ' + node.data?.text"
                    (click)="toggleExpand(node.data)">
                    <mat-icon>
                        {{ treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right' }}
                    </mat-icon>
                </button>
            </a>
        </mat-tree-node>
    </mat-tree>
</nav>
