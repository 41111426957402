<mat-form-field
    class="vc-input-container vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [class.mat-form-field-invalid]="!valid && touched"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        matInput
        placeholder="{{ placeholder }}"
        #inputElement
        [matTooltip]="tooltip"
        [attr.aria-invalid]="!valid && touched"
        [readonly]="readonly"
        [disabled]="disabled"
        [type]="type"
        [aria-describedby]="ariaDescribedBy"
        [required]="required"
        [attr.autocomplete]="autocomplete"
        [class.vc-input-element]="disabled"
        [(ngModel)]="value"
        (focus)="focusChange.emit()"
        (blur)="onBlur()"
        (keyup.enter)="keyupEnter.emit(value)"
        (keydown)="onKeyDown()"
        (keyup)="onKeyUp()" />
    <mat-icon matPrefix *ngIf="prefixIcon" [style.color]="!disabled && !readonly ? prefixIconColor : ''">{{
        prefixIcon
        }}</mat-icon>
    <div matSuffix class="vc-field-suffix">
        <mat-icon *ngIf="suffixIcon && !value" [style.color]="!disabled && !readonly ? suffixIconColor : ''">{{
            suffixIcon
            }}</mat-icon>
        <vc-button
            *ngIf="suffixButtonIcon"
            iconColor="var(--text-high-emphasis)"
            mode="icon"
            [ariaLabel]="suffixButtonAriaLabel"
            [iconName]="suffixButtonIcon"
            (trigger)="$event.stopPropagation(); suffixButtonTrigger.emit()"></vc-button>
        <vc-button
            *ngIf="value && showClear && !disabled && !readonly"
            iconColor="var(--text-high-emphasis)"
            ariaLabel="Clear input"
            iconName="close"
            mode="icon"
            (trigger)="$event.stopPropagation(); clear(); focus()"></vc-button>
    </div>
    <mat-hint *ngIf="(!valid && touched && showErrorMessage) || description">
        <span *ngIf="description">{{ description }}</span>
        <span *ngIf="(!valid && touched && showErrorMessage)" class="mat-error vc-error-message">{{ errorMessage }}</span>
    </mat-hint>
</mat-form-field>
