import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TreeNode } from '../../interfaces/tree-node';
import { isEqual } from 'lodash-es';

/**
 * HTML
 * <vc-tree [data]="treeNode"
 *          [itemRenderer]="treeItemRenderer"
 *          [multiselect]="false"
 *          [highlightable]="true"></vc-tree>
 *
 * TS
 *    treeNode: TreeNode<any>[] = [
 *         {
 *             data: { name: 'All', id: 1 },
 *             children: [
 *                 {
 *                     data: { name: 'Fruit', id: 2 },
 *                     children: [
 *                         { data: { name: 'Apple', id: 3 } },
 *                         { data: { name: 'Banana', id: 4 } },
 *                         { data: { name: 'Fruit loops', id: 5 } },
 *                     ],
 *                 },
 *                 {
 *                     data: { name: 'Vegetables', id: 6 },
 *                     children: [
 *                         {
 *                             data: { name: 'Green', id: 7 },
 *                             children: [
 *                                 { data: { name: 'Broccoli', id: 8 } },
 *                                 { data: { name: 'Brussels sprouts', id: 9 } },
 *                             ],
 *                         },
 *                         {
 *                             data: { name: 'Orange', id: 10 },
 *                             children: [
 *                                 { data: { name: 'Pumpkins', id: 11 }, selectable: false },
 *                                 { data: { name: 'Carrots', id: 12 }, selectable: false },
 *                             ],
 *                         },
 *                     ],
 *                 },
 *             ],
 *         },
 *     ];
 *
 *     treeItemRenderer = (value: { name: string; id: number }) => value?.name ?? '';
 *
 **/

enum TreeKeys {
    ENTER = 'Enter',
    SPACE = 'Space',
    UP = 'ArrowUp',
    DOWN = 'ArrowDown',
    LEFT = 'ArrowLeft',
    RIGHT = 'ArrowRight',
}

class FlatNode<T> {
    data!: T;
    level!: number;
    expanded!: boolean;
    selectable!: boolean;
    iconPrefix!: string;
}

@Component({
    selector: 'vc-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
})
export class TreeComponent<T> {
    /** Map from flat node to nested node. This helps find the nested node to be modified */
    private _flatNodeMap = new Map<FlatNode<any>, TreeNode<any>>();
    /** Map from nested node to flattened node. This helps to keep the same object for selection */
    private _nestedNodeMap = new Map<TreeNode<any>, FlatNode<any>>();
    private _treeFlattener!: MatTreeFlattener<TreeNode<T>, FlatNode<T>>;

    filter: string = '';
    showEmptyStateMessage: boolean = false;

    treeControl!: FlatTreeControl<FlatNode<T>>;
    dataSource!: MatTreeFlatDataSource<TreeNode<T>, FlatNode<T>>;
    selection = new SelectionModel<FlatNode<T>>(true);

    /** Tree data */
    @Input()
    set data(value: TreeNode<T>[]) {
        this._data = value;
        this._initDataSource();
    }

    get data(): TreeNode<T>[] {
        return this._data;
    }

    private _data!: TreeNode<T>[];

    @Input()
    highlighted: T | null = null;

    /** Filter label. */
    @Input()
    filterLabel: string = $localize`:@@COMMON_UI.TREE.FILTER:Filter`;

    /** Filter placeholder. */
    @Input()
    filterPlaceholder: string = $localize`:@@COMMON_UI.TREE.FILTER:Filter`;

    /** Whether to expand/collapse all nodes */
    @Input()
    set expandAll(value: boolean) {
        if (this.expandAll != value) {
            this._expandAll = value;
            this._expandAll ? this.expandAllNodes() : this.collapseAllNodes();
            this.expandAllChange.emit(this._expandAll);
        }
    }

    get expandAll(): boolean {
        return this._expandAll;
    }

    private _expandAll: boolean = true;

    /** Whether to show filter input. */
    @Input()
    showFilter: boolean = true;

    /** Whether to show checkboxes for node selection. */
    @Input()
    multiselect: boolean = false;

    /** Whether to highlight single node */
    @Input()
    highlightable: boolean = false;

    /** Whether to set highlight to null if same node is selected */
    @Input()
    canUnhighlight: boolean = true;

    /** Whether to style highlighted node label in bold  */
    @Input()
    highlightLabelBold: boolean = false;

    /** Whether to highlight single node */
    @Input()
    showCheckIconForHighlighted: boolean = false;

    /** Height of node list */
    @Input()
    height!: string;

    /** Function for rendering node label */
    @Input()
    itemRenderer: (data: T) => string = (value: T) => `${value}`;

    /** Event is fired when selection change. */
    @Output()
    selectionChange = new EventEmitter<T[]>();

    /** Event is fired when selection change. */
    @Output()
    highlightedChange = new EventEmitter<T | null>();

    /** Two way data binding for expandAll. */
    @Output()
    expandAllChange = new EventEmitter<boolean>();

    @ContentChild(TemplateRef)
    templateRef!: TemplateRef<T>;

    getLevel = (node: FlatNode<T>) => node.level;

    isExpandable = (node: FlatNode<T>) => node.expanded;

    getChildren = (node: TreeNode<T>): TreeNode<T>[] | undefined => node.children;

    hasChild = (_: number, _nodeData: FlatNode<T>) => _nodeData.expanded;

    /** Whether hide/show a node, depends on from the filter value */
    filterNode(node: FlatNode<T>): boolean {
        if (!this.filter || this.itemRenderer(node.data).toLowerCase().indexOf(this.filter?.toLowerCase()) !== -1) {
            return false;
        }
        const descendants = this.treeControl.getDescendants(node);

        return !descendants.some(
            (descendantNode: FlatNode<T>) =>
                this.itemRenderer(descendantNode.data).toLowerCase().indexOf(this.filter?.toLowerCase()) !== -1
        );
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FlatNode<T>): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return (
            descendants.length > 0 &&
            descendants.every((child: FlatNode<T>) => {
                return this.selection.isSelected(child);
            })
        );
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    nodeSelectionToggle(node: FlatNode<T>): void {
        this.selection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);

        this.selection.isSelected(node)
            ? this.selection.select(...descendants)
            : this.selection.deselect(...descendants);

        descendants.forEach((child) => this.selection.isSelected(child));
        this.checkAllParentsSelection(node);
        this.selectionChange.emit(this._getSelectedNodes());
    }

    /** Toggle a leaf node item selection. Check all the parents to see if they changed */
    nodeLeafSelectionToggle(node: FlatNode<T>): void {
        this.selection.toggle(node);
        this.checkAllParentsSelection(node);
        this.selectionChange.emit(this._getSelectedNodes());
    }

    /** Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FlatNode<T>): void {
        let parent: FlatNode<T> | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: FlatNode<T>): void {
        const nodeSelected = this.selection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.selection.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.selection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.selection.select(node);
        }
    }

    /** Get the parent node of a node */
    getParentNode(node: FlatNode<T>): FlatNode<T> | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    highlightNode(node: FlatNode<T>) {
        if (this.highlightable && node.selectable) {
            this.highlighted = this.highlighted == node.data && this.canUnhighlight ? null : node.data;
            this.highlightedChange.emit(this.highlighted);
        }
    }

    checkIfHighlight(node: FlatNode<T>): boolean {
        return (
            isEqual(node.data, this.highlighted) ||
            (this.multiselect && node.selectable && this.selection.isSelected(node))
        );
    }

    getAriaSelected(node: FlatNode<T>): string | null {
        const selected = this.checkIfHighlight(node);
        return this.highlightable && node.selectable && selected ? 'true' : null;
    }

    getNodeTabIndex(node: TreeNode<T>) {
        if (this.highlighted && node.data === this.highlighted) {
            return 0;
        }

        if (!this.highlighted && this._data.length > 0 && node.data === this._data[0].data) {
            return 0;
        }

        return -1;
    }

    nodeKeyUp(event: KeyboardEvent, node: FlatNode<T>, nodeElement: HTMLElement) {
        const isExpanded = this.treeControl.isExpanded(node);
        const isExpandable = this.treeControl.isExpandable(node);
        event.stopPropagation();

        switch (event.code) {
            case TreeKeys.ENTER:
            case TreeKeys.SPACE:
                this.highlightNode(node);
                break;
            case TreeKeys.UP:
                /** Set focus to the previous node sibling*/
                (nodeElement.previousElementSibling as HTMLElement)?.focus();
                break;
            case TreeKeys.DOWN:
                /** Set focus to the next node sibling*/
                (nodeElement.nextElementSibling as HTMLElement)?.focus();
                break;
            case TreeKeys.RIGHT:
                /** If a node can be expandable and is already expanded then move focus to the next node sibling else just expand node */
                isExpandable && isExpanded
                    ? (nodeElement.nextElementSibling as HTMLElement)?.focus()
                    : this.treeControl.expand(node);
                break;
            case TreeKeys.LEFT:
                /** If a node can be expandable and is already expanded then collapse nodes */
                if (isExpandable && isExpanded) {
                    this.treeControl.collapse(node);
                }

                /** If a node is not expandable than move focus to the parent node */
                if (!isExpandable) {
                    const level = this.treeControl.getLevel(node);
                    this._getNodeParentElement(nodeElement, `[aria-level="${level}"]`)?.focus();
                }
                break;
            default:
                break;
        }
    }

    expandAllNodes() {
        this.treeControl?.expandAll();
    }

    collapseAllNodes() {
        this.treeControl?.collapseAll();
    }

    clearFilter() {
        this.filter = '';
    }

    expandNode(data: TreeNode<T>) {
        this._flatNodeMap.forEach((value, key) => {
            if (isEqual(data, value)) {
                this.treeControl.expand(key);
                return;
            }
        });
    }

    private _initDataSource() {
        this._treeFlattener = new MatTreeFlattener(
            this._transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<FlatNode<T>>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this._treeFlattener);
        this.dataSource.data = this._data;
        this.expandAll && this.treeControl.expandAll();
    }

    /** Transformer to convert nested node to flat node. Record the nodes in maps for later use. */
    private _transformer = (node: TreeNode<T>, level: number) => {
        const existingNode = this._nestedNodeMap.get(node);
        const flatNode = existingNode && isEqual(existingNode.data, node.data) ? existingNode : new FlatNode();
        flatNode.data = node.data;
        flatNode.level = level;
        flatNode.selectable = node.selectable ?? true;
        flatNode.expanded = !!node.children?.length;
        node.iconPrefix && (flatNode.iconPrefix = node.iconPrefix);
        node.checked && this.selection.select(...[flatNode]);
        this._flatNodeMap.set(flatNode, node);
        this._nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    private _getSelectedNodes(): T[] {
        const selected: T[] = [];
        this.selection.selected.forEach((value) => value.selectable && selected.push(value.data));
        return selected;
    }

    /** Returns the first sibling with the appropriate selector*/
    private _getNodeParentElement(elem: HTMLElement, selector: string): HTMLElement | null {
        let sibling = elem.previousElementSibling;
        /** If the sibling matches our selector, use it,
         * If not, jump to the next sibling and continue the loop */
        while (sibling) {
            if (sibling.matches(selector)) {
                return sibling as HTMLElement;
            }
            sibling = sibling.previousElementSibling;
        }

        return null;
    }

    getContextType(obj: any): T {
        return obj;
    }
}
