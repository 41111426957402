import { IPageRequest } from '../base/page.request';
import { PageResponse } from '../model/page-response.model';

export interface Page<T> {
    pageItems: T[];
    firstRowIndex: number;
    lastRowIndex: number;
    lastPageNumber: number;
    totalItems: number;
    advancedFilterMap?: Map<string, string[]>;
    filterFields?: string[];
    filterValue?: string;
    filterCaseInsensitive?: boolean;
    pageNumber: number;
    pageSize: number;
    previous?: boolean;
    previousPageFirstItem?: any;
    previousPageLastItem?: any;
    sortCaseInsensitive?: boolean;
    sortDescending?: boolean;
    sortField?: string;
}

export class PageUtil {
    public static convertToPage<T>(pageResponse: PageResponse<T>): Page<T> {
        {
            let page: Page<T> = {} as Page<T>;
            page.pageItems = pageResponse?.items ?? [];
            page.pageSize = pageResponse?.pageRequest?.pageSize ?? pageResponse?.items?.length ?? 100;
            page.pageNumber = pageResponse?.pageRequest?.pageNumber ?? 0;
            page.totalItems = pageResponse?.totalCount ?? pageResponse?.items?.length ?? 0;
            page.firstRowIndex = 0;
            page.lastRowIndex = pageResponse?.items?.length ?? 0;
            page.lastPageNumber =
                pageResponse?.totalPages ??
                (pageResponse?.totalCount && pageResponse?.pageRequest?.pageSize
                    ? Math.floor(pageResponse.totalCount / pageResponse?.pageRequest?.pageSize)
                    : 1);

            return page;
        }
    }

    public static buildPageObjectFromArray<T>(pageRequest: IPageRequest, items: T[]): Page<T> {
        let page: Page<T> = {} as Page<T>;

        const startIndex = pageRequest.pageNumber * pageRequest.pageSize;
        page.pageItems =
            pageRequest.pageNumber > 0
                ? items.slice(startIndex, startIndex + pageRequest.pageSize)
                : items.slice(0, pageRequest.pageSize) ?? [];
        page.pageSize = pageRequest?.pageSize ?? items?.length ?? 100;
        page.pageNumber = pageRequest?.pageNumber ?? 0;
        page.totalItems = items.length ?? items?.length ?? 0;
        page.firstRowIndex = 0;
        page.lastRowIndex = items?.length ?? 0;
        page.lastPageNumber = pageRequest?.pageSize ? Math.floor(items?.length / pageRequest?.pageSize) : 1;

        return page;
    }
}
