import { IEventListener } from './event.listener';

export class VCEvent {
    private name: string;
    private reference: any;
    private static EVENT_LISTENERS: Map<string, IEventListener> = new Map();
    private constructor(name: string, reference: any, triggeNow: boolean) {
        this.name = name;
        this.reference = reference;
        if (triggeNow) {
            this.trigger();
        }
    }

    public static addListener(eventListener: IEventListener): void {
        var event = this;
        VCEvent.EVENT_LISTENERS.set(eventListener.constructor.name, eventListener);
    }

    public trigger(): void {
        var event = this;
        VCEvent.EVENT_LISTENERS.forEach(function (eventListener, className) {
            eventListener.onEvent(event.name, event);
        });
    }

    /**
     * @deprecated use vcProgressSpinner instead
     */
    public static fire(name: string, reference: any): VCEvent {
        return new VCEvent(name, reference, true);
    }
}
