import { ValidationFunction } from '../components/base-field/base-field';

export class Validation {
    static validateAlpha(value: string, allowEmpty?: boolean): boolean {
        if (!allowEmpty && !value) {
            return false;
        }

        const rxAlpha = new RegExp(/^[a-zA-Z_]+$/);

        return rxAlpha.test(value);
    }

    static validateAlphanumeric(value: string, allowEmpty?: boolean) {
        if (!allowEmpty && !value) {
            return false;
        }

        const rxAlphanumeric = new RegExp(/^[a-zA-Z0-9_]+$/);

        return rxAlphanumeric.test(value);
    }

    static validateEmail(value: string, allowEmpty?: boolean): boolean {
        if (!allowEmpty && !value) {
            return false;
        }

        const rxEmail = new RegExp(/^(\w+)([-+.'][\w]+)*@(\w[-\w]*\.){1,5}([A-Za-z]){2,16}$/);

        return rxEmail.test(value);
    }

    static validateUrl(value: string, allowEmpty?: boolean): boolean {
        if (!allowEmpty && !value) {
            return false;
        }

        const rxUrl = new RegExp(
            /(((https?)|(ftp)):\/\/([-a-zа-я0-9-]+\.)+[a-zа-я0-9-]{2,3}(\/[%\-[a-zа-я0-9-]]+(\.[a-zа-я0-9-]{2,})?)*(([a-zа-я0-9-\-.?\\/+@&#;`~=%!]*)(\.[a-zа-я0-9-]{2,})?)*\/?)/i
        );

        return rxUrl.test(value);
    }

    static validatePhone(value: string): boolean {
        let valid = true;
        if (value != null && value != '') {
            const phoneDigits = value.replace(new RegExp(/[^0-9]/), '');
            if (phoneDigits.length < 10) {
                valid = false;
            }

            const rxPhone = new RegExp(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/);
            if (!rxPhone.test(value)) {
                valid = false;
            }
        }
        return valid;
    }

    // Returns true if value only contains numbers and/or these symbols . ( ) + -
    static validatePhoneWithCharacters(value: string): boolean {
        const rxPhone = new RegExp(/^[0-9.()+ -]*$/);
        return rxPhone.test(value);
    }

    /* Phone number validation function, returns standard error message locale.  Only accepts numbers and/or symbols . ( ) + - */
    static phoneNumberValidator: ValidationFunction<string> = (value: string): string => {
        return !value || value === '' || Validation.validatePhoneWithCharacters(value)
            ? ''
            : $localize`:@@PHONE_VALIDATOR.PHONE_NUMBERS_AND_SYMBOLS:Phone number should only include numbers and the following symbols: ( ) + - .`;
    };

    // Returns true if value only contains numbers and/or these symbols * # -
    static validatePhoneExtWithCharacters(value: string): boolean {
        const rxPhone = new RegExp(/^[0-9*#-]*$/);
        return rxPhone.test(value);
    }

    /* Phone extension validation function, returns standard error message locale.  Accepts only numbers and/or symbols * # - */
    static phoneExtensionValidator: ValidationFunction<string> = (value: string): string => {
        return !value || value === '' || Validation.validatePhoneExtWithCharacters(value)
            ? ''
            : $localize`:@@PHONE_VALIDATOR.PHONE_EXT_NUMBERS_AND_SYMBOLS:Extension should only include numbers and the following symbols: * # -`;
    };

    static portValidator(value: string, allowEmpty?: boolean): boolean {
        if (allowEmpty && !value) {
            return true;
        }
        const rxPort: RegExp = new RegExp(
            /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
        );

        return rxPort.test(value);
    }

    static ipValidation(value: string, allowEmpty?: boolean): boolean {
        if (allowEmpty && !value) {
            return true;
        }
        const rxPort: RegExp = new RegExp(
            /((^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$)|(^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?$))/
        );

        return rxPort.test(value);
    }
}
