<ng-template #dialogTemplate>
    <div [vcProgressSpinner]="loading">
        <div class="vc-dialog-header">
            <h2 mat-dialog-title class="headline-m">{{ title }}</h2>
            <vc-button
                *ngIf="showCloseIcon"
                class="vc-dialog-close-button"
                tabIndex="-1"
                iconName="close"
                mode="icon"
                ariaLabel="Close Dialog"
                iconColor="var(--secondary-800)"
                (trigger)="visible = false"></vc-button>
        </div>
        <mat-dialog-content class="vc-common-component-dialog-content body-m-1" [style.height]="height">
            <ng-content></ng-content>
        </mat-dialog-content>
        <mat-dialog-actions *ngIf="showActions" style="width: 100%" [align]="showActionsOnLeft ? 'start' : 'end'">
            <div class="vc-common-component-dialog-action-wrapper">
                <div class="vc-common-component-dialog-action-buttons">
                    <ng-container *ngIf="showActionsOnLeft">
                        <vc-button
                            mode="primary"
                            *ngIf="showPrimary"
                            [disabled]="primaryDisabled"
                            [label]="primaryLabel"
                            (trigger)="saveTrigger()"></vc-button>
                        <vc-button
                            mode="text"
                            *ngIf="showSecondary"
                            [disabled]="secondaryDisabled"
                            [label]="secondaryLabel"
                            (trigger)="cancelTrigger()"></vc-button>
                    </ng-container>
                    <ng-content select="[dialogActionLeft]"></ng-content>
                </div>
                <div class="vc-common-component-dialog-action-buttons">
                    <ng-content select="[dialogActionRight]"></ng-content>
                    <ng-container *ngIf="showActionsOnRight">
                        <vc-button
                            mode="text"
                            *ngIf="showSecondary"
                            [disabled]="secondaryDisabled"
                            [label]="secondaryLabel"
                            (trigger)="cancelTrigger()"></vc-button>
                        <vc-button
                            mode="primary"
                            *ngIf="showPrimary"
                            [disabled]="primaryDisabled"
                            [label]="primaryLabel"
                            (trigger)="saveTrigger()"></vc-button>
                    </ng-container>
                </div>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>
