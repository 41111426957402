import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
    selector: 'vc-notification-box',
    templateUrl: './notification-box.component.html',
    styleUrls: ['./notification-box.component.scss'],
})
export class NotificationBoxComponent implements OnDestroy {
    private _visibleChangeTimer!: any;

    /** Whether to show or notification box. */
    @Input()
    set visible(value: boolean) {
        if (this._visible != value) {
            this._visible = value;
            this.visibleChange.emit(this._visible);
            this.setTimer();
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    private _visible = false;

    /** Whether to show close button. */
    @Input()
    showClose: boolean = false;

    /** Specifies notification title. */
    @Input()
    title!: string;

    /** Specifies notification icon. */
    @Input()
    icon!: string;

    /** Specifies notification type. */
    @Input()
    type: 'error' | 'info' | 'success' | 'warning' = 'info';

    /** Specifies notification message. */
    @Input()
    message!: string;

    /** Specifies timeout to hide notification box. */
    @Input()
    timeout!: number;

    @Output()
    visibleChange = new EventEmitter<boolean>();

    close() {
        this.visible = false;
    }

    setTimer() {
        clearTimeout(this._visibleChangeTimer);
        if (this.timeout && this.timeout !== 0 && this.visible) {
            this._visibleChangeTimer = setTimeout(() => this.close(), this.timeout);
        }
    }

    ngOnDestroy(): void {
        clearTimeout(this._visibleChangeTimer);
    }
}
