import { Injectable } from '@angular/core';
import { NavigationMenu } from '../model/navigation-menu.model';
import { getPrivileges } from './privileges';

@Injectable({
    providedIn: 'root',
})
export class UrlPrivilegeUtilService {
    // Returns only visible URL privileges
    public getVisibleURLPrivileges(): NavigationMenu[] {
        return this._getPrivileges().filter((urlPrivilege: NavigationMenu) => urlPrivilege.visible);
    }

    // Returns all URL privileges regardless of visibility
    public getAllURLPrivileges(): NavigationMenu[] {
        return this._getPrivileges();
    }

    // Returns basic user urls
    public getUserBasicUrls(): string[] {
        return [
            '',
            '/',
            'dummy/route',
            'reset-password',
            'notifications-settings',
            'account',
            'account/:tab',
            'notification-history',
        ];
    }

    private _getPrivileges(): NavigationMenu[] {
        return getPrivileges();
    }
}
