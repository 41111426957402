export enum AdHocTaskPurpose {
    ADD_INFO = 'ADD_INFO',
    REMEDIATION = 'REMEDIATION',
    ASSISTANCE = 'ASSISTANCE',
    ESCALATION = 'ESCALATION',
    SCOPE_CHANGE = 'SCOPE_CHANGE',
    OTHER = 'OTHER',
}

export class AdHocTaskPurposeHelper {
    static getAdHocTaskPurposeLocale(purpose: AdHocTaskPurpose): string {
        switch (purpose) {
            case AdHocTaskPurpose.ADD_INFO:
                return $localize`:@@CORE.AD_HOC_TASK.PURPOSE.ADDITIONAL_INFO:Additional Info`;
            case AdHocTaskPurpose.REMEDIATION:
                return $localize`:@@CORE.AD_HOC_TASK.PURPOSE.REMEDIATION:Remediation`;
            case AdHocTaskPurpose.ASSISTANCE:
                return $localize`:@@CORE.AD_HOC_TASK.PURPOSE.ASSISTANCE:Assistance`;
            case AdHocTaskPurpose.ESCALATION:
                return $localize`:@@CORE.AD_HOC_TASK.PURPOSE.ESCALATION:Escalation`;
            case AdHocTaskPurpose.SCOPE_CHANGE:
                return $localize`:@@CORE.AD_HOC_TASK.PURPOSE.SCOPE_CHANGE:Scope Change`;
            case AdHocTaskPurpose.OTHER:
                return $localize`:@@CORE.AD_HOC_TASK.PURPOSE.OTHER:Other`;
            default:
                return purpose;
        }
    }
}
