export abstract class ServiceUrls {
    public static VC_USER_BASE_URL: string = `/user-service/v1`;
    public static VC_USER_PREFERENCE_BASE_URL: string = `/user-preference-service/v1`;
    public static VC_USER_REGISTRATION_BASE_URL: string = `/user-registration-service/v1`;
    public static VC_NOTIFICATION_BASE_URL: string = `/notification-service/v1`;
    public static VC_ORG_BASE_URL: string = `/org-service/v1`;
    public static VC_ASSET_BASE_URL: string = `/asset-service/v1`;
    public static VC_FINDING_BASE_URL: string = `/finding-service/v1`;
    public static VC_EVENT_BASE_URL: string = `/event-service/v1`;
    public static VC_REFERENCE_BASE_URL: string = `/reference-data-service/v1`;
    public static VC_ASSESSMENT_BASE_URL: string = `/assessment-service/v1`;
    public static VC_FILE_LOCKER_BASE_URL: string = `/file-locker-service/v1`;
    public static VC_SCAN_BASE_URL: string = `/scan-service/v1`;
    public static VC_UTM_BASE_URL: string = `/utm-service/v1`;
    public static VC_ENDPOINT_CONTROL_BASE_URL: string = `/endpoint-control-service/v1`;
    public static VC_ENDPOINT_INSTALLER_BASE_URL: string = `/endpoint-activation-service/v1`;
    public static VC_DISPUTE_BASE_URL: string = `/dispute-service/v1`;
    public static VC_WALLET_BASE_URL: string = `/wallet-service/v1`;
    public static VC_STORE_BASE_URL: string = `/store-service/v1`;
    public static VC_PAYMENT_BASE_URL: string = `/payment-service/v1`;
    public static VC_QUESTIONNAIRE_BASE_URL: string = `/questionnaire-service/v1`;
    public static VC_VULNDB_BASE_URL: string = `/vulndb-service/v1`;
    public static VC_COMMENT_BASE_URL: string = `/comments-service/v1`;
    public static VC_MSS_COMMON_SERVICE_BASE_URL: string = `/mss-common-service/v1`;
    public static VC_TICKETS_BASE_URL: string = `/ticket-service/v1`;
    public static VC_TICKET_FACADE_BASE_URL: string = `/mss-ticket-facade-service/v1`;
    public static VC_EVENT_ARCHIVAL_RETRIEVAL_BASE_URL: string = `/event-archival-retrieval-service/v1`;
    public static VC_POLICY_SERVICE_BASE_URL: string = `/policy-service/v1`;
    public static VC_MSS_UI_SERVICE_BASE_URL: string = `/mss-ui-service/v1`;
}
