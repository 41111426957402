import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDGenerator } from '../../common/id-generator';

export declare type ThemeMode = 'dark' | 'light';

@Component({
    selector: 'vc-theme-toggle',
    templateUrl: './theme-toggle.component.html',
    styleUrls: ['./theme-toggle.component.scss'],
})
export class ThemeToggleComponent {
    labelId: string = `toggle-label-${IDGenerator.generateID()}`;
    checked: boolean = false;

    @Input()
    set mode(value: ThemeMode) {
        if (value !== this._mode) {
            this._mode = value;
            this.checked = this._mode === 'dark';
        }
    }

    get mode(): ThemeMode {
        return this._mode;
    }

    private _mode: ThemeMode = 'light';

    @Output()
    modeChanged = new EventEmitter<ThemeMode>();
}
