import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IDGenerator } from '../../../common/id-generator';

@Component({
    selector: 'vc-accordion-panel',
    templateUrl: './accordion-panel.component.html',
    styleUrls: ['./accordion-panel.component.scss'],
})
export class AccordionPanelComponent {
    controlId = `panel-${IDGenerator.generateID()}`;
    buttonId = `accordion-button-${IDGenerator.generateID()}`;

    /** Whether panel is expanded/collapsed. */
    @HostBinding('class.vc-accordion-expanded')
    @Input()
    expanded: boolean = false;

    /** Accordion header tittle */
    @Input()
    title!: string;

    /** Accordion header badge */
    @Input()
    badge!: string;

    /** Event is fired when panel expands */
    @Output()
    expandedChange = new EventEmitter<boolean>();

    public toggleExpand() {
        this.expanded = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }
}
