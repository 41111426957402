import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

/** Example of usage
 * <vc-input [(value)]="searchValue"></vc-input>
 *
 * <ul>
 *     <li *ngFor="let item of listOptions | search : 'name' : searchValue;"></li>
 * </ul>
 *
 * Search pipe accepts three arguments:
 * 1. list -> list of values
 * 2. key -> value key for searching
 * 3. searchValue -> search value
 * */

@Pipe({
    name: 'search',
    standalone: true,
})
export class SearchPipe<T> implements PipeTransform {
    public transform(list: T[], key: string, searchValue: string): T[] {
        if (!searchValue || searchValue === '' || searchValue.trim() === '') {
            return list;
        } else {
            return list.filter((item: T) => {
                const value: string = key && key.trim() !== '' ? get(item, key) : item;
                if (value) {
                    let regExp = new RegExp('\\b' + searchValue, 'gi');
                    return regExp.test(value);
                } else {
                    return false;
                }
            });
        }
    }
}
