export enum Language {
    EN = 'en',
    FR = 'fr',
    ES = 'es',
}

export class LanguageHelper {
    static getLanguageLocale(language?: Language): string {
        switch (language) {
            case Language.EN:
                return $localize`:@@LANGUAGE.ENGLISH:English`;
            case Language.FR:
                return $localize`:@@LANGUAGE.FRENCH:French`;
            case Language.ES:
                return $localize`:@@LANGUAGE.SPANISH:Spanish`;
            default:
                return '';
        }
    }
}
