/*
 * _READ: Backend Components
 * _VIEW: UI Components
 */
export abstract class AuthorityUtil {
    public static ROLE_ORG_ADMIN: string = 'ROLE_ORG_ADMIN';
    public static ROLE_TEMP_MFA_ROLE: string = 'TEMP_MFA_ROLE';

    public static PERMISSION_ASSET_CREATE: string = 'ASSET_CREATE';
    public static PERMISSION_ASSET_DELETE: string = 'ASSET_DELETE';
    public static PERMISSION_ASSET_UPDATE: string = 'ASSET_UPDATE';
    public static PERMISSION_ASSETS_ADD: string = 'ASSETS_ADD';
    public static PERMISSION_ASSETS_MODIFY: string = 'ASSETS_MODIFY';
    public static PERMISSION_ASSETS_VIEW: string = 'ASSETS_VIEW';
    public static PERMISSION_FINDING_CREATE: string = 'FINDING_CREATE';
    public static PERMISSION_FINDING_READ: string = 'FINDING_READ';
    public static PERMISSION_FINDING_UPDATE: string = 'FINDING_UPDATE';
    public static PERMISSION_FINDING_DELETE: string = 'FINDING_DELETE';
    public static PERMISSION_ASSESSMENT_UPDATE: string = 'ASSESSMENT_UPDATE';
    public static PERMISSION_ASSESSMENT_DELETE: string = 'ASSESSMENT_DELETE';
    public static PERMISSION_ASSESSMENT_CREATE: string = 'ASSESSMENT_CREATE';
    public static PERMISSION_DEVICE_MANAGEMENT_MERAKI_UI_READ: string = 'DEVICE_MANAGEMENT_MERAKI_UI_READ';
    public static PERMISSION_ROLE_VIKING_ASSESSOR: string = 'ROLE_VIKING_ASSESSOR';
    public static PERMISSION_ROLE_VIKING_ASSESSOR_ASSOCIATE: string = 'ROLE_VIKING_ASSESSOR_ASSOCIATE';
    public static PERMISSION_ROLE_VIKING_ASSESSOR_MANAGER: string = 'ROLE_VIKING_ASSESSOR_MANAGER';
    public static PERMISSION_ROLE_ASSESSMENT_USER: string = 'ROLE_ASSESSMENT_USER';

    public static PERMISSION_TAG_INTERNAL_READ: string = 'ITAG_READ';
    public static PERMISSION_TAG_INTERNAL_ASSIGN: string = 'ITAG_ASSIGN';
    public static PERMISSION_TAG_EXTERNAL_READ: string = 'XTAG_READ';
    public static PERMISSION_TAG_EXTERNAL_ASSIGN: string = 'XTAG_ASSIGN';
    public static PERMISSION_TAG_EXTERNAL_CREATE: string = 'XTAG_CREATE';
    public static PERMISSION_TAG_PRIVATE_READ: string = 'PTAG_READ';
    public static PERMISSION_TAG_PRIVATE_ASSIGN: string = 'PTAG_ASSIGN';
    public static PERMISSION_TAG_PRIVATE_CREATE: string = 'PTAG_CREATE';
    public static PERMISSION_TAG_PRIVATE_UPDATE: string = 'PTAG_UPDATE';

    public static PERMISSION_ASSESSMENT_ADMIN: string = 'ASSESSMENT_ADMIN';
    public static PERMISSION_WRITE: string = 'WRITE';
    public static PERMISSION_ASSESSMENT_CHANGE_STATUS: string = 'ASSESSMENT_CHANGE_STATUS';
    public static PERMISSION_ASSESSMENT_ADD_REMOVE_ITEMS: string = 'ASSESSMENT_ADD_REMOVE_ITEMS';
    public static PERMISSION_ASSESSMENT_ASSIGN_CUSTOMER: string = 'ASSESSMENT_ASSIGN_CUSTOMER';
    public static PERMISSION_ASSESSMENT_VIEW_DETAILS: string = 'ASSESSMENT_VIEW_DETAILS';
    public static PERMISSION_ASSESSMENT_VIEW_DASHBOARD: string = 'ASSESSMENT_VIEW_DASHBOARD';
    public static PERMISSION_ASSESSMENT_VIEW_MILESTONES: string = 'ASSESSMENT_VIEW_MILESTONES';
    public static PERMISSION_ASSESSMENT_VIEW_DOCUMENTS: string = 'ASSESSMENT_VIEW_DOCUMENTS';
    public static PERMISSION_ASSESSMENT_VIEW_ASSETS: string = 'ASSESSMENT_VIEW_ASSETS';
    public static PERMISSION_ASSESSMENT_VIEW_FINDINGS: string = 'ASSESSMENT_VIEW_FINDINGS';
    public static PERMISSION_ASSESSMENT_VIEW_ASSESSORS: string = 'ASSESSMENT_VIEW_ASSESSORS';
    public static PERMISSION_ASSESSMENT_VIEW_CLIENTS: string = 'ASSESSMENT_VIEW_CLIENTS';
    public static PERMISSION_ASSESSMENT_VIEW_CONTROLS: string = 'ASSESSMENT_VIEW_CONTROLS';
    public static PERMISSION_ASSESSMENT_VIEW_COMPONENTS: string = 'ASSESSMENT_VIEW_COMPONENTS';
    public static PERMISSION_ASSESSMENT_VIEW_REFERENCE_TASKS: string = 'ASSESSMENT_VIEW_REFERENCE_TASKS';
    public static PERMISSION_ASSESSMENT_VIEW_HISTORY: string = 'ASSESSMENT_VIEW_HISTORY';
    public static PERMISSION_ASSESSOR_PRIVILEGE: string = 'ASSESSOR_PRIVILEGE';
    public static PERMISSION_VIEW_DOCUMENTS: string = 'VIEW_DOCUMENTS';

    public static PERMISSION_TASK_ASSIGN_OWNER: string = 'TASK_ASSIGN_OWNER';
    public static PERMISSION_TASK_CHANGE_STATUS: string = 'TASK_CHANGE_STATUS';
    public static PERMISSION_TASK_CREATE: string = 'TASK_CREATE';

    public static PERMISSION_ROLE_IMPERSONATION: string = 'ROLE_IMPERSONATION';
    public static PERMISSION_ROLE_IMPERSONATION_DISALLOW: string = 'ROLE_IMPERSONATION_DISALLOW';
    public static PERMISSION_ROLE_PREVIOUS_IMPERSONATOR: string = 'ROLE_PREVIOUS_IMPERSONATOR';

    public static PERMISSION_ORG_CREATE: string = 'ORG_CREATE';
    public static PERMISSION_ORG_UPDATE: string = 'ORG_UPDATE';
    public static PERMISSION_ORG_DELETE: string = 'ORG_DELETE';

    public static PERMISSION_USER_READ: string = 'USER_READ';
    public static PERMISSION_USER_CREATE: string = 'USER_CREATE';
    public static PERMISSION_USER_UPDATE: string = 'USER_UPDATE';
    public static PERMISSION_USER_DELETE: string = 'USER_DELETE';
    public static PERMISSION_USER_ADMIN_UPDATE_STATUS: string = 'USER_ADMIN_UPDATE_STATUS';
    public static PERMISSION_SCAN_NOW: string = 'SCAN_NOW';

    public static PERMISSION_ROLE_SCAN_USER: string = 'ROLE_SCAN_USER';
    public static PERMISSION_ROLE_IVS_SCAN_USER: string = 'ROLE_IVS_SCAN_USER';
    public static PERMISSION_ROLE_EVS_SCAN_USER: string = 'ROLE_EVS_SCAN_USER';
    public static PERMISSION_ROLE_PCI_SCAN_USER: string = 'ROLE_PCI_SCAN_USER';

    public static PERMISSION_DISPUTE_CREATE: string = 'DISPUTE_CREATE';
    public static PERMISSION_DISPUTE_READ: string = 'DISPUTE_READ';

    public static PERMISSION_ROLE_MARKETPLACE_ADMIN: string = 'MARKETPLACE_ADMIN';
    public static PERMISSION_ROLE_MARKETPLACE_INTERNAL_READONLY: string = 'MARKETPLACE_INTERNAL_READONLY';
    public static PERMISSION_ROLE_MARKETPLACE_CUSTOMER: string = 'MARKETPLACE_CUSTOMER';
    public static PERMISSION_ROLE_MARKETPLACE_DELIVERY: string = 'MARKETPLACE_DELIVERY';

    public static PERMISSION_ROLE_PAYMENT_GATEWAY_ADMIN: string = 'ROLE_PAYMENT_GATEWAY_ADMIN';
    public static PERMISSION_ROLE_PAYMENT_GATEWAY_INTERNAL_READONLY: string = 'ROLE_PAYMENT_GATEWAY_INTERNAL_READONLY';
    public static PERMISSION_ROLE_PAYMENT_GATEWAY_USER: string = 'ROLE_PAYMENT_GATEWAY_USER';

    public static PERMISSION_PAYMENT_GATEWAY_INTERNAL: string = 'PAYMENT_GATEWAY_INTERNAL';
    public static PERMISSION_PAYMENT_GATEWAY_READ: string = 'PAYMENT_GATEWAY_READ';
    public static PERMISSION_PAYMENT_GATEWAY_PAY: string = 'PAYMENT_GATEWAY_PAY';
    public static PERMISSION_PAYMENT_GATEWAY_BILL: string = 'PAYMENT_GATEWAY_BILL';

    public static PERMISSION_ROLE_WALLET_ADMIN: string = 'ROLE_WALLET_ADMIN';
    public static PERMISSION_ROLE_WALLET_INTERNAL_READONLY: string = 'ROLE_WALLET_INTERNAL_READONLY';
    public static PERMISSION_ROLE_WALLET_USER: string = 'ROLE_WALLET_USER';

    public static PERMISSION_WALLET_ADMIN: string = 'WALLET_ADMIN';
    public static PERMISSION_WALLET_READ: string = 'WALLET_READ';
    public static PERMISSION_WALLET_INTERNAL: string = 'WALLET_INTERNAL';
    public static PERMISSION_WALLET_UPDATE: string = 'WALLET_UPDATE';
    public static PERMISSION_WALLET_PURCHASE: string = 'WALLET_PURCHASE';
    public static PERMISSION_WALLET_DISCOVER: string = 'WALLET_DISCOVER';
    public static PERMISSION_WALLET_REPORT: string = 'WALLET_REPORT';
    public static PERMISSION_WALLET_TOP_UP: string = 'WALLET_TOP_UP';

    public static PERMISSION_ROLE_STORE_ADMIN: string = 'ROLE_STORE_ADMIN';
    public static PERMISSION_ROLE_STORE_INTERNAL_READONLY: string = 'ROLE_STORE_INTERNAL_READONLY';
    public static PERMISSION_ROLE_STORE_USER: string = 'ROLE_STORE_USER';
    public static PERMISSION_ROLE_STORE_INTERNAL_DELIVERY: string = 'ROLE_STORE_INTERNAL_DELIVERY';

    public static PERMISSION_STORE_ADMIN: string = 'STORE_ADMIN';
    public static PERMISSION_STORE_READ: string = 'STORE_READ';
    public static PERMISSION_STORE_PURCHASE: string = 'STORE_PURCHASE';
    public static PERMISSION_STORE_INTERNAL: string = 'STORE_INTERNAL';
    public static PERMISSION_STORE_REFUND: string = 'STORE_REFUND';
    public static PERMISSION_STORE_REQUEST_SCHEDULING: string = 'STORE_REQUEST_SCHEDULING';
    public static PERMISSION_STORE_ORDER_DELIVERY: string = 'STORE_ORDER_DELIVERY';

    public static PERMISSION_ROLE_PRODUCT_FAMILY_ADMIN: string = 'ROLE_PRODUCT_FAMILY_ADMIN';
    public static PERMISSION_ROLE_PRODUCT_FAMILY_INTERNAL_READONLY: string = 'ROLE_PRODUCT_FAMILY_INTERNAL_READONLY';
    public static PERMISSION_ROLE_PRODUCT_FAMILY_DELIVERY: string = 'ROLE_PRODUCT_FAMILY_DELIVERY';

    public static PERMISSION_PRODUCT_FAMILY_ADMIN: string = 'PRODUCT_FAMILY_ADMIN';
    public static PERMISSION_PRODUCT_FAMILY_READ: string = 'PRODUCT_FAMILY_READ';
    public static PERMISSION_PRODUCT_FAMILY_INTERNAL: string = 'PRODUCT_FAMILY_INTERNAL';

    public static PERMISSION_ADMIN_ORG_ENDPOINT_READ: string = 'ADMIN_ORG_ENDPOINT_READ';
    public static PERMISSION_ADMIN_ORG_ENDPOINT_UPDATE: string = 'ADMIN_ORG_ENDPOINT_UPDATE';
    public static PERMISSION_ENDPOINT_CONFIG_READ: string = 'ENDPOINT_CONFIG_READ';
    public static PERMISSION_ENDPOINT_CONFIG_UPDATE = 'ENDPOINT_CONFIG_UPDATE';
    public static PERMISSION_ENDPOINT_UPDATE: string = 'ENDPOINT_UPDATE';

    public static PERMISSION_ROLE_ENDPOINT_ADMIN: string = 'ROLE_ENDPOINT_ADMIN';
    public static PERMISSION_ENDPOINT_FINDING_UI_READ: string = 'ENDPOINT_FINDING_UI_READ';

    public static PERMISSION_USER_ROLE_UPDATE: string = 'USER_ROLE_UPDATE';
    public static PERMISSION_USER_PRIVILEGE_UPDATE: string = 'USER_PRIVILEGE_UPDATE';

    public static PERMISSION_ROLE_QUESTIONNAIRE_INTERNAL_READONLY: string = 'ROLE_QUESTIONNAIRE_INTERNAL_READONLY';

    public static PERMISSION_QUESTIONNAIRE_ADMIN: string = 'QUESTIONNAIRE_ADMIN';
    public static PERMISSION_QUESTIONNAIRE_INTERNAL: string = 'QUESTIONNAIRE_INTERNAL';
    public static PERMISSION_QUESTIONNAIRE_READ: string = 'QUESTIONNAIRE_READ';

    // MSS
    public static PERMISSION_MSS_TICKET_READ: string = 'MSS_TICKET_READ';
    public static PERMISSION_MSS_BETA_USER: string = 'MSS_BETA_USER';
    public static PERMISSION_MSS_DASHBOARD_VIEW: string = 'MSS_DASHBOARD_VIEW';
    public static PERMISSION_MSS_MANAGED_CONNECTIVITY_VIEW: string = 'MSS_MANAGED_CONNECTIVITY_VIEW';
    public static PERMISSION_MSS_SITE_AVAILABILITY_VIEW: string = 'MSS_SITE_AVAILABILITY_VIEW';
    public static PERMISSION_MSS_SITE_VIEW: string = 'MSS_SITE_VIEW';
    public static PERMISSION_MSS_CONNECTIVITY_VIEW: string = 'MSS_CONNECTIVITY_VIEW';
    public static PERMISSION_MSS_TICKETS_VIEW: string = 'MSS_TICKETS_VIEW';
    public static PERMISSION_MSS_THREATS_VIEW: string = 'MSS_THREATS_VIEW';
    public static PERMISSION_MSS_FIREWALL_STATE_CHANGE_VIEW: string = 'MSS_FIREWALL_STATE_CHANGE_VIEW';
    public static PERMISSION_MSS_LMS_USER_VIEW: string = 'MSS_LMS_USER_VIEW';
    public static PERMISSION_ROLE_MSS_PM_ADMIN: string = 'ROLE_MSS_PM_ADMIN';

    // Testing
    public static PERMISSION_SQL_TESTER = 'ROLE_SQL_TESTER';
}
