<vc-dialog
    [visible]="visible"
    [title]="dialogTitle"
    primaryLabel="Next"
    i18n-primaryLabel="@@CORE.BUTTON.NEXT"
    secondaryLabel="Cancel"
    i18n-secondaryLabel="@@CORE.BUTTON.CANCEL"
    [width]="'430px'"
    [closeOnPrimary]="false"
    (visibleChange)="visibleChange.emit($event)"
    (primaryTrigger)="nextStep()">
    <div class="vc-enable-mfa-dialog-wrapper body-m-1">
        <vc-input
            i18n-label="@@ACCOUNT.ENABLE_MFA_VERIFICATION_CODE"
            label="Verification Code"
            [(value)]="verificationCode"
            [required]="true"></vc-input>
        <span i18n="@@ACCOUNT.DISABLE_MFA_ENTER_CODE">
            To disable MFA, please enter the current authenticator code and click next.
        </span>
    </div>
</vc-dialog>
