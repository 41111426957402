<vc-input
    #inputComponent
    class="vc-password-input-with-hint"
    [(value)]="value"
    [description]="description"
    [type]="type"
    [label]="label"
    [placeholder]="placeholder"
    [prefixIcon]="prefixIcon"
    [validateOnValueChange]="validateOnValueChange"
    [suffixButtonIcon]="showVisibleButton ? suffixButtonIcon : ''"
    [required]="required"
    [disabled]="disabled"
    [readonly]="readonly"
    [showClear]="showClear"
    [minLength]="minLength"
    [maxLength]="maxLength"
    [autocomplete]="autocomplete"
    [validationFunction]="customValidation ?? validatePassword"
    (suffixButtonTrigger)="toggleVisibility()"
    (focusChange)="focusChange.emit()"
    (blurChange)="blurChange.emit()"></vc-input>
