import { Pipe, PipeTransform } from '@angular/core';
import { IProductFamily, IProductLine } from '../model/pfpl.model';

@Pipe({
  name: 'vcPFPLFullName',
  pure: true,
})
export class VCPFPLFullNamePipe implements PipeTransform {
  transform(item?: IProductFamily | IProductLine): string {
    return item ? `${item.acronym} - ${item.name}` : '';
  }
}
