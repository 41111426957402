import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ColumnDirective } from './column/column.directive';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PageRequest } from '../../interfaces/page-request';
import { Page } from '../../interfaces/page';
import { get, isEqual } from 'lodash-es';
import { ListComponent } from '../list/list.component';

/** Example of table usage
 <vc-table name="Search Scans"
     [showTotal]="true"
     [multiSelect]="true"
     [showAdd]="true"
     [data]="dataSource"
     (add)="addActionTrigger()">
     <ng-template vcColumn
         field="position"
         header="Position"
         let-data>
         {{ data?.position }}
     </ng-template>
     <ng-template vcColumn
         field="name"
         header="Name"
         let-data>
         {{ data?.name }}
     </ng-template>
     <ng-template vcColumn
         field="weight"
         header="Weight"
         [sortable]="true"
         let-data>
        {{ data?.weight }}
     </ng-template>
     <ng-template vcColumn
         field="symbol"
         header="Symbol"
         let-data>
         {{ data?.symbol }}
     </ng-template>
 </vc-table>

 Table with expanded row:
 <vc-table name="Search Scans"
     [data]="dataSource"
     [multiselect]="false"
     [highlightable]="true"
     [showRowExpandButton]="true"
     [expandedRowTemplate]="expandedRowTemplate">
     <ng-template vcColumn field="position" header="Position" let-data>
        {{ data?.position }}
     </ng-template>
     <ng-template vcColumn field="name" header="Name" let-data>
        {{ data?.name }}
     </ng-template>
     <ng-template vcColumn field="weight" header="Weight" [sortable]="true" let-data>
        {{ data?.weight }}
     </ng-template>
     <ng-template vcColumn field="symbol" header="Symbol" let-data>
        {{ data?.symbol }}
     </ng-template>
 </vc-table>
 <ng-template #expandedRowTemplate let-data>
    <div class="expanded-row-content">
         <div>Position: {{ data.position }}</div>
         <div>Name: {{ data.name }}</div>
         <div>Weight: {{ data.weight }}</div>
         <div>Symbol: {{ data.symbol }}</div>
    </div>
 </ng-template>

 Example usage table with filter
 <vc-table #tableComponent
     [data]="users"
     [showColumnSelector]="false"
     [showReloadAction]="true"
     [showTotal]="true"
     [showFilterRowAction]="true"
     [highlightable]="true"
     [showPagination]="false"
     [globalFilterFields]="['firstName', 'lastName', 'userKey.username']"
     (highlightChange)="selectedUserChange($event)"
     (reload)="reload.emit()">
     <ng-template
         vcColumn
         header="Name"
         field="lastName"
         [sortable]="true"
         [filterTemplate]="nameFilterTemplate"
         let-user>
         {{ user?.lastName }}
     </ng-template>
     <ng-template
         vcColumn
         header="Date Added"
         field="dateAddedFilterTemplate"
         [filterTemplate]="idFilterTemplate"
         let-user>
         {{ user?.dateAdded ?? '' }}
     </ng-template>
 </vc-table>
 <ng-template #nameFilterTemplate let-column>
    <vc-input [label]="'Filter '+ column.header"
        suffixIcon="filter_alt"
        (valueChange)="searchField(column.field, $event)"></vc-input>
 </ng-template>
 <ng-template #dateAddedFilterTemplate>
    <vc-date-picker-range label="Filter Date Range"></vc-date-picker-range>
 </ng-template>

 TS file:
 searchField(field: string, value: string) {
        console.log(`${field}, ${value}`);
    }
 */

@Component({
    selector: 'vc-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements AfterViewInit, OnDestroy {
    static ACTION_FIELDS = ['action', 'actions'];
    private _subscription: Subscription = new Subscription();
    dataSource!: MatTableDataSource<T>;

    columnsList: ColumnDirective[] = [];
    columnsOptions: ColumnDirective[] = [];
    visibleColumnList: ColumnDirective[] = [];
    previousSelectedColumns: ColumnDirective[] = [];
    visibleColumnsFields: string[] = [];
    filterColumns: string[] = [];
    selection: SelectionModel<T> = new SelectionModel<T>(true, []);
    exportDialogVisible = false;
    pageRequest!: PageRequest;

    selectColumnID: string = 'select';
    filterSelectColumnID: string = 'filter_select';
    expandColumnID: string = 'expand';

    pageIndex: number = 0;
    totalCount!: number;
    sortDescending?: boolean;
    sortField?: string;

    expandedData!: T | null;

    private _pageData: boolean = false;

    /** Filter value change timer */
    private _valueChangeTimer!: any;
    private _valueChangeInterval: number = 500;

    set highlightedRow(value: T | null) {
        this._highlightedRow = value;
        this.highlightChange.emit(value);
    }

    get highlightedRow(): T | null {
        return this._highlightedRow;
    }

    private _highlightedRow!: T | null;

    /** List of table data with generic type */
    @Input()
    set data(value: T[]) {
        this._data = value;
        this.totalCount = this._data?.length ?? 0;
        this.dataSource = new MatTableDataSource<T>(this._data);
        this._setSorting();
        !this._pageData && this._applyFilter();
    }

    get data(): T[] {
        return this._data;
    }

    private _data: T[] = [];

    @Input()
    set page(value: Page<T> | null) {
        this._page = value;
        this.pageSize = this._page?.pageSize ?? 100;
        this.data = value?.pageItems ?? [];
        this._pageData = true;
        this.totalCount = this._page?.totalItems ?? 0;
        this.dataSource = new MatTableDataSource<T>(this._data);
        this.pageIndex = this._page?.pageNumber ? this._page.pageNumber : 0;
    }

    get page(): Page<T> | null {
        return this._page;
    }

    private _page!: Page<T> | null;

    /** Table header title */
    @Input()
    name: string = '';

    /** The ids of the elements that describe the table */
    @Input()
    describedBy!: string;

    /** Whether to show checkboxes for rows selection */
    @Input()
    multiselect: boolean = false;

    /** Whether to highlight single row */
    @Input()
    highlightable: boolean = false;

    /** Whether to show hover effect for rows */
    @Input()
    rowHover: boolean = true;

    /** Whether to truncate column text if it's too long and show ellipsis */
    @Input()
    truncateColumn: boolean = false;

    /** Whether to show total number of table records */
    @Input()
    showTotal: boolean = false;

    /** Whether to show pagination panel */
    @Input()
    showPagination: boolean = true;

    /** Whether to show spinner on the table */
    @Input()
    loading: boolean = false;

    /** Number of rows to display per page. */
    @Input()
    pageSize: number = 100;

    /** The set of provided page size options to display to the user. */
    @Input()
    pageSizeOptions: number[] = [10, 25, 50, 100];

    /** The scroll height for table body */
    @Input()
    scrollHeight!: string;

    /** ACTION TOOLBAR */
    /** Whether to show table actions toolbar (i.e. show details, edit, delete, filter input, reload and custom actions) */
    @Input()
    showToolbar: boolean = true;

    /** Whether to show action button in table toolbar for user to show/hide filter row located below column header */
    @Input()
    showFilterRowAction: boolean = false;

    /** Whether filter row stick to the top after scrolling */
    @Input()
    stickyFilterRow: boolean = false;

    /** Whether to show table filter row and filter icon inside toolbar below header. */
    @Input()
    set showFilterRow(value: boolean) {
        this._showFilterRow = value;
        this.showFilterRowChange.emit(this._showFilterRow);
    }

    get showFilterRow(): boolean {
        return this._showFilterRow;
    }

    private _showFilterRow: boolean = false;

    /** Whether to show add button */
    @Input()
    showAddAction: boolean = false;

    /** Whether to disable add button */
    @Input()
    disableAddAction: boolean = false;

    /** Whether to show details button */
    @Input()
    showDetailsAction: boolean = false;

    /** Whether to disable details button */
    @Input()
    disableDetailsAction: boolean = false;

    /** Whether to show reload button */
    @Input()
    showReloadAction: boolean = false;

    /** Whether to show edit button */
    @Input()
    showEditAction: boolean = false;

    /** Whether to disable edit button */
    @Input()
    disableEditAction: boolean = false;

    /** Whether to show delete button */
    @Input()
    showDeleteAction: boolean = false;

    /** Whether to disable delete button */
    @Input()
    disableDeleteAction: boolean = false;

    /** Whether to show clear filter action button */
    @Input()
    showClearFilterAction: boolean = false;

    /** Whether to show filter input */
    @Input()
    showFilter: boolean = true;

    /** Value for search filter */
    @Input()
    filterValue: string = '';

    /** Whether to show filter input */
    @Input()
    showFilterClearButton: boolean = true;

    /** Whether the search filter should stretch to fill available space horizontally */
    @Input()
    stretchFilter = false;

    /** Whether to show row expand button */
    @Input()
    showRowExpandButton: boolean = false;

    /** Whether to show column selector */
    @Input()
    showColumnSelector: boolean = true;

    /** Specifies filter label */
    @Input()
    filterLabel: string = $localize`:@@CORE.BUTTON.SEARCH:Search`;

    /** Specifies filter placeholder */
    @Input()
    filterPlaceholder!: string;
    /** End ACTION BAR */

    /** The field of the most recently sorted column */
    @Input()
    activeSortField!: string;

    /** The sort direction of the currently sorted column */
    @Input()
    activeSortDirection!: 'asc' | 'desc' | '';

    /** Expanded row template. */
    @Input()
    expandedRowTemplate!: TemplateRef<T>;

    /** An array of fields as string to use in global filtering. */
    @Input()
    globalFilterFields: string[] = [];

    /** Function that returns query parameters for export request */
    @Input()
    exportFunction?: (row: any, columnField: string, columnValue: string) => string;

    @Input()
    filterFunction!: (data: T, filter: string) => boolean;

    /** No Data template renderer. */
    @Input()
    noDataTemplate!: TemplateRef<T>;

    /** Event to load table data */
    @Output()
    loadData = new EventEmitter<PageRequest>();

    /** Event to reload table data */
    @Output()
    reload = new EventEmitter<PageRequest>();

    /** Event is fired when changing selection in table */
    @Output()
    selectionChange = new EventEmitter<T[]>();

    /** Event is fired when changing selection in table */
    @Output()
    highlightChange = new EventEmitter<T | null>();

    /** Event is fired when click over add button */
    @Output()
    add = new EventEmitter();

    /** Event is fired when click over edit button */
    @Output()
    edit = new EventEmitter<T>();

    /** Event is fired when click over edit button */
    @Output()
    delete = new EventEmitter<T[]>();

    /** Event is fired when click over edit button */
    @Output()
    showDetails = new EventEmitter<T>();

    /** Event is fired when Clear filters action button is clicked */
    @Output()
    clearFilters = new EventEmitter<T>();

    /** Event is fired when double click over row */
    @Output()
    doubleClick = new EventEmitter<T>();

    /** Event is fired when row expands */
    @Output()
    expandRow = new EventEmitter<T | null>();

    @Output()
    showFilterRowChange = new EventEmitter<boolean>();

    @Output()
    visibleColumnsChange = new EventEmitter<ColumnDirective[]>();

    @Output()
    columnSizeChange = new EventEmitter<ColumnDirective>();

    @ContentChildren(ColumnDirective)
    set columns(value: QueryList<ColumnDirective>) {
        if (!isEqual(this._columns, value)) {
            this._columns = value;
            this.columnsList = this._columns.toArray();
            this._initColumnOptions();
            this._setVisibleColumnsField();
            this._setVisibleColumns();
        }
    }

    get columns(): QueryList<ColumnDirective> {
        return this._columns;
    }

    private _columns!: QueryList<ColumnDirective>;

    set showColumnSelectorDialog(value: boolean) {
        if (this._showColumnSelectorDialog !== value) {
            this._showColumnSelectorDialog = value;
            !this._showColumnSelectorDialog && this.listComponent.clearSearch();
            if (this._showColumnSelectorDialog) {
                // Clone deep
                this.visibleColumnList = this.previousSelectedColumns.map((value: ColumnDirective) => value);
            }
        }
    }

    get showColumnSelectorDialog(): boolean {
        return this._showColumnSelectorDialog;
    }

    private _showColumnSelectorDialog: boolean = false;
    @ViewChild(MatSort)
    sort!: MatSort;

    @ViewChild('listComponent')
    listComponent!: ListComponent<ColumnDirective>;

    @ViewChild('tableContainer')
    tableContainer!: ElementRef;

    /** Whether the number of selected elements matches the total number of rows. */
    get isAllSelected() {
        return this.selection?.selected?.length === this.dataSource.filteredData?.length;
    }

    get totalItemsDisplay(): number {
        return this._pageData ? (this._page?.totalItems ?? 0) : (this.dataSource.filteredData.length ?? 0);
    }

    get selectedRowsExists(): boolean {
        return this.selection?.selected?.length > 0 || this.highlightedRow != null;
    }

    get selectedRowsData(): T[] {
        return this.selection?.selected.length > 0
            ? this.selection.selected
            : this.highlightedRow
              ? [this.highlightedRow]
              : [];
    }

    constructor(
        private _liveAnnouncer: LiveAnnouncer,
        private _paginator: MatPaginatorIntl
    ) {
        this._initPaginatorLabels();
    }

    ngAfterViewInit(): void {
        this._subscription = this.selection.changed.subscribe(() => {
            this.selectionChange.emit(this.selection?.selected);
            if (this.highlightedRow && this.selection.selected.length > 0) {
                this.highlightedRow = null;
            }
        });

        this._setSorting();
    }

    showDetailsTrigger() {
        if (this.highlightable) {
            this.highlightedRow && this.showDetails.emit(this.highlightedRow);
        } else {
            this.selection.selected.length > 0 && this.showDetails.emit(this.selection.selected[0]);
        }
    }

    editActionTrigger() {
        if (this.highlightable) {
            this.highlightedRow && this.edit.emit(this.highlightedRow);
        } else {
            this.selection.selected.length > 0 && this.edit.emit(this.selection.selected[0]);
        }
    }

    deleteActionTrigger() {
        const rowsData =
            this.selection?.selected.length > 0
                ? [...this.selection?.selected]
                : this.highlightedRow
                  ? [this.highlightedRow]
                  : [];

        this.delete.emit(rowsData);
    }

    public async sortingChange(sortState: Sort) {
        this.sortDescending = sortState?.direction ? sortState?.direction == 'desc' : undefined;
        this.sortField = sortState?.active && sortState?.direction ? sortState?.active : undefined;
        this.pageIndex = 0;
        this.pageRequest = this._getPageRequest();
        this.clearSelectedAndHighlighted();
        this.loadData.emit(this.pageRequest);
        await this._announceSorting(sortState);
    }

    public applyFilter(value: string): void {
        clearTimeout(this._valueChangeTimer);
        this._valueChangeTimer = setTimeout(() => {
            this.filterValue = value;
            if (this._pageData) {
                this.clearSelectedAndHighlighted();
                this.pageRequest = this._getPageRequest();
                this.pageRequest.pageNumber = 0;
                this.loadData.emit(this.pageRequest);
            } else {
                this._applyFilter();
            }
        }, this._valueChangeInterval);
    }

    public pageChange(page: PageEvent): void {
        this.pageSize = page?.pageSize ?? 100;
        this.pageIndex = page?.pageIndex ?? 0;
        this.clearSelectedAndHighlighted();
        this.pageRequest = this._getPageRequest();
        this.loadData.emit(this.pageRequest);
    }

    /** Table selection */
    public rowTrigger(row: T) {
        if (this.multiselect && !this.highlightable) {
            this.selection.toggle(row);
        }

        if (this.highlightable && this.selection.selected.length === 0) {
            if (this.highlightedRow == row) {
                this.highlightedRow = null;
            } else {
                this.highlightedRow = row;
            }
        }

        this.expandCollapseRow(row);
    }

    public expandCollapseRow(row: T) {
        if (this.expandedRowTemplate) {
            this.expandedData = this.expandedData === row ? null : row;
            this.expandRow.emit(this.expandedData);
        }
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public toggleAllRows(): void {
        if (this.isAllSelected) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.filteredData);
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(row?: T): string {
        if (!row) {
            return `${this.isAllSelected ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
    }

    public changeColumnsVisible(columns: ColumnDirective[]) {
        this.columnsList.forEach(
            (col: ColumnDirective) => TableComponent.ACTION_FIELDS.indexOf(col.field) === -1 && (col.visible = false)
        );
        columns.forEach((visibleCol: ColumnDirective) => {
            const column = this.columnsList.find((col) => col.field === visibleCol.field);
            column && (column.visible = true);
        });
    }

    applyColumnsVisible() {
        // Clone deep
        this.previousSelectedColumns = this.visibleColumnList.map((value: ColumnDirective) => value);
        this._setVisibleColumnsField();
    }

    /** Set selected columns in table by column fields */
    public setVisibleColumnByFields(fields: string[]) {
        this.columnsList.forEach((col: ColumnDirective) => {
            TableComponent.ACTION_FIELDS.indexOf(col.field) === -1 &&
                !fields.includes(col.field) &&
                (col.visible = false);
        });

        this._setVisibleColumnsField();
        this._setVisibleColumns();
    }

    /** Fires load/reload events and clear table selected and highlighted items  */
    public reloadData() {
        this.clearSelectedAndHighlighted();
        this.pageRequest = this._getPageRequest();
        this.loadData.emit(this.pageRequest);
        this.reload.emit(this.pageRequest);
    }

    /** Clear all selected table items and set highlightedRow to null */
    public clearSelectedAndHighlighted() {
        if (this.multiselect) {
            this.selection.clear();
        }
        if (this.highlightable) {
            this.highlightedRow = null;
        }
        this.expandedData = null;
    }

    public columnItemRenderer = (column: ColumnDirective) => column.header;

    public scrollToTop() {
        if (this.tableContainer?.nativeElement) {
            this.tableContainer.nativeElement.scrollTop = 0;
        }
    }

    private _setSorting() {
        if (!this._pageData) {
            this.dataSource.sortingDataAccessor = (item: T, property: string) => {
                return get(item, property);
            };

            this.dataSource.sort = this.sort;
        }
    }

    private _setVisibleColumnsField(): void {
        const visibleColumns: ColumnDirective[] = [];
        this.visibleColumnsFields = [];
        this.filterColumns = [];

        // Add column for checkbox if multiselect is true
        this.multiselect && this.visibleColumnsFields.push(this.selectColumnID);
        this._addFilterColumn(this.multiselect, `${this.filterSelectColumnID}`);

        // Add visible column
        this.columnsList.forEach((item: ColumnDirective) => {
            item.visible && this.visibleColumnsFields.push(item.field) && visibleColumns.push(item);
            this._addFilterColumn(item.visible, item.filterField);
        });

        // Add column for expand/collapse action
        this.expandedRowTemplate && this.showRowExpandButton && this.visibleColumnsFields.push(this.expandColumnID);
        this.visibleColumnsChange.emit(visibleColumns);
    }

    private _setVisibleColumns(): void {
        const columns: ColumnDirective[] = [];
        this.columnsList.forEach((item: ColumnDirective) => {
            item.visible && columns.push(item);
        });

        this.visibleColumnList = columns;
        // Clone deep
        this.previousSelectedColumns = this.visibleColumnList.map((value: ColumnDirective) => value);
    }

    /** Announce the change in sort state for assistive technology. */
    private async _announceSorting(sortState: Sort) {
        sortState.direction
            ? await this._liveAnnouncer.announce(
                  sortState.direction === 'asc'
                      ? $localize`:@@COMMON_UI.TABLE.ASCENDING:Sorted ascending`
                      : $localize`:@@COMMON_UI.TABLE.DESCENDING:Sorted descending`
              )
            : await this._liveAnnouncer.announce($localize`:@@COMMON_UI.TABLE.SORTING_CLEARED:Sorting cleared`);
    }

    private _getPageRequest(): PageRequest {
        const request = new PageRequest();
        request.filterValue = this.filterValue ?? '';
        request.pageNumber = this.pageIndex;
        request.pageSize = this.pageSize;
        request.sortDescending = this.sortDescending;
        request.sortField = this.sortField;

        return request;
    }

    private _applyFilter() {
        if (this.dataSource) {
            if (this.filterFunction != null) {
                this.dataSource.filterPredicate = this.filterFunction;
            } else if (this.globalFilterFields?.length > 0) {
                this.dataSource.filterPredicate = (data: T, filterValue: string) => {
                    return (
                        this.globalFilterFields.find((key: string) => {
                            const value = get(data, key);
                            return (
                                value?.trim().toLocaleLowerCase().indexOf(filterValue.trim().toLocaleLowerCase()) >= 0
                            );
                        }) != undefined
                    );
                };
            }

            this.dataSource.filter = this.filterValue.trim().toLowerCase();
        }
    }

    private _addFilterColumn(condition: boolean, field: string) {
        condition && (this.showFilterRow || this.showFilterRowAction) && this.filterColumns.push(field);
    }

    private _initColumnOptions() {
        // Do not show action column in column selector dialog
        this.columnsOptions = this._columns
            .toArray()
            .filter((value: ColumnDirective) => TableComponent.ACTION_FIELDS.indexOf(value.field) === -1);
    }

    private _initPaginatorLabels() {
        this._paginator.itemsPerPageLabel = $localize`:@@CORE.ITEMS_PER_PAGE: Items per page:`;
        this._paginator.nextPageLabel = $localize`:@@CORE.NEXT_PAGE: Next Page`;
        this._paginator.previousPageLabel = $localize`:@@CORE.PREVIOUS_PAGE: Previous Page`;
        this._paginator.firstPageLabel = $localize`:@@CORE.FIRST_PAGE: First Page`;
        this._paginator.lastPageLabel = $localize`:@@CORE.LAST_PAGE: Last Page`;
    }

    contextExp(rowData: any, dataIndex: any): T {
        return { $implicit: rowData, index: dataIndex } as T;
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }
}
