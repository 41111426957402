import { Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize, take } from 'rxjs';
import { UserService } from '@libs/vc-core-lib';
import { NotificationService } from '@libs/vc-common-ui-lib';

@Component({
    selector: 'vc-enable-mfa-dialog',
    templateUrl: './enable-mfa-dialog.component.html',
    styleUrls: ['./enable-mfa-dialog.component.scss'],
})
export class EnableMfaDialogComponent {
    dialogTitle = $localize`:@@ACCOUNT.ENABLE_MFA:Enable MFA`;
    primaryButtonText = $localize`:@@CORE.BUTTON.NEXT:Next`;

    currentStep: MFAStep = MFAStep.INTRO;
    mfaStep = MFAStep;

    loading: boolean = false;

    qrData: string = '';
    verificationCode!: string;

    constructor(private _notificationService: NotificationService, private _userService: UserService) {}

    @Input()
    set visible(value: boolean) {
        if (value !== this._visible) {
            this._visible = value;
            this.visibleChange.emit(this._visible);
        }

        if (value) {
            this.primaryButtonText = $localize`:@@CORE.BUTTON.NEXT:Next`;
            this.currentStep = MFAStep.INTRO;
            this.qrData = '';
        }

        if (!value) {
            this.verificationCode = '';
        }
    }
    get visible(): boolean {
        return this._visible;
    }
    private _visible = false;

    /* Whether to show the end explanation screen after verification.  Default is false */
    @Input()
    showEnd = false;

    @Input()
    showSecondaryTrigger = true;

    @Input()
    showCloseIcon = true;

    @Output()
    mfaComplete = new EventEmitter<void>();

    @Output()
    visibleChange = new EventEmitter<boolean>();

    nextStep() {
        if (this.currentStep === MFAStep.INTRO) {
            this.currentStep = MFAStep.QRCODE;
            this._beginMFA();
            return;
        }

        if (this.currentStep === MFAStep.QRCODE) {
            this.currentStep = MFAStep.VERIFY;
            return;
        }

        if (this.currentStep === MFAStep.VERIFY) {
            if (this.verificationCode || this.verificationCode !== '') {
                this.loading = true;
                this._verifyMFA(this.verificationCode).subscribe({
                    next: () => {
                        this._notificationService.success(
                            '',
                            $localize`:@@ACCOUNT.ENABLE_MFA.DETAILS.SUCCESS_ENABLING_MFA:Successfully enabled MFA`
                        );

                        if (this.showEnd) {
                            this.primaryButtonText = $localize`:@@CORE.USER.LOGOUT:Log out`;
                            this.currentStep = MFAStep.END;
                            return;
                        }

                        this.visible = false;
                        this.currentStep = MFAStep.INTRO;
                    },
                    error: () =>
                        this._notificationService.error(
                            '',
                            $localize`:@@ACCOUNT.ENABLE_MFA.DETAILS.ERROR:Please enter valid code`
                        ),
                });
            }
            return;
        }

        if (this.currentStep === MFAStep.END) {
            this.visible = false;
            this.mfaComplete.emit();
            return;
        }
    }

    private _beginMFA() {
        this._userService
            .beginMfa()
            .pipe(take(1))
            .subscribe({
                next: (qrData: string) => {
                    this.qrData = qrData;
                },
                error: () =>
                    this._notificationService.error(
                        '',
                        $localize`:@@ACCOUNT.ENABLE_MFA.DETAILS.ERROR_GENERATE_QRCODE:Unable to generate QR code at this time.  Please try again later.`
                    ),
            });
    }

    private _verifyMFA(verificationCode: string) {
        return this._userService.verifyMfa(verificationCode).pipe(
            take(1),
            finalize(() => (this.loading = false))
        );
    }
}

enum MFAStep {
    INTRO = 'Intro',
    QRCODE = 'QRCode',
    VERIFY = 'Verify',
    END = 'End',
}
