export enum AssetTypes {
    NETWORK = 'NETWORK',
    HOST = 'HOST',
    APPLICATION = 'APPLICATION',
    USER = 'USER',
    ENDPOINT = 'ENDPOINT',
    UTM = 'UTM',
    SITE = 'SITE',
    WEB_APPLICATION = 'WEB_APPLICATION',
}

export class AssetTypesHelper {
    static getAssetTypeLocale(assetType: AssetTypes): string {
        switch (assetType) {
            case AssetTypes.NETWORK:
                return $localize`:@@CORE.ASSET_TYPES.NETWORK:Network`;
            case AssetTypes.HOST:
                return $localize`:@@CORE.ASSET_TYPES.HOST:Host`;
            case AssetTypes.APPLICATION:
                return $localize`:@@CORE.ASSET_TYPES.APPLICATION:Application`;
            case AssetTypes.USER:
                return $localize`:@@CORE.ASSET_TYPES.USER:User`;
            case AssetTypes.ENDPOINT:
                return $localize`:@@CORE.ASSET_TYPES.ENDPOINT:Endpoint`;
            case AssetTypes.UTM:
                return $localize`:@@CORE.ASSET_TYPES.UTM:UTM`;
            case AssetTypes.SITE:
                return $localize`:@@CORE.ASSET_TYPES.SITE:Site`;
            case AssetTypes.WEB_APPLICATION:
                return $localize`:@@CORE.ASSET_TYPES.WEB_APPLICATION:Web application`;
            default:
                return '';
        }
    }

    static getAssetTypeIcon(assetType: AssetTypes): string {
        switch (assetType) {
            case AssetTypes.NETWORK:
                return 'rss_feed';
            case AssetTypes.HOST:
                return 'home';
            case AssetTypes.APPLICATION:
                return 'wysiwyg';
            case AssetTypes.USER:
                return 'person';
            case AssetTypes.ENDPOINT:
                return 'desktop_windows';
            default:
                return '';
        }
    }
}
