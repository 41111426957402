import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivationCodeDetails } from '../../model/user/activation-code-details.model';
import { RegistrationInfo } from '../../model/user/registration-info.model';

@Injectable()
export class UserRegistrationService {
    httpClient: HttpClient;
    environment: any;

    constructor(private injector: Injector) {
        this.httpClient = injector.get(HttpClient);
        this.environment = injector.get('environment');
    }

    public registrationLookup(code: string): Observable<ActivationCodeDetails> {
        return this.httpClient.get<ActivationCodeDetails>(
            `${this.environment.APP.VC_USER_REGISTRATION_BASE_URL}/lookup?code=${code}`
        );
    }

    public register(user: RegistrationInfo): Observable<void> {
        return this.httpClient.post<void>(`${this.environment.APP.VC_USER_REGISTRATION_BASE_URL}/register`, user);
    }
}
