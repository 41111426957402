import { CoreConstant } from '../constant/base.constant';
import { Language } from '../model/user/language.enum';
import { Theme } from '../model/user/theme.enum';

export class CommonUtil {
    public static retrieveThemeAndSwitch(reload = true): Theme {
        const theme = localStorage.getItem(CoreConstant.VC_THEME) as Theme;
        return this.switchTheme(theme, reload);
    }

    public static switchTheme(theme: Theme | null, reload = true): Theme {
        if (!theme) {
            theme = Theme.light;
        }

        localStorage.setItem(CoreConstant.VC_THEME, `${theme}`);

        const html = document.querySelector('html');

        if (!html) return Theme.light;

        switch (theme) {
            case Theme.dark:
                document.body.classList.remove('vc-light-theme');
                html.dataset.theme = `theme-dark`;
                document.body.classList.add('vc-dark-theme');
                localStorage.setItem(CoreConstant.VC_THEME, `${theme}`);

                reload && window.location.reload(); // TODO: Remove after Kendo charts and grids are removed
                return Theme.dark;
            case Theme.light:
            case null:
            default:
                document.body.classList.remove('vc-dark-theme');
                html.dataset.theme = `theme-light`;
                document.body.classList.add('vc-light-theme');
                localStorage.setItem(CoreConstant.VC_THEME, `${theme}`);

                reload && window.location.reload(); // TODO: Remove after Kendo charts and grids are removed
                return Theme.light;
        }
    }

    /**
     * Shows the loader that covers the entire viewport.  Should only be used for cases where entire application is
     * loading, such as after user logging in.  For showing spinners on component views,  use vcProgressSpinner from
     * Common UI Library (vc-common-ui-lib)
     */
    public static showAppLoader() {
        const element = document.getElementById('vc-page-loader');
        element?.setAttribute('style', 'visibility: visible; opacity: 1');
    }

    /**
     * Hides the loader that covers the entire viewport.
     */
    public static hideAppLoader() {
        const element = document.getElementById('vc-page-loader');
        element?.setAttribute('style', 'visibility: hidden; opacity: 0');
    }

    static changeLanguage(lang: Language | null, reload = true): Language {
        if (!lang) {
            lang = Language.EN;
        }

        localStorage.setItem(CoreConstant.VC_LANGUAGE_ABBREVIATION, `${lang}`);
        reload && window.location.reload();
        return lang;
    }

    /**
     * Gets the computed color of a CSS variable based on current Theme being used
     */
    static getRenderedColor(color: string): string {
        return getComputedStyle(document.documentElement).getPropertyValue(color);
    }
}
