import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormComponent } from '../form/form.component';
import { TreeComponent } from '../tree/tree.component';

/** Example of dialog usage
 * HTML
 * <vc-button label="Open Dialog"
 *            (trigger)="triggerDialog()"></vc-button>
 * <vc-dialog [(visible)]="dialogVisible"
 *            [title]="'My Dialog'"
 *            width="500px"
 *            [showPrimary]="true">
 *   <h2 id="severity">Severity</h2>
 *   <vc-checkbox-group [(values)]="severityChecked"
 *                      ariaLabelledBy="severity"
 *                      (valuesChange)="valuesChanged($event)">
 *     <vc-checkbox *ngFor="let severity of severityOptions"
 *                  [value]="severity.value"
 *                  [label]="severity.label"></vc-checkbox>
 *   </vc-checkbox-group>
 * </vc-dialog>
 *
 * TS
 *     dialogVisible = false;
 *     triggerDialog() {
 *         this.dialogVisible = !this.dialogVisible;
 *     }
 */

@Component({
    selector: 'vc-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnDestroy {
    private _subscription = new Subscription();
    private dialogRef!: MatDialogRef<any>;

    /** Whether to show or hide dialog. */
    @Input()
    set visible(value: boolean) {
        if (this._visible !== value) {
            this._visible = value;
            this._visible ? this.openDialog() : this.closeDialog();
            this.visibleChange.emit(this._visible);
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    private _visible: boolean = false;

    /** Specifies dialog header title. */
    @Input()
    title!: string;

    /** Specifies dialog width. */
    @Input()
    width!: string;

    /** Specifies dialog height. */
    @Input()
    height!: string;

    /** Whether primary and secondary buttons should be visible on the left side with primary as the left-most  */
    @Input()
    showActionsOnLeft = false;

    /** Whether primary and secondary buttons should be visible on the right side with primary as the right-most.  This is the default side  */
    @Input()
    showActionsOnRight: boolean = true;

    /** Specifies label for table action primary button. */
    @Input()
    primaryLabel: string = $localize`:@@COMMON_UI.SAVE:Save`;

    /** Specifies label for table action secondary button. */
    @Input()
    secondaryLabel: string = $localize`:@@COMMON_UI.CANCEL:Cancel`;

    /** Whether to submit form inside dialog on primary button trigger, if dialog contains form. */
    @Input()
    submitFormOnPrimaryTrigger: boolean = true;

    /** Whether to clear form inside dialog after dialog close, if dialog contains form. */
    @Input()
    clearFormOnClose: boolean = false;

    /** Whether to clear tree filter inside dialog after dialog close, if dialog contains tree component. */
    @Input()
    clearTreeFilter: boolean = true;

    /** Whether to show primary button. */
    @Input()
    showPrimary: boolean = true;

    /** Whether to show secondary button. */
    @Input()
    showSecondary: boolean = true;

    /** Whether to show the actions container that has the Primary, Secondary, and custom buttons */
    @Input()
    showActions: boolean = true;

    /** Whether to close dialog after primary button click. */
    @Input()
    closeOnPrimary: boolean = false;

    /** Whether to close dialog after secondary button click. */
    @Input()
    closeOnSecondary: boolean = true;

    /** Whether to disable primary button. */
    @Input()
    primaryDisabled: boolean = false;

    /** Whether to disable secondary button. */
    @Input()
    secondaryDisabled: boolean = false;

    /** Whether to show header close icon. */
    @Input()
    showCloseIcon: boolean = true;

    /** Whether to show loading spinner. */
    @Input()
    loading: boolean = false;

    /** Where the dialog should focus on open. */
    @Input()
    autoFocus: boolean = true;

    @Input()
    panelClass!: string;

    /** Whether to close dialog when click outside of dialog */
    @Input()
    closeOnClickOutside: boolean = false;

    /** Two ways data binding for visible property. */
    @Output()
    visibleChange = new EventEmitter<boolean>();

    /** Event is fired when primary button click */
    @Output()
    primaryTrigger = new EventEmitter();

    /** Event is fired when secondary button click */
    @Output()
    secondaryTrigger = new EventEmitter();

    @ViewChild('dialogTemplate')
    dialogTemplate!: TemplateRef<any>;

    @ContentChildren(FormComponent)
    set forms(values: QueryList<FormComponent>) {
        this._forms = values;
    }

    get forms() {
        return this._forms;
    }
    private _forms!: QueryList<FormComponent>;

    @ContentChildren(TreeComponent)
    set tree(values: QueryList<TreeComponent<any>>) {
        this._tree = values;
    }

    get tree() {
        return this._tree;
    }
    private _tree!: QueryList<TreeComponent<any>>;

    constructor(public dialog: MatDialog) {}

    openDialog(): void {
        this.dialogRef = this.dialog.open(this.dialogTemplate, {
            width: this.width,
            panelClass: this.panelClass,
            disableClose: !this.closeOnClickOutside,
            autoFocus: this.autoFocus,
        });

        this._subscription.add(
            this.dialogRef.afterClosed().subscribe(() => {
                this.visible = false;
            })
        );
    }

    closeDialog() {
        this.dialogRef.close();

        // To avoid clean up before dialog closed, wrapped these method in setTimeout
        setTimeout(() => {
            this.clearFormOnClose && this.forms?.map((form: FormComponent) => form?.clear());
            this.clearTreeFilter && this.tree?.map((tree: TreeComponent<any>) => tree?.clearFilter());
        }, 100);
    }

    saveTrigger() {
        if (this.submitFormOnPrimaryTrigger) {
            this.forms?.map((form: FormComponent) => form?.submit());
            if (this.forms.find((form: FormComponent) => !form?.valid)) {
                return;
            }
        }

        this.primaryTrigger.emit();

        if (this.closeOnPrimary) {
            this.visible = false;
        }
    }

    cancelTrigger() {
        if (this.closeOnSecondary) {
            this.visible = false;
        }

        this.secondaryTrigger.emit();
    }

    ngOnDestroy() {
        this._subscription?.unsubscribe();
    }
}
