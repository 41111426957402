import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSettingsViewComponent } from './account-settings-view/account-settings-view.component';

const routes: Routes = [
    { path: '', component: AccountSettingsViewComponent },
    { path: ':tab', component: AccountSettingsViewComponent },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountSettingsRoutingModule {}
