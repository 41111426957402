import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UrlPrivilegeUtilService } from '../util-service/url-privilege-util.service';
import { NavigationMenu } from '../model/navigation-menu.model';
import { Observable, of, catchError, map, takeUntil, Subject, take } from 'rxjs';
import { WhoAmI } from '../model/user/whoAmI.model';
import { UserService } from '../service/user/user-service';
import { UserContextService } from '../context/user.context.service';
import { LoginService } from '../service/user/login.service';

@Injectable()
export class AuthGuard  implements OnDestroy {
    private _destroy$: Subject<void> = new Subject();
    private user!: WhoAmI | null;

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _loginService: LoginService,
        private _userContextService: UserContextService,
        private _urlPrivilegeUtilService: UrlPrivilegeUtilService
    ) {
        this._userContextService
            .getContext()
            .pipe(takeUntil(this._destroy$))
            .subscribe((user: WhoAmI | null) => {
                this.user = user;
            });
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this._loginService.redirectUrl = state.url;
        return this._fetchUserContext().pipe(
            map((user: WhoAmI | null) => {
                this.user = user;

                // Validate session
                if (!user || !user.isActive()) {
                    this._userContextService.clear();
                    this._router.navigate(['/login']);
                    return false;
                }

                // Check for general access
                const path = route.routeConfig?.path;
                const fullPath = path ? this.getFullPath(route) : path;
                const basicUrls = this._urlPrivilegeUtilService.getUserBasicUrls();

                if (basicUrls.includes(fullPath as string)) {
                    return true;
                }

                // Check for user specific access based on roles and privileges
                if (this._checkAuthorityNested(this._urlPrivilegeUtilService.getAllURLPrivileges(), fullPath)) {
                    return true;
                }

                this._router.navigate(['/']);
                return false;
            }),
            catchError(() => of(false))
        );
    }

    private getFullPath(route: ActivatedRouteSnapshot): string {
        const path = route.routeConfig?.path || '';

        if (route.parent?.routeConfig?.path) {
            return `${this.getFullPath(route.parent)}/${path}`;
        } else {
            return path;
        }
    }

    private _checkAuthorityNested(menus: NavigationMenu[] | undefined, path: string | undefined): boolean {
        if (!menus || !path) {
            return false;
        }

        for (let item of menus) {
            let containsModules = false;
            if (item?.modulesPaths && item.modulesPaths.length > 0) {
                containsModules = item.modulesPaths.includes(path);
            }
            if (item.link === path || containsModules) {
                if (this._hasAuthority(item?.privileges)) {
                    return true;
                }
            }

            if (item.childMenus && item.childMenus.length > 0 && this._checkAuthorityNested(item.childMenus, path)) {
                return true;
            }
        }

        return false;
    }

    private _hasAuthority(privileges: string[] | undefined): boolean {
        if (!privileges) {
            return false;
        }

        return privileges?.some((item: string) => this.user?.hasAuthority(item));
    }

    private _fetchUserContext(): Observable<WhoAmI | null> {
        const userToken = this._userContextService.getTokenFromStorage();
        return this.user && userToken
            ? of(this.user)
            : this._userService.whoAmI().pipe(
                  take(1),
                  catchError(() => {
                      return of(null);
                  })
              );
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
