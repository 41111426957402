import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import { QRCodeModule } from 'angularx-qrcode';
import { EnableMfaDialogComponent } from './enable-mfa-dialog/enable-mfa-dialog.component';
import { DisableMfaDialogComponent } from './disable-mfa-dialog/disable-mfa-dialog.component';

@NgModule({
    declarations: [EnableMfaDialogComponent, DisableMfaDialogComponent],
    imports: [CommonModule, VcCommonUiLibModule, QRCodeModule],
    exports: [EnableMfaDialogComponent, DisableMfaDialogComponent],
})
export class MfaModule {}
